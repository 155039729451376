<template>
  <v-container>
    <NavigationDrawer
      :nav_functions="nav_functions"
      :title="'Simulations Center'"
      :display_home="false"
      @newNavigationFunction="onNewNavigationFunction"
    />
    <SimulationOverview
      v-if="active_nav_function === 1"
      ref="SimulationOverview"
    />
    <SimulationResults
    v-if="active_nav_function === 2"
      ref="SimulationOverview"
    />
  </v-container>
</template>

<script>
import NavigationDrawer from "../components/general/NavigationDrawer.vue";
import { mapGetters } from "vuex";
import SimulationOverview from "../components/simulationCenter/pages/SimulationOverview.vue";
import SimulationResults from "../components/simulationCenter/pages/SimulationResult.vue";

export default {
  name: "Simulation",

  components: {
    NavigationDrawer,
    SimulationOverview,
    SimulationResults
  },

  computed: {
    ...mapGetters(["navigationIcons"]),
    nav_functions: function () {
      return [
        {
          id: 1,
          name: "Übersicht",
          icon: this.navigationIcons.sim_overview,
        },
        {
          id: 2,
          name: "Ergebnisse",
          icon: this.navigationIcons.sim_results,
        },
      ];
    },
  },

  data: () => ({
    active_nav_function: 1, // Determines which Function is beeing displayed
  }),

  methods: {
    onNewNavigationFunction(nav_function_id, subfunction_id) {
      this.active_nav_function = nav_function_id;
    },
  },
};
</script>
