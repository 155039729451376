import { ObjectId } from "mongodb";

export enum MachineGroupStatus {
  off = 0,
  on = 1,
}

export type MachineGroup = {
  _id: ObjectId;
  name: string;
  current_status: MachineGroupStatus;
  description: string;
  location: string;
  operating_time: number;
  implementation_date: Date;
  components: {
    machines: ObjectId[];
    sensors: ObjectId[];
  };
};
