var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Neue Sensor hinzufügen:")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.form_validation),callback:function ($$v) {_vm.form_validation=$$v},expression:"form_validation"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Name","rules":[function () { return !!_vm.name || 'Pflichtfeld'; }]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.allSensorTypes,"menu-props":{ maxHeight: '400' },"label":"Typ","item-text":"name","item-value":"_id","rules":[function () { return !!_vm.type || 'Pflichtfeld'; }]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Beschreibung","auto-grow":"","outlined":"","rules":[function () { return !!_vm.description || 'Pflichtfeld'; }]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Standort","rules":[function () { return !!_vm.location || 'Pflichtfeld'; }]},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Absoluter max. Wert","rules":[
                  function () { return !!_vm.operating_range_max || 'Pflichtfeld'; },
                  function () { return !isNaN(Number(_vm.operating_range_max)) ||
                    'Nur Nummern zugelassen'; } ]},model:{value:(_vm.operating_range_max),callback:function ($$v) {_vm.operating_range_max=$$v},expression:"operating_range_max"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Absoluter min. Wert","rules":[
                  function () { return !!_vm.operating_range_min || 'Pflichtfeld'; },
                  function () { return !isNaN(Number(_vm.operating_range_min)) ||
                    'Nur Nummern zugelassen'; } ]},model:{value:(_vm.operating_range_min),callback:function ($$v) {_vm.operating_range_min=$$v},expression:"operating_range_min"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Optimaler max. Wert","rules":[
                  function () { return !!_vm.optimal_operating_range_max || 'Pflichtfeld'; },
                  function () { return !isNaN(Number(_vm.optimal_operating_range_max)) ||
                    'Nur Nummern zugelassen'; } ]},model:{value:(_vm.optimal_operating_range_max),callback:function ($$v) {_vm.optimal_operating_range_max=$$v},expression:"optimal_operating_range_max"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Optimaler min. Wert","rules":[
                  function () { return !!_vm.optimal_operating_range_min || 'Pflichtfeld'; },
                  function () { return !isNaN(Number(_vm.optimal_operating_range_min)) ||
                    'Nur Nummern zugelassen'; } ]},model:{value:(_vm.optimal_operating_range_min),callback:function ($$v) {_vm.optimal_operating_range_min=$$v},expression:"optimal_operating_range_min"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.$emit('closeNewSensorDialog');
          _vm.$refs.form.reset();}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"green","text":"","disabled":!_vm.form_validation},on:{"click":_vm.saveSensor}},[_vm._v(" Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }