import { FactoryStatus } from "../types/factory";

export default {
  methods: {
    /* Title: getFactoryStatusText
     *
     * Description: Returns a String representing the Status of an Factory
     *
     */
    getFactoryStatusText(factory_status: FactoryStatus) {
      switch (factory_status) {
        case 0:
          return "Aus";

        default:
          return "Aktiviert";
      }
    },

    /* Title: getFactoryStatusColor
     *
     * Description: Returns a Color-String representing the Status of an Factory
     *
     */
    getFactoryStatusColor(factory_status: FactoryStatus) {
      switch (factory_status) {
        case 0:
          return "grey";
        default:
          return "green";
      }
    },
  },
};
