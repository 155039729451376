<!-- 
    Title: NavigationDrawer
  
    Description: Used for Navigation within the different functioncenters except for the hierarchical overview
-->

<template>
  <v-navigation-drawer app clipped :elevation="2" permanent fixed dark>
    <!-- Navigation Title -->
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> {{ title }} </v-list-item-title>
        <v-list-item-subtitle>
          Hierarchischer Digitaler Zwilling
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav>
      <!-- link to the AdminHome Page, relevant as this is not part of the nav_function prop -->
      <v-list-item @click="onNewNavigationFunction(0)" v-if="display_home">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- every other Link, determined by the nav_functions prop -->
      <v-list-item
        v-for="nav_function in nav_functions"
        :key="nav_function.id"
        link
        @click="onNewNavigationFunction(nav_function.id)"
      >
        <v-list-item-icon>
          <v-icon>{{ nav_function.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ nav_function.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AdminNavigationDrawer",

  props: {
    nav_functions: [], // Contains the elements of the navigation drawer
    title: String, // Current Navigation Title
    display_home: Boolean, // Determines weather the Home Button is being displayed
  },

  methods: {
    /**
     * Title: onNewActiveFunction()
     * Description: is run when a new Function is selected by the user.
     *
     * @param {Number} nav_function_id the new active function, transmitted by v-list
     *
     */

    onNewNavigationFunction(nav_function_id) {
      this.$emit("newNavigationFunction", nav_function_id, 0);
    },
  },
};
</script>
