<!-- 
    Title: EditMachineTypeDialog
  
    Description: Dialog for editing data of a specific Machinetype
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>

      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Maschinentyp bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">

            <!-- Machinetype ID Field -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_machinetype._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Machinetype Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_machinetype.name"
                  label="Name"
                  :rules="[() => !!edited_machinetype.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Machinetype Item Input -->
              <v-col>
                <v-text-field
                  v-model="edited_machinetype.icon"
                  label="Icon"
                  :rules="[() => !!edited_machinetype.icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Machinetype Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_machinetype.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_machinetype.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Dialog Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditMachineTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MachineType from "../../../../types/machineType";
export default {
  name: "EditMachineTypeDialog", //Component Name

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_machinetype: MachineType, // the machinetype that is beeing edited
    edited_machinetype_index: Number, // the index of the edited machinetype within the vuex State
  },

  computed: {
    ...mapGetters(["allMachineTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the Input Form
  }),

  methods: {
    ...mapActions(["editMachineType"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited machinetype by calling the vuex Method
     *
     */
    saveEdit() {

      // Validate Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {

        // Call Vuex Method to save
        this.editMachineType({
          machine_type: this.edited_machinetype,
          machine_type_index: this.edited_machinetype_index,
        });

        // Emit Close Dialog Event to Parent Component
        this.$emit("closeEditMachineTypeDialog");

        // reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
