<!-- 
    Title: ThroughputCard
  
    Description: Used to display historic throughput data
-->

<template>
  <v-card tile height="100%" elevation="5">
    <!-- Title -->
    <v-card-title>Durchsatz:</v-card-title>

    <!-- Subtitle/Uni -->
    <v-card-subtitle>Baugruppen pro Stunde</v-card-subtitle>

    <!-- Chart -->
    <v-card-text>
      <apexchart
        type="line"
        :options="chartOptions"
        :series="throughput_series"
        height="300px"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "ThroughputCard",

  props: {
    throughput_data: [], // raw data
  },

  components: {
    apexchart: VueApexCharts,
  },

  computed: {
    // Returns data for apexchart
    throughput_series: function () {
      return [
        {
          name: "Durchsatz",
          data: this.throughput_data,
        },
      ];
    },
  },

  // Chartoptions
  data: () => ({
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "stepline",
        width: 3,
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          formatter: function (value) {
            return value + " /h";
          },
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
  }),
};
</script>
