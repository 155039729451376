var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"5","height":"100%"}},[_c('v-card-title',[_vm._v(" Wartungshistorie "),_c('v-spacer'),(_vm.checkRights('maintenance') === true)?_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){_vm.display_new_maintenance_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v(" Wartung hinzufügen ")],1):_vm._e()],1),(!_vm.maintenance_history)?_c('v-card-text',[_vm._v(" Keine historische Wartungen vorhanden! ")]):_vm._e(),(_vm.maintenance_history)?_c('v-data-table',{attrs:{"headers":_vm.maintenance_data_header,"items":_vm.items,"expanded":_vm.expanded_maintenances,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_maintenances=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getMaintenanceStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getMaintenanceStatusText(item.status))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.specificMaintenanceType(item.type).icon))])]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item._id)+" ")]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.creation_date).toLocaleString())+" ")]}},{key:"item.operating_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertMinutesToHoursMinutes(item.operating_time))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificUser(item.created_by).login)+" ")]}},{key:"item.time_started",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.time_started).toLocaleString())+" ")]}},{key:"item.time_finished",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeFinishedString(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.status != 2)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.finishMaintenance(item._id)}}},[_vm._v(_vm._s(_vm.navigationIcons.check))]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Wartungs ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":"","dense":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)]}}],null,false,2124006334)}):_vm._e(),_c('NewMaintenanceDialog',{attrs:{"display":_vm.display_new_maintenance_dialog,"hiearchical_level":_vm.component_type,"id":_vm.component_id},on:{"closeNewMaintenanceDialog":function($event){_vm.display_new_maintenance_dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }