import { ProductionOrder } from "@/types/productionOrder";

export default {
  methods: {

    /* Title: getProductionOrderStatusColor
     * 
     * Description: Returns a Color-String representing the Status of an ProductionOrder
     *
     */
    getProductionOrderStatusText(productionorder_status: number) {
      if (productionorder_status === 0) {
        return "Abgeschlossen";
      } else {
        return "In Bearbeitung";
      }
    },

    /* Title: getProductionOrderStatusColor
     * 
     * Description: Returns a Color-String representing the Status of an ProductionOrder
     *
     */
    getProductionOrderStatusColor(productionorder_status: number) {
      if (productionorder_status === 0) {
        return "green";
      } else {
        return "grey";
      }
    },

    /* Title: getProductionOrderProgress
     * 
     * Description: Returns a the progress percentage of an ProductionOrder based on the number of finished subassemblies
     *
     */
    getProductionOrderProgess(productionorder: ProductionOrder) {
      if (productionorder.status === productionorder.assemblies.length) {
        return 0;
      } else {
        return (
          ((productionorder.status * -1 + productionorder.assemblies.length) /
            productionorder.assemblies.length) *
          100
        );
      }
    },
  },
};
