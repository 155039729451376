<!-- 
    Title: Processing Time Card
  
    Description: Card to display average processing time chart
-->

<template>
  <v-card tile height="100%" elevation="5">
    <!-- Title -->
    <v-card-title>Durchschnittliche Bearbeitungszeit:</v-card-title>

    <!-- Subtitle / Unit -->
    <v-card-subtitle>pro Baugruppe</v-card-subtitle>

    <!-- Chart -->
    <v-card-text>
      <apexchart
        type="line"
        :options="chartOptions"
        :series="average_processing_time_series"
        height="300px"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "ProcessingTimeCard",

  props: {
    average_processing_time_data: [], // Raw chart data
  },

  components: {
    apexchart: VueApexCharts,
  },

  computed: {
    /**
     * Title: average_processing_time_series()
     * Description: returns chart data for apex
     *
     *
     */
    average_processing_time_series: function () {
      return [
        {
          name: "Durchsatz",
          data: this.average_processing_time_data,
        },
      ];
    },
  },

  // Chart Options
  data: () => ({
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "stepline",
        width: 3,
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          formatter: function (value) {
            return value + " h";
          },
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
  }),
};
</script>
