var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Baugruppen")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.assembly_search),callback:function ($$v) {_vm.assembly_search=$$v},expression:"assembly_search"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.allAssemblyTypes,"label":"Typ-Filter","item-text":"name","item-value":"_id","clearable":""},model:{value:(_vm.type_filter),callback:function ($$v) {_vm.type_filter=$$v},expression:"type_filter"}})],1)],1)],1),_c('v-data-table',{key:_vm.assembly_data_table_key,attrs:{"headers":_vm.assembly_data_header,"items":_vm.assembly_data,"item-key":"_id","search":_vm.assembly_search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getAssemblyStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getAssemblyStatusText(item.status))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificAssemblyType(item.type).name)+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":"blue","value":_vm.getAssemblyProgess(item),"height":"25"}},[_vm._v(_vm._s(_vm.getAssemblyProgess(item))+"%")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openAssemblyDetailDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.information))])],1)]}},{key:"item.produced_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMachineName(item.produced_by))+" ")]}}])})],1),_c('AssemblyDetailDialog',{attrs:{"display":_vm.display_assembly_detail_dialog,"assembly":_vm.current_assembly,"assembly_history":_vm.assembly_detail_history},on:{"closeAssemblyDetailDialog":_vm.closeAssemblyDetailDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }