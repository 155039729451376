var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"5","height":"100%"}},[_c('v-card-title',[_vm._v(" Produktionshistorie ")]),_c('v-data-table',{attrs:{"headers":_vm.assembly_data_header,"items":_vm.items,"expanded":_vm.expanded_maintenances,"item-key":"_id"},on:{"update:expanded":function($event){_vm.expanded_maintenances=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificAssemblyType(item.type).name)+" ")]}},{key:"item.processing_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.assemblyProcessingTime(item))+" ")]}},{key:"item.process_started",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.process_started).toLocaleString())+" ")]}},{key:"item.process_finished",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.process_finished).toLocaleString())+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }