<template>
  <!--
    Title: Factory Overview
    Purpose: Shows a general overview about a factory and its subordinated manufacturing cells.
  -->
  <v-container>
    <!-- Factory Name -->
    <v-row>
      <h1>{{ factory.name }}</h1>
    </v-row>

    <!-- KPI Cards -->
    <v-row>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktuelle Auslastung'"
          :kpi_value="(factory.current_status * 100).toFixed(0)"
          :kpi_unit="'%'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktueller Durchsatz'"
          :kpi_value="factory.current_throughput"
          :kpi_unit="'Baugruppen pro Stunde'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Offene Produktionsaufträge'"
          :kpi_value="
            allProductionOrders.filter(
              (production_order) => production_order.status != 0
            ).length
          "
          :kpi_unit="' '"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Offene Baugruppen'"
          :kpi_value="
            allAssemblys.filter((assembly) => assembly.status != 0).length
          "
          :kpi_unit="' '"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
    </v-row>

    <!-- Factory Info Card and favored sensors -->
    <v-row>
      <v-col cols="4">
        <FactoryInfoCard :factory="factory" :title="'Kurzinformation'" />
      </v-col>
      <v-col
        cols="4"
        v-for="sensor in factory.components.sensors.filter(
          (sensor) => specificSensor(sensor).favored === true
        )"
        :key="sensor"
      >
        <SensorGaugeCard :sensor_id="sensor" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        v-for="machinegroup in factory.components.machine_groups"
        :key="machinegroup"
      >
        <MachineGroupCard :machinegroupid="machinegroup" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <UtilizationCard :utilization_data="this.utilization_data" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ThroughputCard :throughput_data="this.throughput_data" />
      </v-col>
    </v-row>
    <v-row v-if="factory.components.sensors.length > 0">
      <v-col>
        <SensorDetailPanel :sensor_ids="factory.components.sensors" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MachineGroupCard from "./MachineGroupCard.vue";
import SensorDetailPanel from "../general/SensorDetailPanel.vue";
import FactoryInfoCard from "./FactoryInfoCard.vue";
import SensorGaugeCard from "../general/SensorGaugeCard.vue";
import UtilizationCard from "../general/UtilizationCard.vue";
import ThroughputCard from "../general/ThroughputCard.vue";
import KPICard from "../general/KPICard.vue";
import { mapGetters } from "vuex";

export default {
  name: "FactoryOverview",

  components: {
    MachineGroupCard,
    FactoryInfoCard,
    SensorDetailPanel,
    SensorGaugeCard,
    UtilizationCard,
    ThroughputCard,
    KPICard,
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "allSensors",
      "specificFactory",
      "specificSensor",
      "specificMachineGroup",
      "allProductionOrders",
      "allAssemblys",
    ]),

    /**
     * Title: factory()
     * Description: gets the complete data of the factory from the state
     *
     */
    factory: function () {
      return this.specificFactory(this.overviewActiveElementId);
    },

    /**
     * Title: utilization_data()
     * Description: converts the utilization data for apexcharts
     *
     */
    utilization_data: function () {
      let data = [];
      this.factory.status_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: (status.value * 100).toFixed(0),
        });
      });
      return data;
    },

    /**
     * Title: throughput_data()
     * Description: converts throughput data for apexcharts
     *
     */
    throughput_data: function () {
      let data = [];
      this.factory.throughput_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: status.value,
        });
      });
      return data;
    },
  },
};
</script>
