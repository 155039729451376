<!-- 
    Title: Productiion History Card
  
    Description: Displays the production history of a specific machine
-->
<template>
  <v-card tile elevation="5" height="100%">

    <!-- Title -->
    <v-card-title> Produktionshistorie </v-card-title>

    <!-- Production History table -->
    <v-data-table
      :headers="assembly_data_header"
      :items="items"
      :expanded.sync="expanded_maintenances"
      item-key="_id"
    >
      <!-- Template sensor name-->
      <template v-slot:item.type="{ item }">
        {{ specificAssemblyType(item.type).name }}
      </template>

      <!-- Processing Time -->
      <template v-slot:item.processing_time="{ item }">
        {{ assemblyProcessingTime(item) }}
      </template>

      <!-- Start Time -->
      <template v-slot:item.process_started="{ item }">
        {{ new Date(item.process_started).toLocaleString() }}
      </template>

      <!-- Finish Time -->
      <template v-slot:item.process_finished="{ item }">
        {{ new Date(item.process_finished).toLocaleString() }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductionHistoryCard",

  computed: {
    ...mapGetters([
      "specificAssembly",
      "specificAssemblyType",
      "navigationIcons",
    ]),

    /**
     * Title: items()
     * Description: return the production history items based on the ids within the amchine
     *
     */
    items: function () {
      let data = [];
      this.production_history.forEach((assembly_id) => {
        data.push(this.specificAssembly(assembly_id));
      });
      return data;
    },
  },

  props: {
    production_history: [], // the production history ids
    component_id: String, // the id of the component
    component_type: String, // the type of the component of the producito history
  },

  data: () => ({
    ...mapActions(["finishMaintenance"]),
    expanded_maintenances: [], // synchronices the expanded items of the maintenance data table

    //Table Header DAta
    assembly_data_header: [
      { text: "Typ:", value: "type" },
      { text: "Durchlaufzeit:", value: "processing_time" },
      { text: "Gestartet:", value: "process_started" },
      { text: "Fertiggestellt", value: "process_finished" },
      { text: "ID:", value: "_id" },
    ],
  }),

  methods: {

    /**
     * Title: assemblyProcessingTime()
     * Description: returns the processing time of a specific assembly as a string
     * 
     * @param {Assembly} assembly the assembly whichs processing time is calculated
     *
     */
    assemblyProcessingTime(assembly) {

      // The Processing Time in Minutes
      const processing_time_min = (
        (new Date(assembly.process_finished).getTime() -
          new Date(assembly.process_started).getTime()) /
        60000
      ).toFixed(0);

      // The Processing Time remainder in seconds
      const processing_time_sec = ((
        (new Date(assembly.process_finished).getTime() -
          new Date(assembly.process_started).getTime()) %
        60000
      ) / 1000).toFixed(0);

      if (processing_time_min < 0) return "-";
      else return processing_time_min + ":" + processing_time_sec + " min";
    },
  }
};
</script>
