<!-- 
    Title: AdminDevTools
  
    Description: Enable Access to Development Tools
-->

<template>
  <v-container>
    <h1 class="mt-2 mb-5">Devtools</h1>

    <!-- Machine Development Tool Buttons -->
    <h2>Maschinen</h2>
    <v-row
      ><v-col cols="2">
        <v-btn color="success" @click="startAllMachines()"
          >Alle Maschinen starten</v-btn
        > </v-col
      ><v-col cols="2">
        <v-btn color="error" @click="stopAllMachines()"
          >Alle Maschinen stoppen</v-btn
        >
      </v-col></v-row
    >

    <!-- Sensor Development Tool Buttons -->
    <h2 class="mt-5">Sensoren</h2>
    <v-row
      ><v-col cols="2">
        <v-btn color="success" @click="startAllSensors()"
          >Alle Sensoren starten</v-btn
        > </v-col
      ><v-col cols="2">
        <v-btn color="error" @click="stopAllSensors()"
          >Alle Sensoren stoppen</v-btn
        >
      </v-col></v-row
    >

    <!-- Database Development Tool Button -->
    <h2 class="mt-5">Datenbank</h2>
    <v-btn color="error" @click="resetDatabase()">Datenbank zurücksetzen</v-btn>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AdminDevTools",

  methods: {
    ...mapActions([
      "startAllMachines",
      "stopAllMachines",
      "startAllSensors",
      "stopAllSensors",
      "resetDatabase",
    ]),
  },
};
</script>
