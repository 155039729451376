/* Title: SensorService
 * Description: Fetches historical sensor readings and stati
 */

import axios from "axios";
import { ObjectId } from "mongodb";
import store from "../vuex-store/index";

// fetches and returns historical sensor redings
export async function getHistoricalSensorReadings(sensor_id: ObjectId) {
  const get_url = "api/sensor/HistoricalReading/" + sensor_id;
  return new Promise((resolve, reject) => {
    axios
      .get(get_url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        store.dispatch("newNotification", {
          text: "Historische Sensor Daten konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
          hierachy_level: 4,
        });
      });
  });
}

// fetches and returns historical sensor status
export async function getHistoricalSensorStatus(sensor_id: ObjectId) {
  const get_url = "api/sensor/HistoricalStatus/" + sensor_id;
  return new Promise((resolve, reject) => {
    axios
      .get(get_url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        store.dispatch("newNotification", {
          text: "Historische Sensor Stati konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
          hierachy_level: 4,
        });
      });
  });
}
