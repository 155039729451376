<!-- 
    Title: SelectionDialog
  
    Description: Used to select an item (and an amount if necessary) from a supplied list
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card tile>
      <!-- Dialog Title -->
      <v-card-title class="text-h5 justify-center">{{ title }}</v-card-title>

      <!-- Selection Form -->
      <v-form ref="form" v-model="form_validation">
        <v-container>
          <!-- Selection -->
          <v-select
            :items="selection_data"
            item-text="name"
            item-value="_id"
            v-model="selection_id"
            :label="label"
            :rules="[() => !!selection_id || 'Pflichtfeld']"
            required
          ></v-select>

          <!-- Amount Input -->
          <v-text-field
            v-if="display_amount"
            label="Anzahl"
            v-model="amount"
            required
            :rules="[
              () => !!amount || 'Pflichtfeld',
              () => !isNaN(Number(amount)) || 'Nur Nummern zugelassen',
            ]"
          ></v-text-field>

          <!-- Interval Input -->
          <v-text-field
            v-if="display_interval"
            label="Interval (in h)"
            v-model="interval"
            required
            :rules="[
              () => !!interval || 'Pflichtfeld',
              () => !isNaN(Number(amount)) || 'Nur Nummern zugelassen',
            ]"
          ></v-text-field>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn color="blue darken-1" text @click="cancelSelection"
          >Abbrechen</v-btn
        >

        <!-- Confirm Button -->
        <v-btn
          color="green"
          text
          @click="confirmSelection"
          :disabled="!form_validation"
          >Auswahl bestätigen</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectionDialog",

  props: {
    selection_data: [], // Data which can be selected
    mode: String, // mode in which the selection dialog is opened, mainly used to return to the mother component in order to determine which action is beeing done after selection is confirmed
    display: Boolean, //determines if the dialog is beeing displayed
    label: String, // the label of the v-select
    title: String, // title of the dialog
    display_amount: Boolean, // determines if the amount selector is displayed
    display_interval: Boolean, //determines if the interval input is displayed
  },

  data: () => ({
    selection_id: "", // holds the selected element id
    amount: 1, // holds the number of elements that should be added
    interval: 1, // input data from the interval input
    form_validation: true, // Validation data from the form INput
  }),

  methods: {
    /**
     * Title: cancelSelection()
     * Description: cancels the selection and resets the selection id
     *
     */
    cancelSelection() {
      this.$emit("cancelSelection");
      this.$refs.form.reset();
    },

    /**
     * Title: confirmsSelection()
     * Description: cinforms the selection, emits the mode of the selection (e.g. delete/edot) & the id of the selected object and resets the selection id
     *
     */
    confirmSelection() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.$emit(
          "confirmSelection",
          this.mode,
          this.selection_id,
          this.amount,
          this.interval
        );
        this.$refs.form.reset();
      }
    },
  },
};
</script>
