<template>
  <!--
    Title: Digital Twin Overview
    Purpose: Shows a general overview about the digital twin and its subordinated factorys.
  -->
  <v-container>
    <v-row>
      <!-- Page Title -->
      <v-col>
        <h1>Digitaler Zwilling</h1>
      </v-col>
    </v-row>

    <!-- KPI Cards -->
    <v-row>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktuelle Auslastung'"
          :kpi_value="dtUtilization"
          :kpi_unit="'%'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktueller Durchsatz'"
          :kpi_value="dtThroughput"
          :kpi_unit="'Baugruppen pro Stunde'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Offene Produktionsaufträge'"
          :kpi_value="
            allProductionOrders.filter(
              (production_order) => production_order.status != 0
            ).length
          "
          :kpi_unit="' '"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Offene Baugruppen'"
          :kpi_value="
            allAssemblys.filter((assembly) => assembly.status != 0).length
          "
          :kpi_unit="' '"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
    </v-row>

    <!-- All Factorys -->
    <v-row v-if="getAppLoginRights.hierachy_level <= 2">
      <v-container>
        <h2>Fabriken:</h2>
      </v-container>
    </v-row>
    <v-row v-if="getAppLoginRights.hierachy_level <= 2">
      <v-col cols="4" v-for="factory in allFactorys" :key="factory._id">
        <FactoryInfoCard :factory="factory" :title="factory.name" />
      </v-col>
    </v-row>

    <!-- All Machinegroups -->
    <v-row v-if="getAppLoginRights.hierachy_level <= 3">
      <v-container>
        <h2>Maschinengruppen:</h2>
      </v-container>
    </v-row>
    <v-row v-if="getAppLoginRights.hierachy_level <= 3">
      <v-col
        cols="4"
        v-for="machinegroup in allMachineGroups"
        :key="machinegroup._id"
      >
        <MachineGroupCard :machinegroupid="machinegroup._id" />
      </v-col>
    </v-row>

    <!-- All Machines -->
    <v-row v-if="getAppLoginRights.hierachy_level <= 4">
      <v-container>
        <h2>Maschinen:</h2>
      </v-container>
    </v-row>
    <v-row v-if="getAppLoginRights.hierachy_level <= 4">
      <v-col cols="4" v-for="machine in allMachines" :key="machine._id">
        <MachineCard :machineid="machine._id" />
      </v-col>
    </v-row>

    <!-- All Sensors -->
    <v-row v-if="getAppLoginRights.hierachy_level <= 5">
      <v-container>
        <h2>Sensoren:</h2>
      </v-container>
    </v-row>
    <v-row v-if="getAppLoginRights.hierachy_level <= 5">
      <v-col cols="4" v-for="sensor in allSensors" :key="sensor._id">
        <SensorGaugeCard :sensor_id="sensor._id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KPICard from "../general/KPICard.vue";
import FactoryInfoCard from "../factory/FactoryInfoCard.vue";
import MachineGroupCard from "../factory/MachineGroupCard.vue";
import MachineCard from "../machinegroup/MachineCard.vue";
import SensorGaugeCard from "../general/SensorGaugeCard.vue";
import { mapGetters } from "vuex";
export default {
  name: "DtOverview",

  components: {
    KPICard,
    FactoryInfoCard,
    MachineGroupCard,
    MachineCard,
    SensorGaugeCard,
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "allSensors",
      "specificFactory",
      "specificSensor",
      "specificMachineGroup",
      "allProductionOrders",
      "allAssemblys",
      "allFactorys",
      "getAppLoginRights",
      "allMachineGroups",
      "allMachines",
    ]),

    /**
     * Title: dtThroughput()
     * Description: calculates the current throughput across the digital twin
     *
     */
    dtThroughput: function () {
      let throughput = 0;
      this.allFactorys.forEach((factory) => {
        throughput = throughput + factory.current_throughput;
      });
      return throughput / this.allFactorys.length;
    },

    /**
     * Title: dtUtilization()
     * Description: calculates the current utilization across the digital twin
     *
     */
    dtUtilization: function () {
      let utilization = 0;
      this.allFactorys.forEach((factory) => {
        utilization = utilization + factory.current_status;
      });
      return ((utilization / this.allFactorys.length) * 100).toFixed(0);
    },
  },
};
</script>
