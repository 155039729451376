<!-- 
    Title: NewMachineGroupDialog
  
    Description: Dialog for adding a new machinegroup
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neue Maschinengruppe hinzufügen:</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Machinegroup Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Machinegroup Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Machinegroup Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Dialog Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewMachineGroupDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveMachineGroup"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewMachineGroupDialog", //Component Name

  props: {
    display: Boolean, // Determines weather the dialog is beeing displayed
  },

  data: () => ({
    name: "", // Name of the new machinegroup
    description: "", // Description og the new MachineGroup
    location: "", // Location of the new machinegroup
    form_validation: false,
  }),

  computed: {
    ...mapGetters(["allMachineGroupTypes"]),
  },

  methods: {
    ...mapActions(["newMachineGroup"]),

    /**
     * Title: saveMachineGroup()
     * Description: saves the new MachineGroup by calling the vuex Method
     *
     */
    saveMachineGroup() {
      // Validate Fomr
      this.form_validation = this.$refs.form.validate();

      //Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to save
        this.newMachineGroup({
          name: this.name,
          description: this.description,
          location: this.location,
        });

        // Emit Close Dialog to Parent Component
        this.$emit("closeNewMachineGroupDialog");

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
