<template>
  <v-container>
    <NavigationDrawer
      :nav_functions="nav_functions"
      :title="'Produktions Center'"
      :display_home="true"
      @newNavigationFunction="onNewNavigationFunction"
    />
    <ProductionHome
      v-if="active_nav_function === 0"
      :nav_functions="nav_functions"
      @newNavigationFunction="onNewNavigationFunction"
    />
    <ProductionProductionOrders
      v-if="active_nav_function === 1"
      ref="ProductionProductionOrder"
    />
    <ProductionAssemblies
      v-if="active_nav_function === 2"
      ref="ProductionAssemblies"
    />
    <ProductionAssemblyTypes
      v-if="active_nav_function === 3"
      ref="ProductionAssemblyTypes"
    />
    <ProductionMaterialTypes
      v-if="active_nav_function === 4"
      ref="ProductionMaterialTypes"
    />
  </v-container>
</template>

<script>
import NavigationDrawer from "../components/general/NavigationDrawer.vue";
import ProductionHome from "../components/productionCenter/pages/ProductionHome.vue";
import ProductionAssemblyTypes from "../components/productionCenter/pages/ProductionAssemblyTypes.vue";
import ProductionMaterialTypes from "../components/productionCenter/pages/ProductionMaterialTypes.vue";
import ProductionProductionOrders from "../components/productionCenter/pages/ProductionProductionOrders.vue";
import ProductionAssemblies from "../components/productionCenter/pages/ProductionAssemblies.vue";

import { mapGetters } from "vuex";

export default {
  name: "Production",

  components: {
    NavigationDrawer,
    ProductionHome,
    ProductionProductionOrders,
    ProductionAssemblyTypes,
    ProductionMaterialTypes,
    ProductionAssemblies,
  },

  computed: {
    ...mapGetters(["navigationIcons"]),

    // Data of the Functions of the Production Center, determines the Elements of the NavigationDrawer and of the FunctionCards
    nav_functions: function () {
      return [
        {
          id: 1,
          name: "Produktionsaufträge",
          icon: this.navigationIcons.production_order,
          subfunctions: [
            {
              name: "Produktionsaufträge Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
          ],
        },
        {
          id: 2,
          name: "Baugruppen",
          icon: this.navigationIcons.assembly,
          subfunctions: [{
              name: "Baugruppenübersicht",
              icon: this.navigationIcons.assembly,
              id: 1,
            },],
        },
        {
          id: 3,
          name: "Baugruppentypen",
          icon: this.navigationIcons.product_type,
          subfunctions: [
            {
              name: "Baugruppentyp Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Baugruppentyp Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Baugruppentyp Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
        {
          id: 4,
          name: "Materialtypen",
          icon: this.navigationIcons.material_type,
          subfunctions: [
            {
              name: "Materialtyp Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Materialtyp Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Materialtyp Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
      ];
    },
  },
  data: () => ({
    active_nav_function: 0, // Determines which Function is beeing displayed
  }),
  methods: {
    /**
     * Title: onNewNavigationFunction()
     * Description: is run when a new Function is selected by the user.
     *
     * @param {Number} nav_function_id the new active function, transmitted by the FunctionCard
     * @param {String} subfunction_id the new active subfunction, transmitted by the FunctionCard
     *
     */

    onNewNavigationFunction(nav_function_id, subfunction_id) {
      this.active_nav_function = nav_function_id; //Sets the Production Function Page

      // If Subfunction is called, opens the appropriate subfuntion dialog element of the individual page
      if (subfunction_id != 0) {
        this.$nextTick(() => {
          //If ProductionProductionOrder
          if (nav_function_id === 1) {
            if (subfunction_id === 1) {
              this.$refs.ProductionProductionOrder.display_new_productionorder_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.ProductionAssemblyTypes.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.ProductionAssemblyTypes.setupSelection("delete");
            }
          }


          //If ProductionAssemblyType
          else if (nav_function_id === 3) {
            if (subfunction_id === 1) {
              this.$refs.ProductionAssemblyTypes.display_new_assemblytype_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.ProductionAssemblyTypes.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.ProductionAssemblyTypes.setupSelection("delete");
            }
          }

          // If MaterialType
          else if (nav_function_id === 4) {
            if (subfunction_id === 1) {
              this.$refs.ProductionMaterialTypes.display_new_materialtype_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.ProductionMaterialTypes.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.ProductionMaterialTypes.setupSelection("delete");
            }
          }
          this.subfunction_id = 0;
        });
      }
    },
  },
};
</script>
