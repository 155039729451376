import { ObjectId } from "mongodb";

/**
 * Enum:          MachineStatus
 * Description:   Defines the possible status of a machine
 *
 * Important:     If edited remember to adapt machineMixins.ts
 *
 */

export enum MachineStatus {
  off = 0,
  free = 1,
  occupied = 2,
  stop_after_finished = 3,
}

export type Machine = {
  _id: ObjectId;
  name: string;
  current_status: MachineStatus;
  description: string;
  location: string;
  operating_time: number;
  implementation_date: Date;
  mqtt_pretopic: string;
  components: {
    sensors: ObjectId[];
  };
  type: ObjectId;
  currently_working_on: ObjectId;
};
