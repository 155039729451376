<!-- 
    Title: NewUserDialog
  
    Description: Dialog for adding a new user
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neuen Nutzer hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- Login Name Input -->
              <v-col>
                <v-text-field
                  v-model="login"
                  label="User-ID"
                  :rules="[
                    () => !!login || 'Pflichtfeld',
                    () =>
                      (!!login && login.length >= 5) || 'Mindestens 5 Zeichen',
                  ]"
                  required
                ></v-text-field>
              </v-col>

              <!-- Password Input -->
              <v-col>
                <v-text-field
                  v-model="password"
                  label="Passwort"
                  :rules="[
                    () => !!password || 'Pflichtfeld',
                    () =>
                      (!!password && password.length >= 12) ||
                      'Mindestens 12 Zeichen!',
                  ]"
                  required
                  :type="show_pw ? 'text' : 'password'"
                  :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_pw = !show_pw"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- First Name Input -->
              <v-col>
                <v-text-field
                  v-model="first_name"
                  label="Vorname"
                  :rules="[() => !!first_name || 'Pflichtfeld']"
                  required
                ></v-text-field>
              </v-col>

              <!-- Last Name Input -->
              <v-col>
                <v-text-field
                  v-model="last_name"
                  label="Nachname"
                  :rules="[() => !!last_name || 'Pflichtfeld']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Role Selector -->
            <v-row>
              <v-col>
                <v-select
                  v-model="role"
                  :items="allRoles"
                  :menu-props="{ maxHeight: '400' }"
                  :rules="[() => !!role || 'Pflichtfeld']"
                  label="Rolle"
                  item-text="name"
                  item-value="_id"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewUserDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveUser"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewUserDialog", // Component Name

  props: {
    display: Boolean, // Determines weather the dialog is beeing displayed
  },

  data: () => ({
    login: "", // The Login of the new user
    first_name: "", // Name of the new user
    last_name: "", // Name of the new user
    password: "", // Password og the new User
    role: "", // Role of the new user
    form_validation: false, // Contains Validation Data of the Input Form
    show_pw: false, // Determines weather the passowrd is beeing displayed in clear text
  }),

  computed: {
    ...mapGetters(["allRoles"]),
  },

  methods: {
    ...mapActions(["newUser"]),

    /**
     * Title: saveUser()
     * Description: saves the new User by calling the vuex Method
     *
     */
    saveUser() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      //Check Validation
      if (this.form_validation === true) {
        // Call vuex Method to save
        this.newUser({
          login: this.login,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          role: this.role,
        });

        // Emit close event to parent component
        this.$emit("closeNewUserDialog");

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
