<!-- 
    Title: AdminHome
  
    Description: Home Page of the Admin Center
-->

<template>
  <v-container>
    <!-- Page Title -->
    <h1 class="mt-2 mb-5">Admin-Center Home</h1>

    <!-- Iterative Rendering of Function Cards -->
    <v-row>
      <v-col
        cols="4"
        v-for="nav_function in nav_functions"
        :key="nav_function.id"
      >
        <FunctionCard
          :id="nav_function.id"
          :title="nav_function.name"
          :icon="nav_function.icon"
          :subfunctions="nav_function.subfunctions"
          @newNavigationFunction="onNewNavigationFunction"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FunctionCard from "../../general/FunctionCard.vue";

export default {
  name: "AdminHome", // Component Name

  components: {
    FunctionCard,
  },

  props: {
    nav_functions: [], // Navigation Functions handed down from parent component
  },

  methods: {
    /**
     * Title: onNewQuickFunction()
     * Description: is run when a new Function is selected by the user.
     *
     * @param {Number} nav_function_id the new active function, transmitted by the FunctionCard
     * @param {Number} subfuntion_id the new active subfunction, 0 if only nav_function change
     *
     */
    onNewNavigationFunction(nav_function_id, subfunction_id) {
      this.$emit("newNavigationFunction", nav_function_id, subfunction_id);
    },
  },
};
</script>
