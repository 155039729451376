<!-- 
    Title: EditMaterialTypeDialog
  
    Description: dialog for editign materialtype data
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Materialtyp bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>

              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_materialtype._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_materialtype.name"
                  label="Name"
                  :rules="[() => !!edited_materialtype.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Icon Input -->
              <v-col>
                <v-text-field
                  v-model="edited_materialtype.icon"
                  label="Icon"
                  :rules="[() => !!edited_materialtype.icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_materialtype.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_materialtype.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditMaterialTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MaterialType from "../../../../types/materialType";
export default {
  name: "EditMaterialTypeDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_materialtype: MaterialType, // the materialtype that is beeing edited
    edited_materialtype_index: Number, // the index of the edited materialtype within the vuex State
  },

  computed: {
    ...mapGetters(["allMaterialTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the FormF
  }),

  methods: {
    ...mapActions(["editMaterialType"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited materialtype by calling the vuex Method
     *
     */
    async saveEdit() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        await this.editMaterialType({
          material_type: this.edited_materialtype,
          material_type_index: this.edited_materialtype_index,
        });
        this.$emit("closeEditMaterialTypeDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
