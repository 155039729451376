<!-- 
    Title: Production Assmeblies
  
    Description: Page to manage assemblies
-->
<template>
  <v-container>
    <h1 class="mt-2 mb-5">Baugruppen</h1>
    <v-card class="mt-5" elevation="5" tile>
      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="assembly_search"
              :append-icon="navigationIcons.search"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Select for filtering by assemblytype -->
          <v-col>
            <v-select
              :items="allAssemblyTypes"
              v-model="type_filter"
              label="Typ-Filter"
              item-text="name"
              item-value="_id"
              clearable
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- table for displaying productionorder data  -->
      <v-data-table
        :headers="assembly_data_header"
        :items="assembly_data"
        item-key="_id"
        :key="assembly_data_table_key"
        :search="assembly_search"
      >
        <!-- Template for converting Operating Time -->
        <template v-slot:item.status="{ item }">
          <v-chip :color="getAssemblyStatusColor(item.status)" dark>
            {{ getAssemblyStatusText(item.status) }}
          </v-chip>
        </template>

        <!-- Template for type -->
        <template v-slot:item.type="{ item }">
          {{ specificAssemblyType(item.type).name }}
        </template>

        <!-- Template for progress-->
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            color="blue"
            :value="getAssemblyProgess(item)"
            height="25"
            >{{ getAssemblyProgess(item) }}%</v-progress-linear
          >
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              small
              class="mr-2"
              @click="openAssemblyDetailDialog(item)"
              >{{ navigationIcons.information }}</v-icon
            >
          </div>
        </template>

        <!-- Template for produced_by -->
        <template v-slot:item.produced_by="{ item }">
          {{ getMachineName(item.produced_by) }}
        </template>
      </v-data-table>
    </v-card>
    <AssemblyDetailDialog
      :display="display_assembly_detail_dialog"
      :assembly="current_assembly"
      :assembly_history="assembly_detail_history"
      @closeAssemblyDetailDialog="closeAssemblyDetailDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import helperMixins from "../../../mixins/helperMixins";
import assemblyMixins from "../../../mixins/assemblyMixins";
import AssemblyDetailDialog from "../components/ProductionAssembly/AssemblyDetailDialog.vue";
import machineMixins from "../../../mixins/machineMixins";

export default {
  name: "ProductionAssemblies",
  mixins: [helperMixins, assemblyMixins, machineMixins],

  components: {
    AssemblyDetailDialog,
  },

  computed: {
    ...mapGetters([
      "allAssemblyTypes",
      "navigationIcons",
      "specificUser",
      "specificAssemblyType",
      "specificAssembly",
      "allAssemblys",
      "specificMachine",
    ]),

    // Returns filtered assembly data
    assembly_data: function () {
      if (this.type_filter === null) {
        return this.allAssemblys;
      } else {
        return this.allAssemblys.filter(
          (assembly) => assembly.type === this.type_filter
        );
      }
    },
  },

  data: () => ({
    display_assembly_detail_dialog: false, // Determines wether the assembly detail Dialog is displayed
    assembly_search: "", // Holds search data for the search bar of the assembly data table
    assembly_data_table_key: 0, //Holds the key of the productionorder_data_table. Is needed to force vue to rerender the productionorder data table as vue does not notice a change in the vuex productionorder store after a productionorder is deleted
    type_filter: null, // the assembly type filter data

    // Table Data Header
    assembly_data_header: [
      { text: "Typ:", value: "type" },
      { text: "ID:", value: "_id" },
      { text: "Produziert auf:", value: "produced_by" },
      { text: "Status:", value: "status" },
      { text: "Fortschritt", value: "progress" },
      { text: "", value: "actions", sortable: false },
    ],
    current_assembly: {},
  }),

  methods: {
    ...mapActions([""]),

    // Opens the assembly Detail Dialog
    openAssemblyDetailDialog(assembly) {
      this.current_assembly = assembly;
      this.display_assembly_detail_dialog = true;
      console.log(this.assembly_detail_history);
    },

    // Closes the assembly detail Dialog
    closeAssemblyDetailDialog() {
      this.display_assembly_detail_dialog = false;
      this.$nextTick(() => {
        this.assembly_detail_history = {};
        this.current_assembly = {};
      });
    },
  },
};
</script>
