import { ObjectId } from "mongodb";

export enum SensorStatus {
  off = 0,
  on = 1,
  maintenance = 4,
}

export type Sensor = {
  _id: ObjectId;
  name: string;
  current_status: SensorStatus;
  description: string;
  location: string;
  operating_time: number;
  implementation_date: Date;
  mqtt_pretopic: string;
  current_reading: number;
  icon: string;
  operating_range_max: number;
  operating_range_min: number;
  optimal_operating_range_max: number;
  optimal_operating_range_min: number;
  type: ObjectId;
};
