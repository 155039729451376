<!-- 
    Title: EditSimMachineGroupDialog
  
    Description: Dialog for editing Machinegroup data of a simulatioon
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Maschinengruppe bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_machinegroup._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_machinegroup.name"
                  label="Name"
                  :rules="[
                    () => !!edited_sim_machinegroup.name || 'Pflichtfeld',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Description INput -->
              <v-col>
                <v-textarea
                  v-model="edited_sim_machinegroup.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () =>
                      !!edited_sim_machinegroup.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location INput -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sim_machinegroup.location"
                  label="Standort"
                  :rules="[
                    () => !!edited_sim_machinegroup.location || 'Pflichtfeld',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditSimMachineGroupDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SimMachineGroup from "../../../../types/machineGroup";
export default {
  name: "EditSimMachineGroupDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_sim_machinegroup: SimMachineGroup, // the edited SimMachineGroup
    edited_sim_machinegroup_index: Number, // the index of the edited SimMachineGroup within the vuex State
  },

  computed: {
    ...mapGetters(["allSimMachineGroups", "allSimMachineGroupTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the Input Form
  }),

  methods: {
    ...mapActions(["editSimMachineGroup"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited sim_machinegroup by calling the vuex Method
     *
     */
    async saveEdit() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        await this.editSimMachineGroup({
          sim_machinegroup: this.edited_sim_machinegroup,
          sim_machinegroup_index: this.edited_sim_machinegroup_index,
        });
        this.$emit("closeEditSimMachineGroupDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
