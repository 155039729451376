<template>
  <v-card tile elevation="5" height="100%" class="text-center">
    <v-card-title>Aktueller Messwert</v-card-title>
    <v-container>
      <apexchart
        class="mt-n10"
        type="radialBar"
        :options="chartOptions"
        :series="[percentageOfOperatingRange]"
        height="300px"
      ></apexchart>
      <div>
        <h3>{{ getSensorValueString(sensor) }}</h3>
      </div></v-container
    >
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import sensorMixins from "../../../mixins/sensorMixins";
export default {
  name: "SensorGaugeCardSolo",
  mixins: [sensorMixins],
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    sensor: {},
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificSensor",
      "specificMachine",
      "allSensors",
    ]),
    percentageOfOperatingRange: function () {
      if (this.sensor.current_status === 0 || this.sensor.current_status === 4) {
        return 0;
      } else {
        return (
          (this.sensor.current_reading - this.sensor.operating_range_min) /
          ((this.sensor.operating_range_max - this.sensor.operating_range_min) /
            100)
        ).toFixed(2);
      }
    },

    chartOptions: function () {
      const unit = this.sensor.unit;
      return {
        chart: {
          type: "radialBar",
          height: 350,
        },
        colors: [this.getSensorValueColor(this.sensor)],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
      };
    },
  },

  data: () => ({}),
};
</script>
