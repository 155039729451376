<template>
  <div>
    <NavigationDrawer/>
    <v-container>
      <DtOverview v-if="overviewActiveElementType === 0" />
      <FactoryOverview v-if="overviewActiveElementType === 1" />
      <MachineGroupOverview v-if="overviewActiveElementType === 2" />
      <MachineOverview v-if="overviewActiveElementType === 3" />
      <SensorOverview v-if="overviewActiveElementType === 4" />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Import of Components
import NavigationDrawer from "../components/overview/general/NavigationDrawer.vue";
import DtOverview from "../components/overview/digitaltwin/DtOverview.vue";
import FactoryOverview from "../components/overview/factory/FactoryOverview.vue";
import MachineGroupOverview from "../components/overview/machinegroup/MachineGroupOverview.vue";
import MachineOverview from "../components/overview/machine/MachineOverview.vue";
import SensorOverview from "../components/overview/sensor/SensorOverview.vue";

export default {
  components: {
    NavigationDrawer,
    DtOverview,
    FactoryOverview,
    MachineGroupOverview,
    MachineOverview,
    SensorOverview,
  },

  computed: {
    ...mapGetters(["overviewActiveElementType"]),
  },
};
</script>
