var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Fabrikverwaltung")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_factory_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Fabrik hinzufügen")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('edit')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_vm._v("Fabrik bearbeiten")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('delete')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.delete))]),_vm._v("Fabrik löschen")],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.factory_search),callback:function ($$v) {_vm.factory_search=$$v},expression:"factory_search"}})],1),_c('v-data-table',{key:_vm.factory_data_table_key,attrs:{"headers":_vm.factory_data_header,"items":_vm.allFactorys,"search":_vm.factory_search,"expanded":_vm.expanded_factory,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_factory=$event}},scopedSlots:_vm._u([{key:"item.current_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getFactoryStatusColor(item.current_status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getFactoryStatusText(item.current_status))+" ")])]}},{key:"item.operating_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertMinutesToHoursMinutes(item.operating_time))+" ")]}},{key:"item.implementation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.implementation_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditFactoryDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteFactory(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Fabrik ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('h4',{staticClass:"mb-3"},[_vm._v("Maschinengruppen:")]),_c('v-btn',{staticClass:"mr-3 mt-n7",attrs:{"color":"green","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){Object.assign(_vm.edited_factory, item);
                _vm.setupSelection('add_machinegroup_to_factory');}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.navigationIcons.add))])],1),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),_c('th')])]),_c('tbody',_vm._l((item.components.machine_groups),function(machinegroup){return _c('tr',{key:machinegroup},[_c('td',[_vm._v(_vm._s(machinegroup))]),_c('td',[_vm._v(" "+_vm._s(_vm.allMachineGroups.find( function (machinegroupall) { return machinegroupall._id === machinegroup; } ).name)+" ")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getMachineGroupStatusColor(
                          _vm.allMachineGroups.find(
                            function (machinegroupall) { return machinegroupall._id === machinegroup; }
                          ).current_status
                        )}},[_vm._v(" "+_vm._s(_vm.getMachineGroupStatusText( _vm.allMachineGroups.find( function (machinegroupall) { return machinegroupall._id === machinegroup; } ).current_status ))+" ")])],1),_c('td',[_c('v-btn',{staticClass:"mr-5 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.deleteMachineGroupFromFactory(item, machinegroup)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.remove))])],1)],1)])}),0)]),_c('h4',{staticClass:"mb-3 mt-12"},[_vm._v("Sensoren:")]),_c('v-btn',{staticClass:"mr-3 mt-n7",attrs:{"color":"green","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){Object.assign(_vm.edited_factory, item);
                _vm.setupSelection('add_sensor_to_factory');}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.navigationIcons.add))])],1),_c('v-simple-table',{staticClass:"mb-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),_c('th')])]),_c('tbody',_vm._l((item.components.sensors),function(sensor){return _c('tr',{key:sensor},[_c('td',[_vm._v(_vm._s(sensor))]),_c('td',[_vm._v(" "+_vm._s(_vm.allSensors.find(function (sensorall) { return sensorall._id === sensor; }) .name)+" ")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getSensorStatusColor(
                          _vm.allSensors.find(
                            function (sensorall) { return sensorall._id === sensor; }
                          ).current_status
                        )}},[_vm._v(" "+_vm._s(_vm.getSensorStatusText( _vm.allSensors.find( function (sensorall) { return sensorall._id === sensor; } ).current_status ))+" ")])],1),_c('td',[_c('v-btn',{staticClass:"mr-5 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.deleteSensorFromFactory(item, sensor)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.remove))])],1),_c('v-btn',{staticClass:"mr-12 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.invertFavored(sensor)}}},[(!_vm.specificSensor(sensor).favored)?_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.favoured))]):_vm._e(),(_vm.specificSensor(sensor).favored)?_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(_vm._s(_vm.navigationIcons.favoured))]):_vm._e()],1)],1)])}),0)])],1)],1)]}}])})],1),[_c('DeleteConfirmationDialog',{attrs:{"title":'Soll die gewählte Fabrik gelöscht werden?',"object_name":_vm.edited_factory.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDeleteEdit,"confirmDelete":_vm.deleteFactoryConfirmed}}),_c('SelectionDialog',{attrs:{"selection_data":_vm.selection_data,"mode":_vm.selection_mode,"display":_vm.display_select_dialog,"title":_vm.selection_title,"label":_vm.selection_label},on:{"cancelSelection":_vm.cancelSelection,"confirmSelection":_vm.confirmSelection}}),_c('NewFactoryDialog',{attrs:{"display":_vm.display_new_factory_dialog},on:{"closeNewFactoryDialog":function($event){_vm.display_new_factory_dialog = false}}}),_c('EditFactoryDialog',{attrs:{"display":_vm.display_edit_factory_dialog,"edited_factory":_vm.edited_factory,"edited_factory_index":_vm.edited_factory_index},on:{"closeEditFactoryDialog":_vm.closeEditFactoryDialog}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }