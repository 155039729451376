<!-- 
    Title: Simulation Result
  
    Description: Page to display simulation results
-->

<template>
  <v-container>
    <!-- Title -->
    <h1 class="mt-2 mb-5">Simulationsergebnisse</h1>

    <!-- Simulation Selection -->
    <v-row>
      <v-col cols="3">
        <v-select
          :items="allSimulations"
          item-text="name"
          item-value="_id"
          label="Simulation"
          v-model="selected_simulation"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Text if no simulation is selected -->
    <v-row v-if="selected_simulation === null">
      <v-col>
        <h2>Bitte Simulation auswählen</h2>
      </v-col>
    </v-row>

    <!-- SImulation Results -->
    <v-row v-if="selected_simulation != null">
      <v-col cols="4">
        <!-- Simulation Overview -->
        <v-card elevation="5" tile>
          <v-card-title>Zusammenfassung</v-card-title>
          <v-simple-table dense>
            <tbody>
              <!-- ID -->
              <tr>
                <td>ID:</td>
                <td>
                  {{ simulation._id }}
                </td>
              </tr>

              <!-- created by -->
              <tr>
                <td>Angelegt von:</td>
                <td>{{ specificUser(simulation.created_by).login }}</td>
              </tr>

              <!-- creation data -->
              <tr>
                <td>Angelegt:</td>
                <td>
                  {{ new Date(simulation.creation_date).toLocaleString() }}
                </td>
              </tr>

              <!-- finish date -->
              <tr>
                <td>Fertiggestellt:</td>
                <td>
                  {{ new Date(simulation.finish_date).toLocaleString() }}
                </td>
              </tr>

              <!-- Amount and Assembly type -->
              <tr>
                <td>Auftrag:</td>
                <td>
                  {{
                    simulation.simulationOrder[0].amount +
                    " " +
                    specificAssemblyType(
                      simulation.simulationOrder[0].subassembly_type
                    ).name
                  }}
                </td>
              </tr>

              <!-- simulated time -->
              <tr>
                <td>Simulierte Zeit:</td>
                <td>{{ (simulation.simulated_time / 3600).toFixed(0) }} h</td>
              </tr>

              <!-- description -->
              <tr>
                <td>Beschreibung:</td>
                <td>
                  {{ simulation.description }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <!-- total throughput time -->
          <v-card-text>
            <KPICard
              :kpi_title="'Durchlaufzeit'"
              :kpi_value="simulation.results.total_throughput_time.toFixed(0)"
              :kpi_unit="'Minuten'"
              :kpi_bgcolor="'#2196F3'"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Individual utilization of every simulated machine -->
      <v-col>
        <v-card tile height="100%" elevation="5">
          <v-card-title> Maschinenauslastung </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
                v-for="machine in simulation.results.machine_utilizations"
                :key="machine.name"
              >
                <KPICard
                  :kpi_title="machine.name"
                  :kpi_value="(machine.utilization * 100).toFixed(0) + '%'"
                  :kpi_bgcolor="'#607D8B'"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KPICard from "../../overview/general/KPICard.vue";
import { mapGetters } from "vuex";
export default {
  name: "SimulationResults",

  components: {
    KPICard,
  },

  computed: {
    ...mapGetters([
      "allSimulations",
      "specificSimulation",
      "specificUser",
      "specificAssemblyType",
    ]),

    // returns complete data of the selected simulation
    simulation: function () {
      return this.specificSimulation(this.selected_simulation);
    },
  },

  data: () => ({
    selected_simulation: null, // The selected simulation
  }),
};
</script>
