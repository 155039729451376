<!-- 
    Title: UtilizationCard
  
    Description: Displays historical utilizationData
-->

<template>
  <v-card tile height="100%" elevation="5">
    <v-card-title>Auslastung:</v-card-title>
    <v-card-text>
      <apexchart
        type="line"
        :options="chartOptions"
        :series="utilization_series"
        height="300px"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "UtilizationCard",

  props: {
    utilization_data: [], // rawdata
  },

  components: {
    apexchart: VueApexCharts,
  },

  computed: {
    // returns data for apexcharts
    utilization_series: function () {
      return [
        {
          name: "Status",
          data: this.utilization_data,
        },
      ];
    },
  },

  // Chart Options
  data: () => ({
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "stepline",
        width: 3,
      },
      yaxis: {
        min: 0,
        max: 100,
        decimalsInFloat: 0,
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
  }),
};
</script>
