<!-- 
    Title: SimulationBasicDataCard
  
    Description: Page of the NewSimualtionDialog for inputing basic data
-->

<template>
  <v-card tile elevation="5">
    <!-- Loading Status Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Title -->
    <v-card-title> Schritt 1 - Grunddaten</v-card-title>

    <!-- Input Form -->
    <v-card-text>
      <v-form ref="basic_data" v-model="basic_data_validation">
        <!-- name Input -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="name"
              label="Name"
              :rules="[() => !!name || 'Pflichtfeld']"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Description Input -->
        <v-row>
          <v-col>
            <v-textarea
              v-model="description"
              label="Beschreibung"
              auto-grow
              outlined
              :rules="[() => !!description || 'Pflichtfeld']"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- Assembly Seleciton -->
        <v-row>
          <v-col>
            <v-select
              :items="allAssemblyTypes"
              item-text="name"
              item-value="_id"
              v-model="assemblytype"
              :label="'Baugruppe'"
              :rules="[() => !!assemblytype || 'Pflichtfeld']"
            ></v-select>
          </v-col>

          <!-- Amount Input -->
          <v-col>
            <v-text-field
              v-model="amount"
              label="Anzahl"
              :rules="[
                () => !!amount || 'Pflichtfeld',
                () => !isNaN(Number(amount)) || 'Nur Nummern zugelassen',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Checkbox for resetting the digital model of the simulation to the current regular digital model -->
        <v-row>
          <v-col>
            <v-checkbox
              label="Simulationsmodell auf aktuelles digitales Modell zurücksetzen"
              v-model="cloneLayout"
            ></v-checkbox>
            <v-alert type="warning" icon="mdi-alert" v-if="cloneLayout">
              Das Zurücksetzen des Simulationsmodells löscht die zuvor
              verwendete Konfiguration. Diese kann nicht wiederhergestellt
              werden!
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <!-- Cancel Button -->
      <v-btn text color="red" @click="closeDialog()">Abbrechen</v-btn>

      <!-- Next Button -->
      <v-btn
        text
        color="primary"
        :disabled="!basic_data_validation"
        @click="next"
        >Weiter</v-btn
      >
    </v-card-actions>
    <v-progress-linear value="0" height="10"></v-progress-linear>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SimulationBasicDataCard",

  computed: {
    ...mapGetters(["allAssemblyTypes"]),
  },

  data: () => ({
    basic_data_validation: false, // Validation Data of the Input Form
    name: "", // Name of the Simulation
    description: "", // Descriptuion of the SImulation
    assemblytype: "", // Simulated assembly type
    amount: "", // simulated amount
    cloneLayout: false, // weather to clone/reset the digital model
    overlay: false, // determines if the loading overlay is displayed
  }),

  methods: {
    ...mapActions([
      "fetchSimFactorys",
      "fetchSimMachineGroups",
      "fetchSimMachines",
      "fetchSimSensors",
      "newSimulation",
      "changeAppLoadingStatus",
      "fetchSimAssemblys",
    ]),

    // Opens the next step in Simulation creation
    async next() {
      // Show loading Overlay
      this.overlay = true;

      // create new Simulation
      await this.newSimulation({
        name: this.name,
        description: this.description,
        productionOrder: [
          {
            subassembly_type: this.assemblytype,
            amount: this.amount,
          },
        ],
        cloneLayout: this.cloneLayout,
      });

      // Load simulation digital MOdel
      await this.fetchSimFactorys();
      await this.fetchSimMachineGroups();
      await this.fetchSimMachines();
      await this.fetchSimSensors();
      await this.fetchSimAssemblys();

      // Close Overlay
      this.overlay = false;

      // next step
      this.$emit("nextstep");
    },

    closeDialog() {
      this.$refs.basic_data.reset();
      this.$emit("closeDialog");
    },
  },
};
</script>
