<template>
  <v-container>
    <NavigationDrawer
      :nav_functions="nav_functions"
      :title="'Admin Center'"
      :display_home="true"
      @newNavigationFunction="onNewNavigationFunction"
    />
    <AdminHome
      v-if="active_nav_function === 0"
      :nav_functions="nav_functions.filter(nav_function => nav_function.id != 99)"
      @newNavigationFunction="onNewNavigationFunction"
    />
    <AdminUser v-if="active_nav_function === 1" ref="AdminUser" />
    <AdminRoles v-if="active_nav_function === 2" ref="AdminRoles" />
    <AdminFactory v-if="active_nav_function === 3" ref="AdminFactory" />
    <AdminMachineGroup
      v-if="active_nav_function === 4"
      ref="AdminMachineGroup"
    />
    <AdminMachine v-if="active_nav_function === 5" ref="AdminMachine" />
    <AdminMachineType v-if="active_nav_function === 6" ref="AdminMachineType" />
    <AdminSensor v-if="active_nav_function === 7" ref="AdminSensor" />
    <AdminSensorType v-if="active_nav_function === 8" ref="AdminSensorType" />
    <AdminMaintenanceType v-if="active_nav_function === 9" ref="AdminMaintenanceType" />
    <AdminDevTools v-if="active_nav_function === 99" ref="AdminDevTools" />
  </v-container>
</template>

<script>
// Import of Components
import NavigationDrawer from "../components/general/NavigationDrawer.vue";
import AdminHome from "../components/adminCenter/pages/AdminHome.vue";
import AdminUser from "../components/adminCenter/pages/AdminUser.vue";
import AdminRoles from "../components/adminCenter/pages/AdminRoles.vue";
import AdminMachine from "../components/adminCenter/pages/AdminMachine.vue";
import AdminFactory from "../components/adminCenter/pages/AdminFactory.vue";
import AdminMachineGroup from "../components/adminCenter/pages/AdminMachineGroup.vue";
import AdminMachineType from "../components/adminCenter/pages/AdminMachineType.vue";
import AdminSensor from "../components/adminCenter/pages/AdminSensor.vue";
import AdminSensorType from "../components/adminCenter/pages/AdminSensorType.vue";
import AdminMaintenanceType from "../components/adminCenter/pages/AdminMaintenanceType.vue";
import AdminDevTools from "../components/adminCenter/pages/AdminDevTools.vue";

import { mapGetters } from "vuex";

export default {
  name: "Admin",

  components: {
    NavigationDrawer,
    AdminHome,
    AdminUser,
    AdminRoles,
    AdminMachine,
    AdminFactory,
    AdminMachineGroup,
    AdminMachineType,
    AdminSensor,
    AdminSensorType,
    AdminMaintenanceType,
    AdminDevTools,
  },

  computed: {
    ...mapGetters(["navigationIcons"]),

    // Data of the Functions of the Admin Center, determines the Elements of the NavigationDrawer and of the FunctionCards
    nav_functions: function () {
      return [
        {
          id: 1,
          name: "Benutzer",
          icon: this.navigationIcons.user,
          subfunctions: [
            {
              name: "Nutzer Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Nutzer Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Nutzer Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
            {
              name: "Passwort zurücksetzen",
              icon: this.navigationIcons.pw_reset,
              id: 4,
            },
          ],
        },
        {
          id: 2,
          name: "Rollen",
          icon: this.navigationIcons.role,
          subfunctions: [
            {
              name: "Rolle Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Rolle Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Rolle Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
        {
          id: 3,
          name: "Fabriken",
          icon: this.navigationIcons.factory,
          subfunctions: [
            {
              name: "Fabrik Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Fabrik Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Fabrik Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
        {
          id: 4,
          name: "Maschinengruppen",
          icon: this.navigationIcons.machine_group,
          subfunctions: [
            {
              name: "Maschinengruppe Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Maschinengruppe Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Maschinengruppe Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
        {
          id: 5,
          name: "Maschinen",
          icon: this.navigationIcons.machine,
          subfunctions: [
            {
              name: "Maschinen Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Maschinen Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Maschinen Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
        {
          id: 6,
          name: "Maschinentypen",
          icon: this.navigationIcons.machine_type,
          subfunctions: [
            {
              name: "Maschinentyp Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Maschinentyp Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            {
              name: "Maschinentyp Löschen",
              icon: this.navigationIcons.delete,
              id: 3,
            },
          ],
        },
        {
          id: 7,
          name: "Sensoren",
          icon: this.navigationIcons.sensor,
          subfunctions: [
            {
              name: "Sensoren Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Sensoren Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            { name: "Sensoren Löschen", icon: "mdi-delete", id: 3 },
          ],
        },
        {
          id: 8,
          name: "Sensortypen",
          icon: this.navigationIcons.sensor_type,
          subfunctions: [
            {
              name: "Sensortyp Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Sensortyp Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            { name: "Sensortyp Löschen", icon: "mdi-delete", id: 3 },
          ],
        },
        {
          id: 9,
          name: "Wartungstypen",
          icon: this.navigationIcons.maintenance_type,
          subfunctions: [
            {
              name: "Wartungstyp Hinzufügen",
              icon: this.navigationIcons.add,
              id: 1,
            },
            {
              name: "Wartungstyp Bearbeiten",
              icon: this.navigationIcons.edit,
              id: 2,
            },
            { name: "Wartungstyp Löschen", icon: "mdi-delete", id: 3 },
          ],
        },
        {
          id: 99,
          name: "DevTools",
          icon: this.navigationIcons.dev_tools,
        },
      ];
    },
  },

  data: () => ({
    active_nav_function: 0, // Determines which Function is beeing displayed
  }),

  methods: {
    /**
     * Title: onNewNavigationFunction()
     * Description: is run when a new Function is selected by the user.
     *
     * @param {Number} nav_function_id the new active function, transmitted by the FunctionCard
     * @param {String} subfunction_id the new active subfunction, transmitted by the FunctionCard
     *
     */
    onNewNavigationFunction(nav_function_id, subfunction_id) {
      this.active_nav_function = nav_function_id; //Sets the Admin Function Page

      // If Subfunction is called, opens the appropriate subfuntion dialog element of the individual page
      if (subfunction_id != 0) {
        this.$nextTick(() => {
          // If AdminUser
          if (nav_function_id === 1) {
            if (subfunction_id === 1) {
              this.$refs.AdminUser.display_new_user_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminUser.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminUser.setupSelection("delete");
            } else if (subfunction_id === 4) {
              this.$refs.AdminUser.setupSelection("pw_reset");
            }
          }

          // If AdminRoles
          else if (nav_function_id === 2) {
            if (subfunction_id === 1) {
              this.$refs.AdminRoles.display_new_role_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminRoles.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminRoles.setupSelection("delete");
            }
          }

          // If AdminFactory
          else if (nav_function_id === 3) {
            if (subfunction_id === 1) {
              this.$refs.AdminFactory.display_new_factory_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminFactory.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminFactory.setupSelection("delete");
            }
          }

          // If AdminMachineGroup
          else if (nav_function_id === 4) {
            if (subfunction_id === 1) {
              this.$refs.AdminMachineGroup.display_new_machinegroup_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminMachineGroup.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminMachineGroup.setupSelection("delete");
            }
          }

          // If Adminmachine
          else if (nav_function_id === 5) {
            if (subfunction_id === 1) {
              this.$refs.AdminMachine.display_new_machine_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminMachine.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminMachine.setupSelection("delete");
            }
          }

          // If AdminMachineType
          else if (nav_function_id === 6) {
            if (subfunction_id === 1) {
              this.$refs.AdminMachineType.display_new_machinetype_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminMachineType.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminMachineType.setupSelection("delete");
            }
          }

          // If AdminSensor
          else if (nav_function_id === 7) {
            if (subfunction_id === 1) {
              this.$refs.AdminSensor.display_new_sensor_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminSensor.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminSensor.setupSelection("delete");
            }
          }

          // If AdminSensorType
          else if (nav_function_id === 8) {
            if (subfunction_id === 1) {
              this.$refs.AdminSensorType.display_new_sensortype_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminSensorType.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminSensorType.setupSelection("delete");
            }
          }

          // If AdminSensorType
          else if (nav_function_id === 9) {
            if (subfunction_id === 1) {
              this.$refs.AdminMaintenanceType.display_new_maintenance_dialog = true;
            } else if (subfunction_id === 2) {
              this.$refs.AdminMaintenanceType.setupSelection("edit");
            } else if (subfunction_id === 3) {
              this.$refs.AdminMaintenanceType.setupSelection("delete");
            }
          }

          this.subfunction_id = 0;
        });
      }
    },
  },
};
</script>
