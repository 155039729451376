<template>
  <v-card tile elevation="5">
    <NewSimMachineGroupDialog
      :display="display_new_sim_machinegroup_dialog"
      @closeNewSimMachineGroupDialog="closeOpenDialog()"
    />

    <EditSimMachineGroupDialog
      :display="display_edit_sim_machinegroup_dialog"
      :edited_sim_machinegroup="edited_sim_machinegroup"
      :edited_sim_machinegroup_index="edited_sim_machinegroup_index"
      @closeEditSimMachineGroupDialog="closeOpenDialog"
    />

    <DeleteConfirmationDialog
      :title="'Soll die simulierte Maschinengruppe wirklich gelöscht werden?'"
      :object_name="edited_sim_machinegroup.name"
      :display="display_delete_confirmation_dialog"
      @cancelDelete="closeOpenDialog"
      @confirmDelete="deletionConfirmed"
    />

    <SelectionDialog
      :selection_data="selection_data"
      :mode="selection_mode"
      :display="display_select_dialog"
      :title="selection_title"
      :label="selection_label"
      @cancelSelection="closeOpenDialog"
      @confirmSelection="confirmSelection"
    />

    <v-card-title> Schritt 3 - Hierarchieebene Maschinengruppe</v-card-title>
    <v-card-title>
      <v-text-field
        v-model="machinegroup_search"
        :append-icon="navigationIcons.search"
        label="Suche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="table_data_header"
      :items="allSimMachineGroups"
      :search="machinegroup_search"
      :expanded.sync="expanded_machinegroup"
      :key="machinegroup_data_table_key"
      item-key="_id"
      show-expand
    >
      <!-- Template for assigned -->
      <template v-slot:item.assigned="{ item }">
        <v-checkbox
          class="mt-2 mb-n2"
          dense
          disabled
          v-model="item.assigned"
        ></v-checkbox>
      </template>
      <!-- Template for function btns in each row -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            small
            class="mr-2"
            @click="openEditMachineGroupDialog(item)"
            >{{ navigationIcons.edit }}</v-icon
          >
          <v-icon
            small
            class="mr-2"
            :disabled="item.assigned"
            @click="confirmDeleteMachineGroup(item)"
            >{{ navigationIcons.delete }}</v-icon
          >
        </div>
      </template>
      <!-- Template for MachineGroup Details -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <h4 class="mt-3 mb-3">Maschinengruppe ID:</h4>
            <v-text-field v-model="item._id" readonly outlined></v-text-field>
            <h4 class="mt-3 mb-3">Beschreibung:</h4>
            <v-textarea
              v-model="item.description"
              readonly
              outlined
            ></v-textarea>

            <!-- MachineGroups -->
            <h4 class="mb-3">Maschinen:</h4>
            <v-btn
              color="green"
              fab
              small
              absolute
              right
              class="mr-3 mt-n7"
              @click="
                Object.assign(edited_sim_machinegroup, item);
                setupSelection('add_machine_to_machinegroup');
              "
              ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
            >
            <v-simple-table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="machine in item.components.machines" :key="machine">
                  <td>{{ machine }}</td>
                  <td>
                    {{
                      allSimMachines.find(
                        (machineall) => machineall._id === machine
                      ).name
                    }}
                  </td>
                  <td>
                    <v-btn
                      icon
                      x-small
                      absolute
                      right
                      class="mr-5 mt-n2 align-center"
                      @click="deleteMachineFromMachineGroup(item, machine)"
                      ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <!-- Sensoren -->
            <h4 class="mb-3 mt-12">Sensoren:</h4>
            <v-btn
              color="green"
              fab
              small
              absolute
              right
              class="mr-3 mt-n7"
              @click="
                Object.assign(edited_sim_machinegroup, item);
                setupSelection('add_sensor_to_machinegroup');
              "
              ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
            >
            <v-simple-table class="mb-3">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sensor in item.components.sensors" :key="sensor">
                  <td>{{ sensor }}</td>
                  <td>
                    {{
                      allSimSensors.find(
                        (sensorall) => sensorall._id === sensor
                      ).name
                    }}
                  </td>
                  <td>
                    <v-btn
                      icon
                      x-small
                      absolute
                      right
                      class="mr-5 mt-n2 align-center"
                      @click="deleteSensorFromMachineGroup(item, sensor)"
                      ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn plain tile @click="display_new_sim_machinegroup_dialog = true"
        ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
        >Maschinengruppe hinzufügen</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn text color="darkgrey" @click="$emit('previousstep')">Zurück</v-btn>
      <v-btn text color="primary" @click="$emit('nextstep')">Weiter</v-btn>
    </v-card-actions>
    <v-progress-linear value="45" height="10"></v-progress-linear>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewSimMachineGroupDialog from "./../simulationMachineGroup/NewSimMachineGroupDialog.vue";
import EditSimMachineGroupDialog from "./../simulationMachineGroup/EditSimMachineGroupDialog.vue";
import DeleteConfirmationDialog from "../../../../components/general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../../../components/general/SelectionDialog.vue";
export default {
  name: "SimulationMachineGroupCard",

  components: {
    NewSimMachineGroupDialog,
    EditSimMachineGroupDialog,
    DeleteConfirmationDialog,
    SelectionDialog,
  },

  computed: {
    ...mapGetters([
      "navigationIcons",
      "allSimMachineGroups",
      "allSimSensors",
      "allSimMachines",
      "specificSimSensor",
    ]),
  },

  data: () => ({
    display_new_sim_machinegroup_dialog: false,
    display_edit_sim_machinegroup_dialog: false,
    display_delete_confirmation_dialog: false,
    display_select_dialog: false,

    selection_title: "",
    selection_label: "",
    selection_data: [],
    selection_mode: "",

    table_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID:", value: "_id" },
      { text: "Standort:", value: "location" },
      { text: "Zugeordnet:", value: "assigned" },
      { text: "", value: "actions", sortable: false },
    ],

    machinegroup_search: "",
    machinegroup_data_table_key: 0,
    expanded_machinegroup: [],
    edited_sim_machinegroup_index: -1,
    edited_sim_machinegroup: {
      _id: "",
      components: {
        machines: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },
    standard_machinegroup: {
      _id: "",
      components: {
        machines: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },
  }),

  methods: {
    ...mapActions([
      "deleteSimMachineGroup",
      "editSimMachineGroup",
      "removeSimSensorFromSimMachineGroup",
      "removeSimMachineFromSimMachineGroup",
      "addSimSensorToSimMachineGroup",
      "addSimMachineToSimMachineGroup"
    ]),
    /**
     * Title: closeOpenDialog()
     * Description: closes every dialog
     */
    closeOpenDialog() {
      this.display_new_sim_machinegroup_dialog = false;
      this.display_edit_sim_machinegroup_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.display_select_dialog = false;

      Object.assign(this.edited_sim_machinegroup, this.standard_machinegroup);
      this.edited_sim_machinegroup_index = -1;
      this.machinegroup_data_table_key = this.machinegroup_data_table_key + 1;
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      this.selection_mode = "";
    },

    /**
     * Title: openEditMachineGroupDialog()
     * Description: opens the Edit MachineGroup Dialog and sets the edited_sim_machinegroup and edited_sim_machinegroup_index according to the machinegroup selected by the user
     *
     * @param {MachineGroup} machinegroup the machinegroup that is beeing edited
     *
     */
    openEditMachineGroupDialog(machinegroup) {
      this.edited_sim_machinegroup_index =
        this.allSimMachineGroups.indexOf(machinegroup);
      Object.assign(this.edited_sim_machinegroup, machinegroup);
      this.display_edit_sim_machinegroup_dialog = true;
    },

    /**
     * Title: deleteMachineGroup()
     * Description: opens the confirmation dialog when a machinegroup is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} machinegroup the machien that is supposed to be deleted
     *
     */
    confirmDeleteMachineGroup(machinegroup) {
      this.display_delete_confirmation_dialog = true;
      this.edited_sim_machinegroup_index =
        this.allSimMachineGroups.indexOf(machinegroup);
      Object.assign(this.edited_sim_machinegroup, machinegroup);
    },

    deletionConfirmed() {
      this.deleteSimMachineGroup(this.edited_sim_machinegroup);
      this.closeOpenDialog();
    },

    async deleteSensorFromMachineGroup(machinegroup, sim_sensor_id) {
      await this.removeSimSensorFromSimMachineGroup({
        sim_machinegroup_id: machinegroup._id,
        sim_sensor_id,
      });
      this.machinegroup_data_table_key++;
      this.closeOpenDialog();
    },

    async deleteMachineFromMachineGroup(machinegroup, sim_machine_id) {
      await this.removeSimMachineFromSimMachineGroup({
        sim_machinegroup_id: machinegroup._id,
        sim_machine_id,
      });
      this.machinegroup_data_table_key++;
      this.closeOpenDialog();
    },

    setupSelection(selection_mode) {
      this.selection_mode = selection_mode;
      if (selection_mode === "add_machine_to_machinegroup") {
        this.selection_title = "Maschine auswählen:";
        this.selection_label = "Maschine";
        this.selection_data = this.allSimMachines.filter(
          (machine) => machine.assigned === false
        );
      } else if (selection_mode === "add_sensor_to_machinegroup") {
        this.selection_title = "Sensor auswählen:";
        this.selection_label = "Sensor";
        this.selection_data = this.allSimSensors.filter(
          (sensor) => sensor.assigned === false
        );
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a machinegroup is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    async confirmSelection(mode, selection_id) {
      // If a new sensor has been added to the machinegroup
      if (mode === "add_sensor_to_machinegroup") {
        await this.addSimSensorToSimMachineGroup({
          sim_machinegroup_id: this.edited_sim_machinegroup._id,
          sim_sensor_id: selection_id,
        });
      }
      // If a new machine has been added to the machinegroup
      else if (mode === "add_machine_to_machinegroup") {
        await this.addSimMachineToSimMachineGroup({
          sim_machinegroup_id: this.edited_sim_machinegroup._id,
          sim_machine_id: selection_id,
        });
      }
      this.machinegroup_data_table_key++;
      this.closeOpenDialog();
    },
  },
};
</script>
