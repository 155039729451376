<template>
  <v-card tile elevation="5">
    <NewSimSensorDialog
      :display="display_new_sim_sensor_dialog"
      @closeNewSimSensorDialog="display_new_sim_sensor_dialog = false"
    />

    <EditSimSensorDialog
      :display="display_edit_sim_sensor_dialog"
      :edited_sim_sensor="edited_sim_sensor"
      :edited_sim_sensor_index="edited_sim_sensor_index"
      @closeEditSimSensorDialog="closeOpenDialog"
    />

    <DeleteConfirmationDialog
      :title="'Soll die simulierte Sensor wirklich gelöscht werden?'"
      :object_name="edited_sim_sensor.name"
      :display="display_delete_confirmation_dialog"
      @cancelDelete="closeOpenDialog"
      @confirmDelete="deletionConfirmed"
    />

    <v-card-title> Schritt 5 - Hierarchieebene Sensor</v-card-title>
    <v-card-title>
      <v-text-field
        v-model="sensor_search"
        :append-icon="navigationIcons.search"
        label="Suche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="table_data_header"
      :items="allSimSensors"
      :search="sensor_search"
      :expanded.sync="expanded_sensor"
      :key="sensor_data_table_key"
      item-key="_id"
      show-expand
    >
      <!-- Template for assigned -->
      <template v-slot:item.assigned="{ item }">
        <v-checkbox
          class="mt-2 mb-n2"
          dense
          disabled
          v-model="item.assigned"
        ></v-checkbox>
      </template>

      <!-- Template for function btns in each row -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon small class="mr-2" @click="openEditSensorDialog(item)">{{
            navigationIcons.edit
          }}</v-icon>
          <v-icon
            small
            class="mr-2"
            :disabled="item.assigned"
            @click="confirmDeleteSensor(item)"
            >{{ navigationIcons.delete }}</v-icon
          >
        </div>
      </template>
      <!-- Template for Sensor Details -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <h4 class="mt-3 mb-3">Sensor ID:</h4>
            <v-text-field v-model="item._id" readonly outlined></v-text-field>
            <h4 class="mt-3 mb-3">Beschreibung:</h4>
            <v-textarea
              v-model="item.description"
              readonly
              outlined
            ></v-textarea>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn plain tile @click="display_new_sim_sensor_dialog = true"
        ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
        >Sensor hinzufügen</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn text color="darkgrey" @click="$emit('previousstep')">Zurück</v-btn>
      <v-btn text color="primary" @click="$emit('nextstep')">Weiter</v-btn>
    </v-card-actions>
    <v-progress-linear value="75" height="10"></v-progress-linear>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewSimSensorDialog from "../../components/simulationSensor/NewSimSensorDialog.vue";
import EditSimSensorDialog from "../../components/simulationSensor/EditSimSensorDialog.vue";
import DeleteConfirmationDialog from "../../../../components/general/DeleteConfirmationDialog.vue";
export default {
  name: "SimulationSensorCard",

  components: {
    NewSimSensorDialog,
    EditSimSensorDialog,
    DeleteConfirmationDialog,
  },

  computed: {
    ...mapGetters([
      "navigationIcons",
      "allSimSensors",
      "allSimSensorGroups",
      "allSimSensors",
      "specificSimSensor",
    ]),
  },

  data: () => ({
    display_new_sim_sensor_dialog: false,
    display_edit_sim_sensor_dialog: false,
    display_delete_confirmation_dialog: false,
    display_select_dialog: false,

    selection_title: "",
    selection_label: "",
    selection_data: [],
    selection_mode: "",

    table_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID:", value: "_id" },
      { text: "Standort:", value: "location" },
      { text: "Zugeordnet:", value: "assigned" },
      { text: "", value: "actions", sortable: false },
    ],

    sensor_search: "",
    sensor_data_table_key: 0,
    expanded_sensor: [],
    edited_sim_sensor_index: -1,
    edited_sim_sensor: {
      _id: "",
      components: { senors: [] },
      current_status: 0,
      currently_working_on: null,
      description: "",
      implementation_data: new Date(),
      location: "",
      mqtt_pretopic: "",
      name: "",
      operating_time: 0,
      type: "",
    },
    standard_sensor: {
      _id: "",
      components: { senors: [] },
      current_status: 0,
      currently_working_on: null,
      description: "",
      implementation_data: new Date(),
      location: "",
      mqtt_pretopic: "",
      name: "",
      operating_time: 0,
      type: "",
    },
  }),

  methods: {
    ...mapActions(["deleteSimSensor", "editSimSensor"]),
    /**
     * Title: closeOpenDialog()
     * Description: closes every dialog
     */
    closeOpenDialog() {
      this.display_new_sim_sensor_dialog = false;
      this.display_edit_sim_sensor_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.display_select_dialog = false;

      Object.assign(this.edited_sim_sensor, this.standard_sensor);
      this.edited_sim_sensor_index = -1;
      this.sensor_data_table_key = this.sensor_data_table_key + 1;
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      this.selection_mode = "";
    },

    /**
     * Title: openEditSensorDialog()
     * Description: opens the Edit Sensor Dialog and sets the edited_sim_sensor and edited_sim_sensor_index according to the sensor selected by the user
     *
     * @param {Sensor} sensor the sensor that is beeing edited
     *
     */
    openEditSensorDialog(sensor) {
      this.edited_sim_sensor_index = this.allSimSensors.indexOf(sensor);
      Object.assign(this.edited_sim_sensor, sensor);
      this.display_edit_sim_sensor_dialog = true;
    },

    /**
     * Title: deleteSensor()
     * Description: opens the confirmation dialog when a sensor is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} sensor the machien that is supposed to be deleted
     *
     */
    confirmDeleteSensor(sensor) {
      this.display_delete_confirmation_dialog = true;
      this.edited_sim_sensor_index = this.allSimSensors.indexOf(sensor);
      Object.assign(this.edited_sim_sensor, sensor);
    },

    deletionConfirmed() {
      this.deleteSimSensor(this.edited_sim_sensor);
      this.closeOpenDialog();
    },
  },
};
</script>
