<!-- 
    Title: AdminMachineType
  
    Description: Sub Page of the Admin Center, used to configure Machinetype settings
-->
<template>
  <v-container>
    <h1 class="mt-2 mb-5">Maschinentypenverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_machinetype_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Maschinentyp hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Maschinentyp bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Maschinentyp löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="machinetype_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying machinetype data  -->
      <v-data-table
        :headers="machinetype_data_header"
        :items="allMachineTypes"
        :search="machinetype_search"
        :expanded.sync="expanded_machinetype"
        :key="machinetype_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template machinetype name-->
        <template v-slot:item.name="{ item }">
          <v-icon class="mr-2">{{ item.icon }}</v-icon>
          {{ item.name }}
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMillisToHoursString(item.operating_time) }} h
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              small
              class="mr-2"
              @click="openEditMachineTypeDialog(item)"
              >{{ navigationIcons.edit }}</v-icon
            >
            <v-icon
              small
              class="mr-2"
              @click="confirmDeleteMachineType(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for MachineType Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Maschinentyp ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <!-- Description -->
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>

              <!-- Maintenanceplan Table -->
              <h4 class="mb-3">Wartungsplan:</h4>

              <!-- Button to add maintenance items -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_machinetype, item);
                  setupSelection('add_item_to_maintenanceplan');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Table for maintenanceplan data -->
              <v-simple-table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Interval</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="maintenance_item in item.maintenance_plan"
                    :key="maintenance_item.maintenance_type"
                  >
                    <!-- MaintenanceType -->
                    <td>{{ maintenance_item.maintenance_type }}</td>
                    <td>
                      {{
                        specificMaintenanceType(
                          maintenance_item.maintenance_type
                        ).name
                      }}
                    </td>

                    <!-- Maintenance Interval -->
                    <td>
                      {{maintenance_item.interval/60 + " h"}}
                    </td>
                    <td>
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="deleteItemFromMaintenancePlan(item, maintenance_item)"
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll der gewählte Maschinentyp gelöscht werden?'"
        :object_name="edited_machinetype.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteMachineTypeConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        :display_interval="display_interval"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewMachineTypeDialog
        :display="display_new_machinetype_dialog"
        @closeNewMachineTypeDialog="display_new_machinetype_dialog = false"
      />

      <EditMachineTypeDialog
        :display="display_edit_machinetype_dialog"
        :edited_machinetype="edited_machinetype"
        :edited_machinetype_index="edited_machinetype_index"
        @closeEditMachineTypeDialog="closeEditMachineTypeDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewMachineTypeDialog from "../components/adminMachineType/NewMachineTypeDialog.vue";
import EditMachineTypeDialog from "../components/adminMachineType/EditMachineTypeDialog.vue";

// Import of Types
import MachineType from "../../../types/machineType";

export default {
  name: "AdminMachineType", // Component Name
  mixins: [helperMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewMachineTypeDialog,
    EditMachineTypeDialog,
  },

  computed: {
    ...mapGetters([
      "allMachineTypes",
      "navigationIcons",
      "specificMaintenanceType",
      "allMaintenanceTypes",
    ]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_machinetype_dialog: false, // Determines wether the new machinetype dialog is beeing displayed
    display_edit_machinetype_dialog: false, // Determines wether the edit machinetype dialog is beeing displayed
    selected_machinetype_id: String, // Holds the id of the selected machinetype. Is beeing determined by the selection dialog
    machinetype_search: "", // Holds search data for the search bar of the machinetype data table
    expanded_machinetype: [], // synchronices the expanded items of the machinetype data table
    edited_machinetype_index: -1, // Holds the index of the machinetype that is beeing edited. determined by the openEditMachineTypeDialog() funciton
    machinetype_data_table_key: 0, //Holds the key of the machinetype_data_table. Is needed to force vue to rerender the machinetype data table as vue does not notice a change in the vuex machinetype store after a machinetype is deleted
    display_interval: false,

    // Holds the currently being edited machinetype during edits or deletion
    edited_machinetype: {
      _id: "",
      assemblyQueue: [],
      maintenance_plan: [],
      icon: "",
      name: "",
    },

    // Standard Machinetype data used to reset edited_machinetype
    standard_machinetype: {
      _id: "",
      assemblyQueue: [],
      maintenance_plan: [],
      icon: "",
      name: "",
    },

    // Holds the Header data of the machinetype data table
    machinetype_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID", value: "_id" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions(["deleteMachineType", "editMachineType"]),

    /**
     * Title: deleteMachineType()
     * Description: opens the confirmation dialog when a machinetype is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} machinetype the machien that is supposed to be deleted
     *
     */
    confirmDeleteMachineType(machinetype) {
      this.display_delete_confirmation_dialog = true;
      this.edited_machinetype_index = this.allMachineTypes.indexOf(machinetype);
      Object.assign(this.edited_machinetype, machinetype);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_machinetype and edited_machinetype_index variable after the deletion of a machinetype has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_machinetype, this.standard_machinetype);
        this.edited_machinetype_index = -1;
      });
    },

    /**
     * Title: confirmDelete()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteMachineTypeConfirmed() {
      this.deleteMachineType(this.edited_machinetype);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a machinetype is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    async confirmSelection(mode, selection_id, amount, interval) {
      if (mode === "delete") {
        const machinetype = this.allMachineTypes.find(
          (machinetypeall) => machinetypeall._id === selection_id
        );
        Object.assign(this.edited_machinetype, machinetype);
        this.confirmDeleteMachineType(machinetype);
      } else if (mode === "edit") {
        const machinetype = this.allMachineTypes.find(
          (machinetypeall) => machinetypeall._id === selection_id
        );
        Object.assign(this.edited_machinetype, machinetype);
        this.display_edit_machinetype_dialog = true;
      } else if (mode === "add_item_to_maintenanceplan") {
        console.log(this.edited_machinetype)
        this.edited_machinetype_index = this.allMachineTypes.indexOf(
          this.edited_machinetype
        );
        const interval_in_minutes = interval * 60;
        this.edited_machinetype.maintenance_plan.push({maintenance_type: selection_id, interval: interval_in_minutes});
        await this.editMachineType({
          machine_type: this.edited_machinetype,
          machine_type_index: this.edited_machinetype,
        });
        this.machinetype_data_table_key++;
      }

      // reset selection data
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      this.display_interval = false;
    },

    /**
     * Title: openEditMachineTypeDialog()
     * Description: opens the Edit MachineType Dialog and sets the edited_machinetype and edited_machinetype_index according to the machinetype selected by the user
     *
     * @param {MachineType} machinetype the machinetype that is beeing edited
     *
     */
    openEditMachineTypeDialog(machinetype) {
      this.edited_machinetype_index = this.allMachineTypes.indexOf(machinetype);
      Object.assign(this.edited_machinetype, machinetype);
      this.display_edit_machinetype_dialog = true;
    },

    /**
     * Title: closeEditMachineTypeDialog()
     * Description: closes the Edit MachineType Dialog and resets the edited_machinetype and edited_machinetype_index
     *
     */
    closeEditMachineTypeDialog() {
      this.display_edit_machinetype_dialog = false;
      this.edited_machinetype_index = -1;
      Object.assign(this.edited_machinetype, this.standard_machinetype);
      this.machinetype_data_table_key = this.machinetype_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a machinetype is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      this.display_interval = false;
      Object.assign(this.edited_machinetype, this.standard_machinetype);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Maschinentyp";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Maschinentyp zum Bearbeiten auswählen:";
        this.selection_data = this.allMachineTypes;
      } else if (selection_mode === "delete") {
        this.selection_title = "Maschinentyp zum Löschen auswählen:";
        this.selection_data = this.allMachineTypes;
      } else if (selection_mode === "add_item_to_maintenanceplan") {
        this.selection_title = "Wartungstyp auswählen:";
        this.selection_label = "Wartungstyp";
        this.selection_data = this.allMaintenanceTypes;
        this.display_interval = true;
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: deleteItemFromMaintenancePlan)
     * Description: deletes an Item from the Maintenance Plan of a machinetype
     *
     * @param {MachineType} machinetype the machinetype that is beeing edited
     * @param {MaintenanceItem} maintenance_item the maintenance item that is beeing deleted
     *
     */
    async deleteItemFromMaintenancePlan(machinetype, maintenance_item) {
      Object.assign(this.edited_machinetype, machinetype);
      this.edited_machine_index = this.allMachineTypes.indexOf(this.edited_machinetype);
      const maintenance_item_index =
        this.edited_machinetype.maintenance_plan.indexOf(maintenance_item);
      this.edited_machinetype.maintenance_plan.splice(maintenance_item_index, 1);
      await this.editMachineType({
        machine_type: this.edited_machinetype,
        machine_type_index: this.edited_machinetype_index,
      });
      this.closeEditMachineTypeDialog;
    },
  },
};
</script>
