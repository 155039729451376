<!-- 
    Title: NewMaintenanceDialog
  
    Description: Used to add a new maintenance to a machine or sensor
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neue Wartung hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Type Selection -->
            <v-row>
              <v-col>
                <v-select
                  :items="allMaintenanceTypes"
                  item-text="name"
                  item-value="_id"
                  v-model="maintenancetype"
                  :label="'Wartungstyp'"
                  :rules="[() => !!maintenancetype || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewMaintenanceDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveMaintenance"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewMaintenanceDialog",

  props: {
    display: Boolean, // Determines weather the dialog is beeing displayed
    id: String, // the id of the sensor/machine to which the maintenance is beeing added
    hiearchical_level: String, //the hierarchical level (machine/sensor) to which the maintenance is beeing added
  },

  data: () => ({
    // Data of the new Maintenance
    name: "",
    description: "",
    maintenancetype: "",
    form_validation: false,
  }),

  computed: {
    ...mapGetters(["allMaintenanceTypes", "specificMachine", "specificSensor"]),
  },

  methods: {
    ...mapActions(["newMachineMaintenance", "newSensorMaintenance"]),

    /**
     * Title: saveMaintenance()
     * Description: saves the new Maintenance by calling the vuex Method
     *
     */
    saveMaintenance() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        if (this.hiearchical_level === "machine") {
          console.log("machine");
          this.newMachineMaintenance({
            name: this.name,
            description: this.description,
            type: this.maintenancetype,
            machine_id: this.id,
          });
        } else if (this.hiearchical_level === "sensor") {
          console.log("sensor");
          this.newSensorMaintenance({
            name: this.name,
            description: this.description,
            type: this.maintenancetype,
            sensor_id: this.id,
          });
        }
        this.$emit("closeNewMaintenanceDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
