var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Sensor bearbeiten:")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.form_validation),callback:function ($$v) {_vm.form_validation=$$v},expression:"form_validation"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"ID","disabled":""},model:{value:(_vm.edited_sim_sensor._id),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "_id", $$v)},expression:"edited_sim_sensor._id"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Name","rules":[function () { return !!_vm.edited_sim_sensor.name || 'Pflichtfeld'; }]},model:{value:(_vm.edited_sim_sensor.name),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "name", $$v)},expression:"edited_sim_sensor.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.allSensorTypes,"menu-props":{ maxHeight: '400' },"label":"Typ","item-text":"name","item-value":"_id","rules":[function () { return !!_vm.edited_sim_sensor.type || 'Pflichtfeld'; }]},model:{value:(_vm.edited_sim_sensor.type),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "type", $$v)},expression:"edited_sim_sensor.type"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Beschreibung","auto-grow":"","outlined":"","rules":[
                  function () { return !!_vm.edited_sim_sensor.description || 'Pflichtfeld'; } ]},model:{value:(_vm.edited_sim_sensor.description),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "description", $$v)},expression:"edited_sim_sensor.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Standort","rules":[function () { return !!_vm.edited_sim_sensor.location || 'Pflichtfeld'; }]},model:{value:(_vm.edited_sim_sensor.location),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "location", $$v)},expression:"edited_sim_sensor.location"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Absoluter max. Wert","rules":[
                  function () { return !!_vm.edited_sim_sensor.operating_range_max || 'Pflichtfeld'; },
                  function () { return !isNaN(Number(_vm.edited_sim_sensor.operating_range_max)) ||
                    'Nur Nummern zugelassen'; } ]},model:{value:(_vm.edited_sim_sensor.operating_range_max),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "operating_range_max", $$v)},expression:"edited_sim_sensor.operating_range_max"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Absoluter min. Wert","rules":[
                  function () { return !!_vm.edited_sim_sensor.operating_range_min || 'Pflichtfeld'; },
                  function () { return !isNaN(Number(_vm.edited_sim_sensor.operating_range_min)) ||
                    'Nur Nummern zugelassen'; } ]},model:{value:(_vm.edited_sim_sensor.operating_range_min),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "operating_range_min", $$v)},expression:"edited_sim_sensor.operating_range_min"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Optimaler max. Wert","rules":[
                  function () { return !!_vm.edited_sim_sensor.optimal_operating_range_max ||
                    'Pflichtfeld'; },
                  function () { return !isNaN(
                      Number(_vm.edited_sim_sensor.optimal_operating_range_max)
                    ) || 'Nur Nummern zugelassen'; } ]},model:{value:(_vm.edited_sim_sensor.optimal_operating_range_max),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "optimal_operating_range_max", $$v)},expression:"edited_sim_sensor.optimal_operating_range_max"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Optimaler min. Wert","rules":[
                  function () { return !!_vm.edited_sim_sensor.optimal_operating_range_min ||
                    'Pflichtfeld'; },
                  function () { return !isNaN(
                      Number(_vm.edited_sim_sensor.optimal_operating_range_min)
                    ) || 'Nur Nummern zugelassen'; } ]},model:{value:(_vm.edited_sim_sensor.optimal_operating_range_min),callback:function ($$v) {_vm.$set(_vm.edited_sim_sensor, "optimal_operating_range_min", $$v)},expression:"edited_sim_sensor.optimal_operating_range_min"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.$emit('closeEditSimSensorDialog');
          _vm.$refs.form.reset();}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"green","text":"","disabled":!_vm.form_validation},on:{"click":_vm.saveEdit}},[_vm._v(" Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }