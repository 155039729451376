<!-- 
    Title: AssemblyDetailDialog
  
    Description: Displays Assembly Details
-->
<template>
  <v-dialog v-model="display" persistent max-width="1600px">
    <v-card>
      <!-- Title -->
      <v-card-title primary-title class="mb-5">
        Baugruppendetails:
      </v-card-title>

      <!-- Assemblys -->
      <v-card-text>
        <SubAssembly :key="assembly._id" :id="assembly._id" />
      </v-card-text>

      <!-- Close Button -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('closeAssemblyDetailDialog')">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SubAssembly from "../ProductionAssembly/SubAssembly.vue";

export default {
  name: "AssemblyDetailDialog",

  components: {
    SubAssembly,
  },

  props: {
    display: Boolean, // Determines weather the dialog is beeing displayed
    assembly: {}, // the complete assembly data
  },
};
</script>
