import { activeElementType } from "@/types/overview";

export default {
  methods: {
    /* Title: activeElementTypeToIconString
     *
     * Description: Returns a String representing the currently active Element of the dt Overview
     *
     */
    activeElementTypeToIconString(activeElementType: activeElementType) {
      switch (activeElementType) {
        case 0:
          return "digital_twin";
        case 1:
          return "factory";
        case 2:
          return "machine_group";
        case 3:
          return "machine";
        case 4:
          return "sensor";
      }
    },
  },
};
