<!-- 
    Title: EditRoleDialog
  
    Description: Dialog for editing data of a specific user role
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Rolle bearbeiten:</span>
      </v-card-title>

      <!-- Input Fomr -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_role._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_role.name"
                  label="Name"
                  :rules="[() => !!edited_role.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_role.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!edited_role.description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Permission Checkboxes -->
            <v-row>
              <v-col>
                <h3>Berechtigungen:</h3>

                <!-- Admin Checkbox -->
                <v-checkbox
                  v-model="edited_role.rights.admin"
                  label="Admin"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Production Checkbox -->
                <v-checkbox
                  v-model="edited_role.rights.production"
                  label="Produktion"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Hiearchy Checkbox -->
                <v-checkbox
                  v-model="edited_role.rights.hierachy"
                  label="Hierachie"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Maintenance Checkbox -->
                <v-checkbox
                  v-model="edited_role.rights.maintenance"
                  label="Wartung"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Simulation Checkbox -->
                <v-checkbox
                  v-model="edited_role.rights.simulation"
                  label="Simulation"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Selector for hiearchy permission level -->
                <h3 class="mt-8">Hierachisches Level:</h3>
                <v-select
                  v-model="edited_role.rights.hierachy_level"
                  :items="hierachy_levels"
                  :rules="[
                    () => !!edited_role.rights.hierachy_level || 'Pflichtfeld',
                  ]"
                  label="Hierachisches Level"
                  item-text="name"
                  item-value="id"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditRoleDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Role from "../../../../types/role";
export default {
  name: "EditRoleDialog", // Component name

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_role: Role, // the edited Role
    edited_role_index: Number, // the index of the edited Role within the vuex State
  },

  computed: {
    ...mapGetters(["allRoles", "allRoleTypes"]),
  },

  data: () => ({
    form_validation: false, // Contains Validation Data of Input Form

    // Possible hierachy levels for Selector
    hierachy_levels: [
      { id: 1, name: "Alle" },
      { id: 2, name: "Fabrik" },
      { id: 3, name: "Maschinengruppe" },
      { id: 4, name: "Maschine" },
      { id: 5, name: "Sensor" },
    ],
  }),

  methods: {
    ...mapActions(["editRole"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited role by calling the vuex Method
     *
     */
    async saveEdit() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to Save
        await this.editRole({
          role: this.edited_role,
          role_index: this.edited_role_index,
        });

        // Emit Close Event to Parent Component
        this.$emit("closeEditRoleDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
