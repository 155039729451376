<!-- 
    Title: NewSimMachineGroupDialog
  
    Description: Dialog for adding a new simulated machinegroup
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Neue Maschinengruppe hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewSimMachineGroupDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveSimMachineGroup"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewSimMachineGroupDialog",

  props: {
    display: Boolean, // Determines weather the Dialog is displayed
  },

  data: () => ({
    name: "", // Name of the new sim_machinegroup
    description: "", // Description og the new SimMachineGroup
    location: "", // Location of the new sim_machinegroup
    form_validation: false, // Validation data of the input form
  }),

  computed: {
    ...mapGetters(["allSimMachineGroupTypes"]),
  },

  methods: {
    ...mapActions(["newSimMachineGroup"]),

    /**
     * Title: saveSimMachineGroup()
     * Description: saves the new SimMachineGroup by calling the vuex Method
     *
     */
    saveSimMachineGroup() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.newSimMachineGroup({
          name: this.name,
          description: this.description,
          location: this.location,
        });
        this.$emit("closeNewSimMachineGroupDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
