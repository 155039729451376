<template>
  <div>
    <v-row>
      <v-col>
        <h1>{{header_title}}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="kpi in kpi_data" :key="kpi.title">
        <KPICard 
          :kpi_title="kpi.title"
          :kpi_bgcolor="kpi.bgcolor"
          :kpi_icon="kpi.icon"
          :kpi_value="kpi.kpi"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KPICard from "./KPICard.vue"

export default {
  name: "Header",

  components: {
    KPICard,
  },

  props: {
    kpi_data: [],
    header_title: String,
  },
  
};
</script>
