<!-- 
    Title: SubAssembly
  
    Description: Displays information on a specific assembly and its subassembly
-->
<template>
  <v-container>
    <v-card elevation="5" color="grey lighten-3" tile>
      <!-- Title and Status -->
      <v-card-title>
        <h5>{{ specificAssemblyType(assembly.type).name }}</h5>
        <v-spacer></v-spacer>
        <v-chip :color="getAssemblyStatusColor(assembly.status)" dark>
          {{ getAssemblyStatusText(assembly.status) }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <!-- Assembly ID -->
            ID:
            <v-text-field
              v-model="assembly._id"
              label="ID"
              readonly
              dense
              solo
            ></v-text-field>

            <!-- Machine on which assembly has been produced -->
            Gefertigt auf:
            <v-text-field
              v-model="machine_name"
              label="Gefertigt auf:"
              readonly
              dense
              solo
            ></v-text-field>

            <!-- current Progress -->
            Fortschritt:
            <v-progress-linear
              color="blue"
              :value="getAssemblyProgess(assembly)"
              height="40"
              ><strong
                >{{ getAssemblyProgess(assembly) }}%</strong
              ></v-progress-linear
            >
          </v-col>
          <v-col>
            <!-- Creating Date -->
            Angelegt:
            <v-text-field
              v-model="creation_date"
              label="Angelegt:"
              readonly
              dense
              solo
            ></v-text-field>

            <!-- Starte Date -->
            Gestartet:
            <v-text-field
              v-model="process_started"
              label="Gestartet:"
              readonly
              dense
              solo
            ></v-text-field>

            <!-- finished date -->
            Fertiggestellt:
            <v-text-field
              v-model="process_finished"
              label="Fertiggestellt:"
              readonly
              dense
              solo
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Buttons to extend/collapse sub asssemblys -->
        <v-row>
          <v-col
            class="d-flex align-center"
            v-if="assembly.subassemblies.length != 0"
          >
            <h4>
              <v-icon
                v-if="subassemblies_extented === false"
                @click="subassemblies_extented = true"
                >{{ navigationIcons.reduced }}</v-icon
              >
              <v-icon
                v-if="subassemblies_extented === true"
                @click="subassemblies_extented = false"
                >{{ navigationIcons.extended }}</v-icon
              >
              Sub-Baugruppen
            </h4>
          </v-col>
        </v-row>

        <!-- Rekursive Subassembly rendering -->
        <div v-if="subassemblies_extented">
          <SubAssembly
            v-for="subassembly in assembly.subassemblies"
            :key="subassembly"
            :id="subassembly"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SubAssembly from "./SubAssembly.vue";
import { mapGetters } from "vuex";
import helperMixins from "../../../../mixins/helperMixins";
import assemblyMixins from "../../../../mixins/assemblyMixins";
import machineMixins from "../../../../mixins/machineMixins";
export default {
  name: "SubAssembly",

  components: {
    SubAssembly,
  },

  mixins: [helperMixins, assemblyMixins, machineMixins],

  computed: {
    ...mapGetters([
      "specificAssemblyType",
      "specificAssembly",
      "navigationIcons",
      "specificMachine",
    ]),

    // Returns the complete assembly data
    assembly: function () {
      return this.specificAssembly(this.id);
    },

    // returns the name of the machine on which a assembly has been produced
    machine_name: function () {
      if (this.assembly.produced_by === "") {
        return "-";
      } else {
        return this.getMachineName(this.assembly.produced_by);
      }
    },

    // returns the creation date and time as a stirng
    creation_date: function () {
      return (
        new Date(this.assembly.creation_date).toLocaleDateString() +
        " - " +
        new Date(this.assembly.creation_date).toLocaleTimeString()
      );
    },

    // returns the finish date and time as a stirng
    process_finished: function () {
      if (this.assembly.process_finished === null) {
        return "Nicht fertiggestellt";
      } else {
        return (
          new Date(this.assembly.process_finished).toLocaleDateString() +
          " - " +
          new Date(this.assembly.process_finished).toLocaleTimeString()
        );
      }
    },

    // returns the start date and time as a stirng
    process_started: function () {
      if (this.assembly.process_started === null) {
        return "Nicht gestartet";
      } else {
        return (
          new Date(this.assembly.process_started).toLocaleDateString() +
          " - " +
          new Date(this.assembly.process_started).toLocaleTimeString()
        );
      }
    },
  },

  props: {
    id: String, // the id of the assembly
  },

  data: () => ({
    subassemblies_extented: false, // determines weather subassemblies are extended
  }),
};
</script>
