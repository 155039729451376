<!-- 
    Title: EditSimMachineDialog
  
    Description: Dialog for editieing simulated machines
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Maschine bearbeiten:</span>
      </v-card-title>

      <!-- Input Fomr -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID FIeld -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_machine._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_machine.name"
                  label="Name"
                  :rules="[() => !!edited_sim_machine.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_sim_machine.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_sim_machine.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location INput -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sim_machine.location"
                  label="Standort"
                  :rules="[
                    () => !!edited_sim_machine.location || 'Pflichtfeld',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Machinetyp select -->
              <v-col>
                <v-select
                  v-model="edited_sim_machine.type"
                  :items="allMachineTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!edited_sim_machine.type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditSimMachineDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Machine from "../../../../types/machine";
export default {
  name: "EditSimMachineDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_sim_machine: Machine, // the edited SimMachine
    edited_sim_machine_index: Number, // the index of the edited SimMachine within the vuex State
  },

  computed: {
    ...mapGetters(["allSimMachines", "allMachineTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the INput Form
  }),

  methods: {
    ...mapActions(["editSimMachine"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited sim_machine by calling the vuex Method
     *
     */
    saveEdit() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.editSimMachine({
          sim_machine: this.edited_sim_machine,
          sim_machine_index: this.edited_sim_machine_index,
        });
        this.$emit("closeEditSimMachineDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
