var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"5"}},[_c('NewSimSensorDialog',{attrs:{"display":_vm.display_new_sim_sensor_dialog},on:{"closeNewSimSensorDialog":function($event){_vm.display_new_sim_sensor_dialog = false}}}),_c('EditSimSensorDialog',{attrs:{"display":_vm.display_edit_sim_sensor_dialog,"edited_sim_sensor":_vm.edited_sim_sensor,"edited_sim_sensor_index":_vm.edited_sim_sensor_index},on:{"closeEditSimSensorDialog":_vm.closeOpenDialog}}),_c('DeleteConfirmationDialog',{attrs:{"title":'Soll die simulierte Sensor wirklich gelöscht werden?',"object_name":_vm.edited_sim_sensor.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.closeOpenDialog,"confirmDelete":_vm.deletionConfirmed}}),_c('v-card-title',[_vm._v(" Schritt 5 - Hierarchieebene Sensor")]),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.sensor_search),callback:function ($$v) {_vm.sensor_search=$$v},expression:"sensor_search"}})],1),_c('v-data-table',{key:_vm.sensor_data_table_key,attrs:{"headers":_vm.table_data_header,"items":_vm.allSimSensors,"search":_vm.sensor_search,"expanded":_vm.expanded_sensor,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_sensor=$event}},scopedSlots:_vm._u([{key:"item.assigned",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-2 mb-n2",attrs:{"dense":"","disabled":""},model:{value:(item.assigned),callback:function ($$v) {_vm.$set(item, "assigned", $$v)},expression:"item.assigned"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditSensorDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":item.assigned},on:{"click":function($event){return _vm.confirmDeleteSensor(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Sensor ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)]}}])}),_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_sim_sensor_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Sensor hinzufügen")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"darkgrey"},on:{"click":function($event){return _vm.$emit('previousstep')}}},[_vm._v("Zurück")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('nextstep')}}},[_vm._v("Weiter")])],1),_c('v-progress-linear',{attrs:{"value":"75","height":"10"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }