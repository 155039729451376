<!-- 
    Title: SimulationNewSimulationDialog
  
    Description: Dialog for adding a new simulation
-->
<template>
  <v-container>
    <v-dialog v-model="display" persistent max-width="1600px">
      <SimulationBasicDataCard
        v-if="step === 1"
        @nextstep="step++"
        @previousstep="step--"
        @closeDialog="
          step = 1;
          $emit('closeDialog');
        "
      />

      <SimulationFactoryCard
        v-if="step === 2"
        @nextstep="step++"
        @previousstep="step--"
      />

      <SimulationMachineGroupCard
        v-if="step === 3"
        @nextstep="step++"
        @previousstep="step--"
      />

      <SimulationMachineCard
        v-if="step === 4"
        @nextstep="step++"
        @previousstep="step--"
      />

      <SimulationSensorCard
        v-if="step === 5"
        @nextstep="step++"
        @previousstep="step--"
      />

      <SimulationStartCard
        v-if="step === 6"
        @close="
          step = 1;
          $emit('closeDialog');
        "
        @previousstep="step--"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SimulationBasicDataCard from "./SimulationBasicDataCard.vue";
import SimulationFactoryCard from "./SimulationFactoryCard.vue";
import SimulationMachineGroupCard from "./SimulationMachineGroupCard.vue";
import SimulationMachineCard from "./SimulationMachineCard.vue";
import SimulationSensorCard from "./SimulationSensorCard.vue";
import SimulationStartCard from "./SimulationStartCard.vue";

export default {
  name: "NewSimulationDialog",

  components: {
    SimulationBasicDataCard,
    SimulationFactoryCard,
    SimulationMachineGroupCard,
    SimulationMachineCard,
    SimulationSensorCard,
    SimulationStartCard,
  },

  computed: {
    ...mapGetters(["navigationIcons"]),
  },

  props: {
    display: Boolean, // Determines weather the Dialog is displayed
  },

  data: () => ({
    step: 1, // the current step of the simulation creation dialog
  }),
};
</script>
