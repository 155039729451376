<!-- 
    Title: AdminMachineGroup
  
    Description: Sub Page of the Admin Center, used to configure Machinegroup settings
-->

<template>
  <v-container>
    <h1 class="mt-2 mb-5">Maschinengruppeverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_machinegroup_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Maschinengruppe hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Maschinengruppe bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Maschinengruppe löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="machinegroup_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying machinegroup data  -->
      <v-data-table
        :headers="machinegroup_data_header"
        :items="allMachineGroups"
        :search="machinegroup_search"
        :expanded.sync="expanded_machinegroup"
        :key="machinegroup_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template for colored status chips -->
        <template v-slot:item.current_status="{ item }">
          <v-chip :color="getMachineGroupStatusColor(item.current_status)" dark>
            {{ getMachineGroupStatusText(item.current_status) }}
          </v-chip>
        </template>

        <!-- Template for assigned -->
        <template v-slot:item.assigned="{ item }">
          <v-checkbox
            class="mt-2 mb-n2"
            dense
            disabled
            v-model="item.assigned"
          ></v-checkbox>
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMinutesToHoursMinutes(item.operating_time) }}
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <!-- Edit Button -->
            <v-icon
              small
              class="mr-2"
              @click="openEditMachineGroupDialog(item)"
              >{{ navigationIcons.edit }}</v-icon
            >

            <!-- Delete Button -->
            <v-icon
              small
              class="mr-2"
              :disabled="item.assigned"
              @click="confirmDeleteMachineGroup(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for MachineGroup Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Maschinengruppen ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <!-- Description -->
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>

              <!-- Machines within the Machinegroup -->
              <h4 class="mb-3">Maschinen:</h4>

              <!-- Button to add a new machine -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_machinegroup, item);
                  setupSelection('add_machine_to_machinegroup');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Table for Machine Data -->
              <v-simple-table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="machine in item.components.machines"
                    :key="machine"
                  >
                    <!-- Machine ID -->
                    <td>{{ machine }}</td>

                    <!-- Machine Name -->
                    <td>
                      {{
                        allMachines.find(
                          (machineall) => machineall._id === machine
                        ).name
                      }}
                    </td>

                    <!-- Machine Status Chip -->
                    <td>
                      <v-chip
                        :color="
                          getMachineStatusColor(
                            allMachines.find(
                              (machineall) => machineall._id === machine
                            ).current_status
                          )
                        "
                      >
                        {{
                          getMachineStatusText(
                            allMachines.find(
                              (machineall) => machineall._id === machine
                            ).current_status
                          )
                        }}
                      </v-chip>
                    </td>

                    <!-- Delete Machine from Machinegroup Button -->
                    <td>
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="deleteMachineFromMachineGroup(item, machine)"
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <!-- Sensors within Machinegroup -->
              <h4 class="mb-3 mt-12">Sensoren:</h4>

              <!-- Add Sensor button -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_machinegroup, item);
                  setupSelection('add_sensor_to_machinegroup');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Sensor Data Table -->
              <v-simple-table class="mb-3">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sensor in item.components.sensors" :key="sensor">
                    <!-- Sensor ID -->
                    <td>{{ sensor }}</td>

                    <!-- Sensor Name -->
                    <td>
                      {{
                        allSensors.find((sensorall) => sensorall._id === sensor)
                          .name
                      }}
                    </td>

                    <!-- Sensor Status -->
                    <td>
                      <v-chip
                        :color="
                          getSensorStatusColor(
                            allSensors.find(
                              (sensorall) => sensorall._id === sensor
                            ).current_status
                          )
                        "
                      >
                        {{
                          getSensorStatusText(
                            allSensors.find(
                              (sensorall) => sensorall._id === sensor
                            ).current_status
                          )
                        }}
                      </v-chip>
                    </td>
                    <td>
                      <!-- DElete Sensor Button -->
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="deleteSensorFromMachineGroup(item, sensor)"
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >

                      <!-- Favor Sensor Button -->
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-12 mt-n2 align-center"
                        @click="invertFavored(sensor)"
                      >
                        <v-icon v-if="!specificSensor(sensor).favored">{{
                          navigationIcons.favoured
                        }}</v-icon>
                        <v-icon
                          color="yellow darken-3"
                          v-if="specificSensor(sensor).favored"
                          >{{ navigationIcons.favoured }}</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll die gewählte Maschinengruppe gelöscht werden?'"
        :object_name="edited_machinegroup.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteMachineGroupConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewMachineGroupDialog
        :display="display_new_machinegroup_dialog"
        @closeNewMachineGroupDialog="display_new_machinegroup_dialog = false"
      />

      <EditMachineGroupDialog
        :display="display_edit_machinegroup_dialog"
        :edited_machinegroup="edited_machinegroup"
        :edited_machinegroup_index="edited_machinegroup_index"
        @closeEditMachineGroupDialog="closeEditMachineGroupDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import machinegroupMixins from "../../../mixins/machinegroupMixins";
import machineMixins from "../../../mixins/machineMixins";
import sensorMixins from "../../../mixins/sensorMixins";
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewMachineGroupDialog from "../components/adminMachineGroup/NewMachineGroupDialog.vue";
import EditMachineGroupDialog from "../components/adminMachineGroup/EditMachineGroupDialog.vue";

// Import of Types
import MachineGroup from "../../../types/machineGroup";

export default {
  name: "AdminMachineGroup", // Component Name
  mixins: [machinegroupMixins, helperMixins, sensorMixins, machineMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewMachineGroupDialog,
    EditMachineGroupDialog,
  },

  computed: {
    ...mapGetters([
      "allMachineGroups",
      "allSensors",
      "allMachines",
      "navigationIcons",
      "specificSensor",
    ]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_machinegroup_dialog: false, // Determines wether the new machinegroup dialog is beeing displayed
    display_edit_machinegroup_dialog: false, // Determines wether the edit machinegroup dialog is beeing displayed
    selected_machinegroup_id: String, // Holds the id of the selected machinegroup. Is beeing determined by the selection dialog
    machinegroup_search: "", // Holds search data for the search bar of the machinegroup data table
    expanded_machinegroup: [], // synchronices the expanded items of the machinegroup data table
    edited_machinegroup_index: -1, // Holds the index of the machinegroup that is beeing edited. determined by the openEditMachineGroupDialog() funciton
    machinegroup_data_table_key: 0, //Holds the key of the machinegroup_data_table. Is needed to force vue to rerender the machinegroup data table as vue does not notice a change in the vuex machinegroup store after a machinegroup is deleted

    // Holds the currently being edited machinegroup during edits or deletion
    edited_machinegroup: {
      _id: "",
      components: {
        machines: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },

    // Standard Machinegroup data, used to reset edited_machinegroup
    standard_machinegroup: {
      _id: "",
      components: {
        machines: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },

    // Holds the Header data of the machinegroup data table
    machinegroup_data_header: [
      { text: "Name:", value: "name" },
      { text: "Status:", value: "current_status" },
      { text: "Zugeordnet:", value: "assigned" },
      { text: "Standort:", value: "location" },
      { text: "Laufzeit (h):", value: "operating_time" },
      { text: "Start Datum:", value: "implementation_date" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions([
      "deleteMachineGroup",
      "editMachineGroup",
      "editSensor",
      "addMachineToMachineGroup",
      "addSensorToMachineGroup",
      "removeMachineFromMachineGroup",
      "removeSensorFromMachineGroup",
    ]),

    /**
     * Title: deleteMachineGroup()
     * Description: opens the confirmation dialog when a machinegroup is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} machinegroup the machien that is supposed to be deleted
     *
     */
    confirmDeleteMachineGroup(machinegroup) {
      this.display_delete_confirmation_dialog = true;
      this.edited_machinegroup_index =
        this.allMachineGroups.indexOf(machinegroup);
      Object.assign(this.edited_machinegroup, machinegroup);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_machinegroup and edited_machinegroup_index variable after the deletion of a machinegroup has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_machinegroup, this.standard_machinegroup);
        this.edited_machinegroup_index = -1;
      });
    },

    /**
     * Title: confirmDelete()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteMachineGroupConfirmed() {
      this.deleteMachineGroup(this.edited_machinegroup);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a machinegroup is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    async confirmSelection(mode, selection_id) {
      if (mode === "delete") {
        const machinegroup = this.allMachineGroups.find(
          (machinegroupall) => machinegroupall._id === selection_id
        );
        Object.assign(this.edited_machinegroup, machinegroup);
        this.confirmDeleteMachineGroup(machinegroup);
      } else if (mode === "edit") {
        const machinegroup = this.allMachineGroups.find(
          (machinegroupall) => machinegroupall._id === selection_id
        );
        Object.assign(this.edited_machinegroup, machinegroup);
        this.display_edit_machinegroup_dialog = true;
      }

      // If a new sensor has been added to the machinegroup
      else if (mode === "add_sensor_to_machinegroup") {
        await this.addSensorToMachineGroup({
          machinegroup_id: this.edited_machinegroup._id,
          sensor_id: selection_id,
        });
      }

      // If a new machine has been added to the machinegroup
      else if (mode === "add_machine_to_machinegroup") {
        await this.addMachineToMachineGroup({
          machinegroup_id: this.edited_machinegroup._id,
          machine_id: selection_id,
        });
      }

      // reset selection data and froce table update
      this.machinegroup_data_table_key++;
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditMachineGroupDialog()
     * Description: opens the Edit MachineGroup Dialog and sets the edited_machinegroup and edited_machinegroup_index according to the machinegroup selected by the user
     *
     * @param {MachineGroup} machinegroup the machinegroup that is beeing edited
     *
     */
    openEditMachineGroupDialog(machinegroup) {
      this.edited_machinegroup_index =
        this.allMachineGroups.indexOf(machinegroup);
      Object.assign(this.edited_machinegroup, machinegroup);
      this.display_edit_machinegroup_dialog = true;
    },

    /**
     * Title: closeEditMachineGroupDialog()
     * Description: closes the Edit MachineGroup Dialog and resets the edited_machinegroup and edited_machinegroup_index
     *
     */
    closeEditMachineGroupDialog() {
      this.display_edit_machinegroup_dialog = false;
      this.edited_machinegroup_index = -1;
      Object.assign(this.edited_machinegroup, this.standard_machinegroup);
      this.machinegroup_data_table_key = this.machinegroup_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a machinegroup is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_machinegroup, this.standard_machinegroup);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Maschinengruppe";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Maschinengruppe zum Bearbeiten auswählen:";
        this.selection_data = this.allMachineGroups;
      } else if (selection_mode === "delete") {
        this.selection_title = "Maschinengruppe zum Löschen auswählen:";
        this.selection_data = this.allMachineGroups;
      } else if (selection_mode === "add_machine_to_machinegroup") {
        this.selection_title = "Maschine auswählen:";
        this.selection_label = "Maschine";
        this.selection_data = this.allMachines.filter(
          (machine) => machine.assigned === false
        );
      } else if (selection_mode === "add_sensor_to_machinegroup") {
        this.selection_title = "Sensor auswählen:";
        this.selection_label = "Sensor";
        this.selection_data = this.allSensors.filter(
          (sensor) => sensor.assigned === false
        );
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: deleteSensorFromMachineGroup()
     * Description: deletes a Sensor from a machinegroup
     *
     * @param {MachineGroup} machinegroup the machinegroup that is beeing edited
     * @param {ObjectID} sensor_id the id of the Sensor that is beeing deleted
     *
     */
    async deleteSensorFromMachineGroup(machinegroup, sensor_id) {
      await this.removeSensorFromMachineGroup({
        machinegroup_id: machinegroup._id,
        sensor_id,
      });
      this.machinegroup_data_table_key++;
      this.closeEditMachineGroupDialog;
    },

    /**
     * Title: deleteMachineFromMachineGroup()
     * Description: deletes a Machien from a machinegroup
     *
     * @param {MachineGroup} machinegroup the machinegroup that is beeing edited
     * @param {ObjectID} machine_id the id of the Machine that is beeing deleted
     *
     */
    async deleteMachineFromMachineGroup(machinegroup, machine_id) {
      await this.removeMachineFromMachineGroup({
        machinegroup_id: machinegroup._id,
        machine_id,
      });
      this.machinegroup_data_table_key++;
      this.closeEditMachineGroupDialog;
    },

    /**
     * Title: invertFavored()
     * Description: inverts the favored status of a sensor
     *
     * @param {Stirng} sensor_id the sensor which is beeing edited
     *
     */
    async invertFavored(sensor_id) {
      var edited_sensor = {};
      Object.assign(edited_sensor, this.specificSensor(sensor_id));
      const edited_sensor_index = this.allSensors.indexOf(
        this.specificSensor(sensor_id)
      );
      edited_sensor.favored = !edited_sensor.favored;
      await this.editSensor({
        sensor: edited_sensor,
        sensor_index: edited_sensor_index,
      });
      this.machinegroup_data_table_key = this.machinegroup_data_table_key + 1;
    },
  },
};
</script>
