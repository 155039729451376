<!-- 
    Title: NewSensorDialog
  
    Description: Dialog for adding a new sensor
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neue Sensor hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Sensortype Selector -->
            <v-row>
              <v-col>
                <v-select
                  v-model="type"
                  :items="allSensorTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Operating Range Max Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="operating_range_max"
                  label="Absoluter max. Wert"
                  :rules="[
                    () => !!operating_range_max || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(operating_range_max)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Operating Range Min Input -->
              <v-col>
                <v-text-field
                  v-model="operating_range_min"
                  label="Absoluter min. Wert"
                  :rules="[
                    () => !!operating_range_min || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(operating_range_min)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Optimal Operating Range Max Input -->
              <v-col>
                <v-text-field
                  v-model="optimal_operating_range_max"
                  label="Optimaler max. Wert"
                  :rules="[
                    () => !!optimal_operating_range_max || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(optimal_operating_range_max)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Optimal Operating Rang min Input -->
              <v-col>
                <v-text-field
                  v-model="optimal_operating_range_min"
                  label="Optimaler min. Wert"
                  :rules="[
                    () => !!optimal_operating_range_min || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(optimal_operating_range_min)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Dialog Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewSensorDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveSensor"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewSensorDialog", // Component Name

  props: {
    display: Boolean, // Determines if Dialog is beeing displayed
  },

  data: () => ({
    // Data of the new sensor
    name: "",
    description: "",
    location: "",
    operating_range_max: "",
    operating_range_min: "",
    optimal_operating_range_max: "",
    optimal_operating_range_min: "",
    type: "",
    form_validation: false,
  }),

  computed: {
    ...mapGetters(["allSensorTypes"]),
  },

  methods: {
    ...mapActions(["newSensor"]),

    /**
     * Title: saveSensor()
     * Description: saves the new Sensor by calling the vuex Method
     *
     */
    saveSensor() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to save
        this.newSensor({
          name: this.name,
          description: this.description,
          location: this.location,
          type: this.type,
          operating_range_max: this.operating_range_max,
          operating_range_min: this.operating_range_min,
          optimal_operating_range_max: this.optimal_operating_range_max,
          optimal_operating_range_min: this.optimal_operating_range_min,
        });

        // Emit Close Dialog event to Parent Component
        this.$emit("closeNewSensorDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
