var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Simulationsübersicht")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[(_vm.display_alert)?_c('v-container',[_c('v-alert',{attrs:{"prominent":"","type":"info","icon":"mdi-information"}},[_c('h2',{staticClass:"mb-3 ml-3"},[_vm._v("Neue Simulation nicht verfügbar!")]),_c('p',{staticClass:"ml-3"},[_vm._v(" Aktuell kann keine neue Simulation gestartet werden, da das System bereits mit der Berechnung einer anderen Simulation beschäftigt ist. ")])])],1):_vm._e(),_c('v-card-actions',[(!_vm.display_alert)?_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_simulation_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Simulation hinzufügen")],1):_vm._e()],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.sim_simulation_search),callback:function ($$v) {_vm.sim_simulation_search=$$v},expression:"sim_simulation_search"}})],1),_c('v-data-table',{key:_vm.sim_simulation_data_table_key,attrs:{"headers":_vm.simulation_data_header,"items":_vm.allSimulations,"search":_vm.sim_simulation_search,"item-key":"_id","show-expand":""},scopedSlots:_vm._u([{key:"item.simulationOrder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificAssemblyType(item.simulationOrder[0].subassembly_type).name)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.simulationOrder[0].amount)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getSimulationStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getSimulationStatusText(item.status))+" ")])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificUser(item.created_by).login)+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":"blue","value":_vm.getSimulationProgess(item),"height":"25"}},[_vm._v(_vm._s(_vm.getSimulationProgess(item))+"%")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.creation_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.setupDelete(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Simulations ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)]}}])})],1),[_c('NewSimulationDialog',{attrs:{"display":_vm.display_new_simulation_dialog},on:{"closeDialog":function($event){_vm.display_new_simulation_dialog = false}}}),_c('DeleteConfirmationDialog',{attrs:{"title":'Soll die gewählte Simulation gelöscht werden?',"object_name":_vm.deleted_simulation.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDelete,"confirmDelete":_vm.confirmDelete}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }