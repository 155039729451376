<!-- 
    Title: ProductionProductionOrders
  
    Description: Page to manage productionorders
-->
<template>
  <v-container>
    <!-- Title -->
    <h1 class="mt-2 mb-5">Produktionsaufträge</h1>

    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_productionorder_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Produktionsauftrag hinzufügen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="productionorder_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying productionorder data  -->
      <v-data-table
        :headers="productionorder_data_header"
        :items="allProductionOrders"
        :search="productionorder_search"
        :key="productionorder_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Assemblytype Name -->
        <template v-slot:item.assemblies="{ item }">
          {{
            specificAssemblyType(specificAssembly(item.assemblies[0]).type).name
          }}
        </template>

        <!-- Production AMoiunt -->
        <template v-slot:item.amount="{ item }">
          {{ item.assemblies.length }}
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.status="{ item }">
          <v-chip :color="getProductionOrderStatusColor(item.status)" dark>
            {{ getProductionOrderStatusText(item.status) }}
          </v-chip>
        </template>

        <!-- Template for converting created by -->
        <template v-slot:item.created_by="{ item }">
          {{ specificUser(item.created_by).login }}
        </template>

        <!-- Template for progress-->
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            color="blue"
            :value="getProductionOrderProgess(item)"
            height="25"
            >{{
              getProductionOrderProgess(item).toFixed(0)
            }}%</v-progress-linear
          >
        </template>

        <!-- Template for creation_date -->
        <template v-slot:item.creation_date="{ item }">
          {{ convertDateToString(item.creation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              small
              class="mr-2"
              @click="confirmDeleteProductionOrder(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for Assembly Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Auftrags ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <!-- Assemblys -->
              <h4 class="mb-3 mt-12">Baugruppen:</h4>
              <v-simple-table class="mb-3">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Status</th>
                    <th>Typ</th>
                    <th>Fortschritt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="assembly in item.assemblies" :key="assembly">
                    <!-- Assembly ID -->
                    <td>{{ assembly }}</td>

                    <!-- Status -->
                    <td>
                      <v-chip
                        :color="
                          getAssemblyStatusColor(
                            specificAssembly(assembly).status
                          )
                        "
                        dark
                      >
                        {{
                          getAssemblyStatusText(
                            specificAssembly(assembly).status
                          )
                        }}
                      </v-chip>
                    </td>

                    <!-- Assemblytype -->
                    <td>
                      {{
                        specificAssemblyType(specificAssembly(assembly).type)
                          .name
                      }}
                    </td>

                    <!-- Progress -->
                    <td>
                      <v-progress-linear
                        color="blue"
                        :value="getAssemblyProgess(specificAssembly(assembly))"
                        height="25"
                        >{{
                          getAssemblyProgess(specificAssembly(assembly))
                        }}%</v-progress-linear
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <NewProductionOrderDialog
        :display="display_new_productionorder_dialog"
        @closeNewProductionOrderDialog="
          display_new_productionorder_dialog = false
        "
      />

      <DeleteConfirmationDialog
        :title="'Soll der gewählte Produktionsauftrag gelöscht werden?'"
        :object_name="edited_productionorder.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteProductionOrderConfirmed"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import helperMixins from "../../../mixins/helperMixins";
import productionorderMixins from "../../../mixins/productionorderMixins";
import assemblyMixins from "../../../mixins/assemblyMixins";
// Import of Components
import NewProductionOrderDialog from "../components/ProductionProductionOrders/NewProductionOrderDialog.vue";
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";

export default {
  name: "ProductionProductionOrder",
  mixins: [helperMixins, productionorderMixins, assemblyMixins],

  components: {
    NewProductionOrderDialog,
    DeleteConfirmationDialog,
  },

  computed: {
    ...mapGetters([
      "allProductionOrders",
      "navigationIcons",
      "specificUser",
      "specificAssemblyType",
      "specificAssembly",
      "allAssemblys",
    ]),
  },

  data: () => ({
    display_new_productionorder_dialog: false, // Determines wether the new productionOrder Dialog is displayed
    productionorder_search: "", // Holds search data for the search bar of the productionorder data table
    productionorder_data_table_key: 0, //Holds the key of the productionorder_data_table. Is needed to force vue to rerender the productionorder data table as vue does not notice a change in the vuex productionorder store after a productionorder is deleted
    edited_productionorder_index: -1, // The Index of an edited production Order
    display_delete_confirmation_dialog: false, // Determines weather the confirmation dialog is beeing displayed

    // The currently edited/delted production order
    edited_productionorder: {
      _id: "",
      name: "",
      assemblies: [],
      created_by: "",
      status: 0,
      creation_date: new Date(),
    },

    // Standard production Order data used to reset edite_productionorder
    standard_productionorder: {
      _id: "",
      name: "",
      assemblies: [],
      created_by: "",
      status: 0,
      creation_date: new Date(),
    },

    // Holds the Header data of the productionorder data table
    productionorder_data_header: [
      { text: "Name:", value: "name" },
      { text: "Baugruppe:", value: "assemblies" },
      { text: "Anzahl", value: "amount" },
      { text: "Status", value: "status" },
      { text: "Fortschritt", value: "progress" },
      { text: "Erstellt von", value: "created_by" },
      { text: "Erstellt am", value: "creation_date" },
      { text: "", value: "actions", sortable: false },
    ],

    // Table Header Data fpr Assemblys
    assembly_data_header: [
      { text: "ID:", value: "_id" },
      { text: "Status:", value: "status" },
      { text: "Typ:", value: "type" },
      { text: "Fortschritt", value: "progress" },
    ],
  }),

  methods: {
    ...mapActions(["deleteProductionOrder"]),

    // Opens the confirmation dialog if production order is deleted
    confirmDeleteProductionOrder(productionorder) {
      this.edited_productionorder_index =
        this.allProductionOrders.indexOf(productionorder);
      Object.assign(this.edited_productionorder, productionorder);
      this.display_delete_confirmation_dialog = true;
    },

    // canecls the deletion of a production order
    cancelDeleteEdit() {
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(
          this.edited_productionorder,
          this.standard_productionorder
        );
        this.edited_productionorder_index = -1;
      });
    },

    // deletes a production order after the confirmation
    deleteProductionOrderConfirmed() {
      this.deleteProductionOrder(this.edited_productionorder);
      this.cancelDeleteEdit();
    },
  },
};
</script>
