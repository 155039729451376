<!-- 
    Title: MachineGroupInfoCard
  
    Description: Displays detailed Infromation of a machinegroup
-->
<template>
  <v-card tile height="100%" elevation="5">
    <!-- Title -->
    <v-card-title>Kurzinformationen</v-card-title>

    <!-- Machinegroup Data Tbale -->
    <v-simple-table dense>
      <tbody>
        <!-- ID -->
        <tr>
          <td>ID:</td>
          <td>
            {{ machinegroup._id }}
          </td>
        </tr>

        <!-- Utilization -->
        <tr>
          <td>Auslastung:</td>
          <td>{{ (machinegroup.current_status * 100).toFixed(0) }}%</td>
        </tr>

        <!-- Location -->
        <tr>
          <td>Standort:</td>
          <td>
            {{ machinegroup.location }}
          </td>
        </tr>

        <!-- Operating Time -->
        <tr>
          <td>Betriebszeit:</td>
          <td>
            {{ convertMinutesToHoursMinutes(machinegroup.operating_time) }}
          </td>
        </tr>

        <!-- Number of Machines -->
        <tr>
          <td>Maschinen:</td>
          <td>
            {{ machinegroup.components.machines.length }}
          </td>
        </tr>

        <!-- Number of Sensors -->
        <tr>
          <td>Sensoren:</td>
          <td>
            {{ machinegroup.components.sensors.length }}
          </td>
        </tr>

        <!-- Implementation Data -->
        <tr>
          <td>Erstellt am:</td>
          <td>
            {{ new Date(machinegroup.implementation_date).toLocaleString() }}
          </td>
        </tr>

        <!-- Description -->
        <tr>
          <td>Beschreibung:</td>
          <td>
            {{ machinegroup.description }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import machinegroupMixins from "../../../mixins/machinegroupMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "MachineGroupInfoCard",
  mixins: [machinegroupMixins, helperMixins],

  computed: {
    ...mapGetters(["overviewActiveElementId", "specificMachineGroup"]),
  },

  props: {
    machinegroup: {}, // The machinegroup data
  },
};
</script>
