<!-- 
    Title: ProductionMaterialtypes
  
    Description: Page to manage materialtypes
-->
<template>
  <v-container>
    <h1 class="mt-2 mb-5">Materialtypenverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_materialtype_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Materialtyp hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Materialtyp bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Materialtyp löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="materialtype_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying materialtype data  -->
      <v-data-table
        :headers="materialtype_data_header"
        :items="allMaterialTypes"
        :search="materialtype_search"
        :expanded.sync="expanded_materialtype"
        :key="materialtype_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template materialtype name-->
        <template v-slot:item.name="{ item }">
          <v-icon class="mr-2">{{ item.icon }}</v-icon>
          {{ item.name }}
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMillisToHoursString(item.operating_time) }} h
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              small
              class="mr-2"
              @click="openEditMaterialTypeDialog(item)"
              >{{ navigationIcons.edit }}</v-icon
            >
            <v-icon
              small
              class="mr-2"
              @click="confirmDeleteMaterialType(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for MaterialType Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Materialtyp ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll der gewählte Materialtyp gelöscht werden?'"
        :object_name="edited_materialtype.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteMaterialTypeConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewMaterialTypeDialog
        :display="display_new_materialtype_dialog"
        @closeNewMaterialTypeDialog="display_new_materialtype_dialog = false"
      />

      <EditMaterialTypeDialog
        :display="display_edit_materialtype_dialog"
        :edited_materialtype="edited_materialtype"
        :edited_materialtype_index="edited_materialtype_index"
        @closeEditMaterialTypeDialog="closeEditMaterialTypeDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewMaterialTypeDialog from "../components/ProductionMaterialTypes/NewMaterialTypeDialog.vue";
import EditMaterialTypeDialog from "../components/ProductionMaterialTypes/EditMaterialTypeDialog.vue";

// Import of Types
import MaterialType from "../../../types/materialType";

export default {
  name: "AdminMaterialType",
  mixins: [helperMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewMaterialTypeDialog,
    EditMaterialTypeDialog,
  },

  computed: {
    ...mapGetters(["allMaterialTypes", "navigationIcons"]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_materialtype_dialog: false, // Determines wether the new materialtype dialog is beeing displayed
    display_edit_materialtype_dialog: false, // Determines wether the edit materialtype dialog is beeing displayed
    selected_materialtype_id: String, // Holds the id of the selected materialtype. Is beeing determined by the selection dialog
    materialtype_search: "", // Holds search data for the search bar of the materialtype data table
    expanded_materialtype: [], // synchronices the expanded items of the materialtype data table
    edited_materialtype_index: -1, // Holds the index of the materialtype that is beeing edited. determined by the openEditMaterialTypeDialog() funciton
    materialtype_data_table_key: 0, //Holds the key of the materialtype_data_table. Is needed to force vue to rerender the materialtype data table as vue does not notice a change in the vuex materialtype store after a materialtype is deleted

    // Holds the currently being edited materialtype during edits or deletion
    edited_materialtype: {
      name: "",
    },

    // Holds the Header data of the materialtype data table
    materialtype_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID", value: "_id" },
      { text: "Lagerbestand", value: "in_stock" },
      { text: "Einheit:", value: "standard_unit" },
      { text: "Maße:", value: "measures" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions(["deleteMaterialType"]),

    /**
     * Title: deleteMaterialType()
     * Description: opens the confirmation dialog when a materialtype is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} materialtype the machien that is supposed to be deleted
     *
     */
    confirmDeleteMaterialType(materialtype) {
      this.display_delete_confirmation_dialog = true;
      this.edited_materialtype_index =
        this.allMaterialTypes.indexOf(materialtype);
      Object.assign(this.edited_materialtype, materialtype);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_materialtype and edited_materialtype_index variable after the deletion of a materialtype has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_materialtype, this.standard_materialtype);
        this.edited_materialtype_index = -1;
      });
    },

    /**
     * Title: confirmDelete()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteMaterialTypeConfirmed() {
      this.deleteMaterialType(this.edited_materialtype);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a materialtype is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    confirmSelection(mode, selection_id) {
      if (mode === "delete") {
        const materialtype = this.allMaterialTypes.find(
          (materialtypeall) => materialtypeall._id === selection_id
        );
        Object.assign(this.edited_materialtype, materialtype);
        this.confirmDeleteMaterialType(materialtype);
      } else if (mode === "edit") {
        const materialtype = this.allMaterialTypes.find(
          (materialtypeall) => materialtypeall._id === selection_id
        );
        Object.assign(this.edited_materialtype, materialtype);
        this.display_edit_materialtype_dialog = true;
      }
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditMaterialTypeDialog()
     * Description: opens the Edit MaterialType Dialog and sets the edited_materialtype and edited_materialtype_index according to the materialtype selected by the user
     *
     * @param {MaterialType} materialtype the materialtype that is beeing edited
     *
     */
    openEditMaterialTypeDialog(materialtype) {
      this.edited_materialtype_index =
        this.allMaterialTypes.indexOf(materialtype);
      Object.assign(this.edited_materialtype, materialtype);
      this.display_edit_materialtype_dialog = true;
    },

    /**
     * Title: closeEditMaterialTypeDialog()
     * Description: closes the Edit MaterialType Dialog and resets the edited_materialtype and edited_materialtype_index
     *
     */
    closeEditMaterialTypeDialog() {
      this.display_edit_materialtype_dialog = false;
      this.edited_materialtype_index = -1;
      Object.assign(this.edited_materialtype, this.standard_materialtype);
      this.materialtype_data_table_key = this.materialtype_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a materialtype is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_materialtype, this.standard_materialtype);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Materialtyp";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Materialtyp zum Bearbeiten auswählen:";
        this.selection_data = this.allMaterialTypes;
      } else if (selection_mode === "delete") {
        this.selection_title = "Materialtyp zum Löschen auswählen:";
        this.selection_data = this.allMaterialTypes;
      }
      this.display_select_dialog = true;
    },
  },
};
</script>
