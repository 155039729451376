<!-- 
    Title: EditSimSensorDialoig
  
    Description: Dialog for editing Sensor Data of a SImulation
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Sensor bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor.name"
                  label="Name"
                  :rules="[() => !!edited_sim_sensor.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Type Selction -->
            <v-row>
              <v-col>
                <v-select
                  v-model="edited_sim_sensor.type"
                  :items="allSensorTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!edited_sim_sensor.type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_sim_sensor.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_sim_sensor.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor.location"
                  label="Standort"
                  :rules="[() => !!edited_sim_sensor.location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Operating Range Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor.operating_range_max"
                  label="Absoluter max. Wert"
                  :rules="[
                    () =>
                      !!edited_sim_sensor.operating_range_max || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(edited_sim_sensor.operating_range_max)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor.operating_range_min"
                  label="Absoluter min. Wert"
                  :rules="[
                    () =>
                      !!edited_sim_sensor.operating_range_min || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(edited_sim_sensor.operating_range_min)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor.optimal_operating_range_max"
                  label="Optimaler max. Wert"
                  :rules="[
                    () =>
                      !!edited_sim_sensor.optimal_operating_range_max ||
                      'Pflichtfeld',
                    () =>
                      !isNaN(
                        Number(edited_sim_sensor.optimal_operating_range_max)
                      ) || 'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="edited_sim_sensor.optimal_operating_range_min"
                  label="Optimaler min. Wert"
                  :rules="[
                    () =>
                      !!edited_sim_sensor.optimal_operating_range_min ||
                      'Pflichtfeld',
                    () =>
                      !isNaN(
                        Number(edited_sim_sensor.optimal_operating_range_min)
                      ) || 'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditSimSensorDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EditSimSensorDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_sim_sensor: {}, // the sim_sensor that is beeing edited
    edited_sim_sensor_index: Number, // the index of the edited sim_sensor within the vuex State
  },

  data: () => ({
    form_validation: false, // Validation Data of the Input Form
  }),

  computed: {
    ...mapGetters(["allSimSensors", "allSensorTypes"]),
  },

  methods: {
    ...mapActions(["editSimSensor"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited sim_sensor by calling the vuex Method
     *
     */
    async saveEdit() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        await this.editSimSensor({
          sim_sensor: this.edited_sim_sensor,
          sim_sensor_index: this.edited_sim_sensor_index,
        });
        this.$emit("closeEditSimSensorDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
