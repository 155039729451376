<!-- 
    Title: SimualtionFactoryCard
  
    Description: Page of the NewSimualtionDialog for editing factory configuration
-->
<template>
  <v-card tile elevation="5">
    <NewSimFactoryDialog
      :display="display_new_sim_factory_dialog"
      @closeNewSimFactoryDialog="display_new_sim_factory_dialog = false"
    />

    <EditSimFactoryDialog
      :display="display_edit_sim_factory_dialog"
      :edited_sim_factory="edited_sim_factory"
      :edited_sim_factory_index="edited_sim_factory_index"
      @closeEditSimFactoryDialog="closeOpenDialog"
    />

    <DeleteConfirmationDialog
      :title="'Soll die simulierte Fabrik wirklich gelöscht werden?'"
      :object_name="edited_sim_factory.name"
      :display="display_delete_confirmation_dialog"
      @cancelDelete="closeOpenDialog"
      @confirmDelete="deletionConfirmed"
    />

    <SelectionDialog
      :selection_data="selection_data"
      :mode="selection_mode"
      :display="display_select_dialog"
      :title="selection_title"
      :label="selection_label"
      @cancelSelection="closeOpenDialog"
      @confirmSelection="confirmSelection"
    />

    <!-- Title -->
    <v-card-title> Schritt 2 - Hierarchieebene Fabrik</v-card-title>

    <!-- Search -->
    <v-card-title>
      <v-text-field
        v-model="factory_search"
        :append-icon="navigationIcons.search"
        label="Suche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <!-- Factory Table -->
    <v-data-table
      :headers="table_data_header"
      :items="allSimFactorys"
      :search="factory_search"
      :expanded.sync="expanded_factory"
      :key="factory_data_table_key"
      item-key="_id"
      show-expand
    >
      <!-- Template for function btns in each row -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon small class="mr-2" @click="openEditFactoryDialog(item)">{{
            navigationIcons.edit
          }}</v-icon>
          <v-icon small class="mr-2" @click="confirmDeleteFactory(item)">{{
            navigationIcons.delete
          }}</v-icon>
        </div>
      </template>

      <!-- Template for assigned -->
      <template v-slot:item.assigned="{ item }">
        <v-checkbox
          class="mt-2 mb-n2"
          dense
          disabled
          v-model="item.assigned"
        ></v-checkbox>
      </template>

      <!-- Template for Factory Details -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <!-- Factory ID -->
            <h4 class="mt-3 mb-3">Fabrik ID:</h4>
            <v-text-field v-model="item._id" readonly outlined></v-text-field>

            <!-- Description -->
            <h4 class="mt-3 mb-3">Beschreibung:</h4>
            <v-textarea
              v-model="item.description"
              readonly
              outlined
            ></v-textarea>

            <!-- MachineGroups -->
            <h4 class="mb-3">Maschinengruppen:</h4>

            <!-- Button to add a machinegroup -->
            <v-btn
              color="green"
              fab
              small
              absolute
              right
              class="mr-3 mt-n7"
              @click="
                Object.assign(edited_sim_factory, item);
                setupSelection('add_machinegroup_to_factory');
              "
              ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
            >

            <!-- MachineGroup Table -->
            <v-simple-table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="machinegroup in item.components.machine_groups"
                  :key="machinegroup"
                >
                  <!-- ID -->
                  <td>{{ machinegroup }}</td>

                  <!-- Name -->
                  <td>
                    {{
                      allSimMachineGroups.find(
                        (machinegroupall) =>
                          machinegroupall._id === machinegroup
                      ).name
                    }}
                  </td>

                  <!-- Delete Machinegroup from factory button -->
                  <td>
                    <v-btn
                      icon
                      x-small
                      absolute
                      right
                      class="mr-5 mt-n2 align-center"
                      @click="deleteMachineGroupFromFactory(item, machinegroup)"
                      ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <!-- Sensoren -->
            <h4 class="mb-3 mt-12">Sensoren:</h4>

            <!-- Button to add a sensor to a factory -->
            <v-btn
              color="green"
              fab
              small
              absolute
              right
              class="mr-3 mt-n7"
              @click="
                Object.assign(edited_sim_factory, item);
                setupSelection('add_sensor_to_factory');
              "
              ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
            >
            <v-simple-table class="mb-3">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sensor in item.components.sensors" :key="sensor">

                  <!-- Sensor ID -->
                  <td>{{ sensor }}</td>

                  <!-- Sensor Name -->
                  <td>
                    {{
                      allSimSensors.find(
                        (sensorall) => sensorall._id === sensor
                      ).name
                    }}
                  </td>

                  <!-- Button to delete sensor from factory -->
                  <td>
                    <v-btn
                      icon
                      x-small
                      absolute
                      right
                      class="mr-5 mt-n2 align-center"
                      @click="deleteSensorFromFactory(item, sensor)"
                      ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <v-card-actions>

      <!-- Button to add a new Factory -->
      <v-btn plain tile @click="display_new_sim_factory_dialog = true"
        ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
        >Fabrik hinzufügen</v-btn
      >
      <v-spacer></v-spacer>

      <!-- Next Button -->
      <v-btn text color="primary" @click="$emit('nextstep')">Weiter</v-btn>
    </v-card-actions>
    <v-progress-linear value="30" height="10"></v-progress-linear>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewSimFactoryDialog from "../../components/simulationFactory/NewSimFactoryDialog.vue";
import EditSimFactoryDialog from "../../components/simulationFactory/EditSimFactoryDialog.vue";
import DeleteConfirmationDialog from "../../../../components/general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../../../components/general/SelectionDialog.vue";
export default {
  name: "SimulationFactoryCard",

  components: {
    NewSimFactoryDialog,
    EditSimFactoryDialog,
    DeleteConfirmationDialog,
    SelectionDialog,
  },

  computed: {
    ...mapGetters([
      "navigationIcons",
      "allSimFactorys",
      "allSimMachineGroups",
      "allSimSensors",
      "specificSimSensor",
    ]),
  },

  data: () => ({
    display_new_sim_factory_dialog: false,
    display_edit_sim_factory_dialog: false,
    display_delete_confirmation_dialog: false,
    display_select_dialog: false,

    selection_title: "",
    selection_label: "",
    selection_data: [],
    selection_mode: "",

    table_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID:", value: "_id" },
      { text: "Standort:", value: "location" },
      { text: "Zugeordnet:", value: "assigned" },
      { text: "", value: "actions", sortable: false },
    ],

    factory_search: "",
    factory_data_table_key: 0,
    expanded_factory: [],
    edited_sim_factory_index: -1,
    edited_sim_factory: {
      _id: "",
      components: {
        machine_groups: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },
    standard_factory: {
      _id: "",
      components: {
        machine_groups: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },
  }),

  methods: {
    ...mapActions([
      "deleteSimFactory",
      "editSimFactory",
      "removeSimSensorFromSimFactory",
      "removeSimMachineGroupFromSimFactory",
      "addSimSensorToSimFactory",
      "addSimMachineGroupToSimFactory",
    ]),
    /**
     * Title: closeOpenDialog()
     * Description: closes every dialog
     */
    closeOpenDialog() {
      this.display_new_sim_factory_dialog = false;
      this.display_edit_sim_factory_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.display_select_dialog = false;

      Object.assign(this.edited_sim_factory, this.standard_factory);
      this.edited_sim_factory_index = -1;
      this.factory_data_table_key = this.factory_data_table_key + 1;
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      this.selection_mode = "";
    },

    /**
     * Title: openEditFactoryDialog()
     * Description: opens the Edit Factory Dialog and sets the edited_sim_factory and edited_sim_factory_index according to the factory selected by the user
     *
     * @param {Factory} factory the factory that is beeing edited
     *
     */
    openEditFactoryDialog(factory) {
      this.edited_sim_factory_index = this.allSimFactorys.indexOf(factory);
      Object.assign(this.edited_sim_factory, factory);
      this.display_edit_sim_factory_dialog = true;
    },

    /**
     * Title: deleteFactory()
     * Description: opens the confirmation dialog when a factory is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} factory the machien that is supposed to be deleted
     *
     */
    confirmDeleteFactory(factory) {
      this.display_delete_confirmation_dialog = true;
      this.edited_sim_factory_index = this.allSimFactorys.indexOf(factory);
      Object.assign(this.edited_sim_factory, factory);
    },

    deletionConfirmed() {
      this.deleteSimFactory(this.edited_sim_factory);
      this.closeOpenDialog();
    },

    async deleteSensorFromFactory(factory, sim_sensor_id) {
      await this.removeSimSensorFromSimFactory({
        sim_factory_id: factory._id,
        sim_sensor_id,
      });
      this.factory_data_table_key++;
      this.closeOpenDialog;
    },

    async deleteMachineGroupFromFactory(factory, sim_machinegroup_id) {
      await this.removeSimMachineGroupFromSimFactory({
        sim_factory_id: factory._id,
        sim_machinegroup_id,
      });
      this.factory_data_table_key++;
      this.closeOpenDialog;
    },

    setupSelection(selection_mode) {
      this.selection_label = "Fabrik";
      this.selection_mode = selection_mode;
      if (selection_mode === "add_machinegroup_to_factory") {
        this.selection_title = "Maschinengruppe auswählen:";
        this.selection_label = "Maschinengruppe";
        this.selection_data = this.allSimMachineGroups.filter(
          (machinegroup) => machinegroup.assigned === false
        );
      } else if (selection_mode === "add_sensor_to_factory") {
        this.selection_title = "Sensor auswählen:";
        this.selection_label = "Sensor";
        this.selection_data = this.allSimSensors.filter(
          (sensor) => sensor.assigned === false
        );
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a factory is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    async confirmSelection(mode, selection_id) {
      // If a new sensor has been added to the factory
      if (mode === "add_sensor_to_factory") {
        await this.addSimSensorToSimFactory({
          sim_factory_id: this.edited_sim_factory._id,
          sim_sensor_id: selection_id,
        });
      }
      // If a new machine has been added to the machinegroup
      else if (mode === "add_machinegroup_to_factory") {
        await this.addSimMachineGroupToSimFactory({
          sim_factory_id: this.edited_sim_factory._id,
          sim_machinegroup_id: selection_id,
        });
      }
      this.factory_data_table_key++;
      this.closeOpenDialog();
    },
  },
};
</script>
