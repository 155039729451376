<template>

  <!-- 
    Title: EditMachineDialog
  
    Description: Dialog for editing Data of a specific machine
  
   -->

  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>

      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Maschine bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>

              <!-- Machine ID -->
              <v-col>
                <v-text-field
                  v-model="edited_machine._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>
              
              <!-- Machine Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_machine.name"
                  label="Name"
                  :rules="[() => !!edited_machine.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>

              <!-- Machine Description Input -->
              <v-col>
                <v-textarea
                  v-model="edited_machine.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!edited_machine.description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>

              <!-- Machine Location Input -->
              <v-col>
                <v-text-field
                  v-model="edited_machine.location"
                  label="Standort"
                  :rules="[() => !!edited_machine.location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Machine Type Selector -->
              <v-col>
                <v-select
                  v-model="edited_machine.type"
                  :items="allMachineTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!edited_machine.type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Dialog Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditMachineDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Machine from "../../../../types/machine";
export default {
  name: "EditMachineDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_machine: Machine, // the edited Machine
    edited_machine_index: Number, // the index of the edited Machine within the vuex State
  },

  computed: {
    ...mapGetters(["allMachines", "allMachineTypes"]),
  },

  data: () => ({
    form_validation: false, // True = Form has been validated; False = Form has not been validated
  }),

  methods: {
    ...mapActions(["editMachine"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited machine by calling the vuex Method
     *
     */
    saveEdit() {

      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {

        // Call Vuex Method
        this.editMachine({
          machine: this.edited_machine,
          machine_index: this.edited_machine_index,
        });

        // Emit Close Dialog to Parent Component
        this.$emit("closeEditMachineDialog");

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
