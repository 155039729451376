<!-- 
    Title: NewMaintenanceTypeDialog
  
    Description: Dialog for adding a new maintenancetype
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>

      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neue Wartungstyp hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">

            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Icon Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="icon"
                  label="Icon"
                  :rules="[() => !!icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewMaintenanceTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveMaintenanceType"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewMaintenanceTypeDialog", //Component name

  props: {
    display: Boolean, // Determines weather Dialog is being displayed
  },

  data: () => ({
    // Data of the new MaintenanceType
    name: "",
    description: "",
    icon: "",
    form_validation: false,
  }),

  methods: {
    ...mapActions(["newMaintenanceType"]),

    /**
     * Title: saveMaintenanceType()
     * Description: saves the new MaintenanceType by calling the vuex Method
     *
     */
    saveMaintenanceType() {

      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      //Check Validation
      if (this.form_validation === true) {

        //Call Vuex Method to save
        this.newMaintenanceType({
          name: this.name,
          description: this.description,
          icon: this.icon,
        });

        // Emit close event to parent component
        this.$emit("closeNewMaintenanceTypeDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
