<!-- 
    Title: SimulationStartCard
  
    Description: Card to confirm the start of the simulation
-->
<template>
  <v-card tile elevation="5">
    <!-- Laoding Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Title -->
    <v-card-title> Schritt 6 - Simulation starten</v-card-title>
    <v-card-text> Soll die Simulation gestartet werden? </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <!-- Back Button -->
      <v-btn text color="darkgrey" @click="$emit('previousstep')">Zurück</v-btn>

      <!-- Start Button -->
      <v-btn text color="primary" @click="next">Starten</v-btn>
    </v-card-actions>
    <v-progress-linear value="100" height="10"></v-progress-linear>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SimulationStartCard",

  computed: {
    ...mapGetters(["allAssemblyTypes"]),
  },

  data: () => ({
    overlay: false, // determines weather loading Overlay is displayed
  }),

  methods: {
    ...mapActions(["startSimulation"]),

    // Skips to next page
    async next() {
      this.startSimulation();
      this.$emit("close");
    },
  },
};
</script>
