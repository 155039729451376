<!-- 
    Title: EditMachineGroupDialog
  
    Description: Dialog for editing Data of a specific machinegroup
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Maschinengruppe bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- Machinegroup ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_machinegroup._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Machinegroup Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_machinegroup.name"
                  label="Name"
                  :rules="[() => !!edited_machinegroup.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Machinegroup Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_machinegroup.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_machinegroup.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Machinegroup Location Input -->
            <v-row
              >-
              <v-col>
                <v-text-field
                  v-model="edited_machinegroup.location"
                  label="Standort"
                  :rules="[
                    () => !!edited_machinegroup.location || 'Pflichtfeld',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Dialog Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditMachineGroupDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MachineGroup from "../../../../types/machineGroup";
export default {
  name: "EditMachineGroupDialog", // Component Name

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_machinegroup: MachineGroup, // the edited MachineGroup
    edited_machinegroup_index: Number, // the index of the edited MachineGroup within the vuex State
  },

  computed: {
    ...mapGetters(["allMachineGroups", "allMachineGroupTypes"]),
  },

  data: () => ({
    form_validation: false, // Contains Validation Data of the Input Form
  }),

  methods: {
    ...mapActions(["editMachineGroup"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited machinegroup by calling the vuex Method
     *
     */
    saveEdit() {
      // Validate Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method
        this.editMachineGroup({
          machinegroup: this.edited_machinegroup,
          machinegroup_index: this.edited_machinegroup_index,
        });

        // Emit Close Dialog to Parent Component
        this.$emit("closeEditMachineGroupDialog");

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
