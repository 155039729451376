export default {

  methods: {

    /* Title: getmaintenanceStatusText
     * 
     * Description: Returns a String representing the Status of an maintenance
     *
     */
    getMaintenanceStatusText(status: number): string {
      switch (status) {
        case 1:
          return "Offen";
        case 2:
          return "Fertig";
        default:
          return "-"
      }
    },

    /* Title: getmaintenanceStatusText
     * 
     * Description: Returns a String representing the Status of an maintenance
     *
     */
    getMaintenanceStatusColor(status: number): string {
      switch (status) {
        case 1:
          return "orange";
        case 2:
          return "green";
        default:
          return "black";
      }
    },
  },
};
