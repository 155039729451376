import { Machine, MachineStatus } from "../types/machine";
import store from "../vuex-store/index";

export default {
  methods: {
    /* Title: getmachineStatusColor
     *
     * Description: Returns a Color-String representing the Status of an machine
     *
     */
    getMachineStatusText(machine_status: MachineStatus) {
      switch (machine_status) {
        case 0:
          return "Aus";
        case 1:
          return "Frei";
        case 2:
          return "Beschäftigt";
        case 3:
          return "Geht aus";
        case 4:
          return "In Wartung";
        default:
          break;
      }
    },

    /* Title: getmachineProgress
     *
     * Description: Returns a the progress percentage of an machine based on the number of finished subassemblies
     *
     */
    getMachineStatusColor(machine_status: MachineStatus) {
      switch (machine_status) {
        case 0:
          return "grey";
        case 1:
          return "green";
        case 2:
          return "green";
        case 3:
          return "green-lighten 2";
        case 4:
          return "orange";
        default:
          return "black";
      }
    },

    /* Title: getMachineName
     * Description: Return the name of a machien by its id
     *
     */
    getMachineName(machine_id: string) {
      if (machine_id === "") {
        return "";
      } else {
        return store.getters.specificMachine(machine_id).name;
      }
    },

    /* Title: currentlyWorkingOnToNameString
     *
     * Description: gets the name of the assembly a machine is currently working on
     *
     */
    currentlyWorkingOnToNameString(machine: Machine | "null"): string {
      if (
        machine.currently_working_on == null ||
        machine.currently_working_on === "null"
      ) {
        return "-";
      } else {
        return machine.currently_working_on;
      }
    },
  },
};
