<!-- 
    Title: EditSensorTypeDialog
  
    Description: Dialog for editing data of a specific Sensortype
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Sensortyp bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- ID Field -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sensortype._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensortype.name"
                  label="Name"
                  :rules="[() => !!edited_sensortype.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Unit Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensortype.unit"
                  label="Einheit"
                  :rules="[() => !!edited_sensortype.unit || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Icon Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensortype.icon"
                  label="Icon"
                  :rules="[() => !!edited_sensortype.icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_sensortype.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_sensortype.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditSensorTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SensorType from "../../../../types/sensorType";
export default {
  name: "EditSensorTypeDialog", // Component Name

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_sensortype: SensorType, // the sensortype that is beeing edited
    edited_sensortype_index: Number, // the index of the edited sensortype within the vuex State
  },

  computed: {
    ...mapGetters(["allSensorTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of Input Fomr
  }),

  methods: {
    ...mapActions(["editSensorType"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited sensortype by calling the vuex Method
     *
     */
    async saveEdit() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to save
        await this.editSensorType({
          sensor_type: this.edited_sensortype,
          sensor_type_index: this.edited_sensortype_index,
        });

        // Emit Close Dialog Event to Parent Component
        this.$emit("closeEditSensorTypeDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
