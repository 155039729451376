<!-- 
    Title: Maintenance History Card
  
    Description: tabvle for displaying the maintenance history of an enity
-->
<template>
  <v-card tile elevation="5" height="100%">
    <v-card-title>
      Wartungshistorie
      <v-spacer></v-spacer>

      <!-- Button to add a new maintenance -->
      <v-btn plain v-if="checkRights('maintenance') === true" @click="display_new_maintenance_dialog = true">
        <v-icon class="mr-4">{{ navigationIcons.add }}</v-icon>
        Wartung hinzufügen
      </v-btn>
    </v-card-title>

    <!-- Text if no Maintenance History is available -->
    <v-card-text v-if="!maintenance_history">
      Keine historische Wartungen vorhanden!
    </v-card-text>

    <!-- Maintenance Table -->
    <v-data-table
      v-if="maintenance_history"
      :headers="maintenance_data_header"
      :items="items"
      :expanded.sync="expanded_maintenances"
      item-key="_id"
      show-expand
    >
      <!-- Template sensor name-->
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>

      <!-- Template for status -->
      <template v-slot:item.status="{ item }">
        <v-chip :color="getMaintenanceStatusColor(item.status)" dark>
          {{ getMaintenanceStatusText(item.status) }}
        </v-chip>
      </template>

      <!-- Template for Type -->
      <template v-slot:item.type="{ item }">
        <v-icon>{{ specificMaintenanceType(item.type).icon }}</v-icon>
        <!-- {{ specificMaintenanceType(item.type).name }} -->
      </template>

      <!-- Template for ID -->
      <template v-slot:item._id="{ item }">
        {{ item._id }}
      </template>

      <!-- Template for colored status chips -->
      <template v-slot:item.creation_date="{ item }">
        {{ new Date(item.creation_date).toLocaleString() }}
      </template>

      <!-- Template for Operating Time -->
      <template v-slot:item.operating_time="{ item }">
        {{ convertMinutesToHoursMinutes(item.operating_time) }}
      </template>

      <!-- Template for created_by -->
      <template v-slot:item.created_by="{ item }">
        {{ specificUser(item.created_by).login }}
      </template>

      <!-- Template for start time -->
      <template v-slot:item.time_started="{ item }">
        {{ new Date(item.time_started).toLocaleString() }}
      </template>

      <!-- template for finish time -->
      <template v-slot:item.time_finished="{ item }">
        {{ getTimeFinishedString(item) }}
      </template>

      <!-- Template for function btns in each row -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            v-if="item.status != 2"
            small
            class="mr-2"
            @click="finishMaintenance(item._id)"
            >{{ navigationIcons.check }}</v-icon
          >
        </div>
      </template>

      <!-- Template for Sensor Details -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <h4 class="mt-3 mb-3">Wartungs ID:</h4>
            <v-text-field
              v-model="item._id"
              readonly
              outlined
              dense
            ></v-text-field>
            <h4 class="mt-3 mb-3">Beschreibung:</h4>
            <v-textarea
              v-model="item.description"
              readonly
              outlined
            ></v-textarea>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <NewMaintenanceDialog
      :display="display_new_maintenance_dialog"
      :hiearchical_level="component_type"
      :id="component_id"
      @closeNewMaintenanceDialog="display_new_maintenance_dialog = false"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewMaintenanceDialog from "../../general/NewMaintenanceDialog.vue";
import maintenanceMixins from "../../../mixins/maintenanceMixins";
import helperMixins from "../../../mixins/helperMixins";
export default {
  name: "MaintenanceHistoryCard",
  components: { NewMaintenanceDialog },

  computed: {
    ...mapGetters([
      "specificMaintenanceType",
      "specificUser",
      "specificMaintenance",
      "navigationIcons",
      "checkRights"
    ]),

    /**
     * Title: items()
     * Description: gets the data of the individual maintenances from the state by the maintenance id in the entity
     *
     */
    items: function () {
      let data = [];
      this.maintenance_history.forEach((maintenance_id) => {
        data.push(this.specificMaintenance(maintenance_id));
      });
      return data;
    },
  },

  mixins: [maintenanceMixins, helperMixins],

  props: {
    maintenance_history: [], // the displayed history
    component_id: String, // the id of the component of which the maintenance_history is beeing displayed, used for adding a new maintenenace
    component_type: String, // the type of the component of which the maintenance_history is beeing displayed, used for adding a new maintenenace
  },

  data: () => ({
    ...mapActions(["finishMaintenance"]),
    expanded_maintenances: [], // synchronices the expanded items of the maintenance data table
    display_new_maintenance_dialog: false, // determines if the new maintenanace dialog is beeing displayed

    // Table header for maintenance table
    maintenance_data_header: [
      { text: "Name:", value: "name" },
      { text: "Status:", value: "status" },
      { text: "Typ", value: "type" },
      { text: "Angelegt am:", value: "creation_date" },
      { text: "Bei:", value: "operating_time" },
      { text: "Von:", value: "created_by" },
      { text: "Gestartet:", value: "time_started" },
      { text: "Beendet:", value: "time_finished" },
      { text: "", value: "actions" },
    ],
  }),

  methods: {

    /**
     * Title: getTimeFinishedString()
     * Description: converts the finished time into a string, returns "-" if no time has been set (the case when the maintenance is not done)
     *
     */
    getTimeFinishedString(maintenance) {
      if (maintenance.time_finished == null) return "-";
      else return new Date(maintenance.time_finished).toLocaleString();
    },
  },
};
</script>
