<!-- 
    Title: SensorDetailPanel
  
    Description: Displays detailed information about all sensors within an dt component
-->

<template>
  <v-card tile elevation="5">
    <v-card-title>Sensoren</v-card-title>
    <!-- table for displaying sensor data  -->
    <v-data-table
      :headers="sensor_data_header"
      :items="sensors"
      :expanded.sync="expanded_sensor"
      item-key="_id"
      show-expand
    >
      <!-- Template sensor name-->
      <template v-slot:item.name="{ item }">
        <v-icon>{{ specificSensorType(item.type).icon }}</v-icon>
        {{ item.name }}
      </template>

      <!-- Template for current_reading -->
      <template v-slot:item.current_reading="{ item }">
        <v-chip :color="getSensorValueColor(item)" dark>
          {{ getSensorValueString(item) }}
        </v-chip>
      </template>

      <!-- Template for colored status chips -->
      <template v-slot:item.current_status="{ item }">
        <v-chip :color="getSensorStatusColor(item.current_status)" dark>
          {{ getSensorStatusText(item.current_status) }}
        </v-chip>
      </template>

      <!-- Templates for Operatign Range -->
      <template v-slot:item.operating_range_max="{ item }">
        {{
          item.operating_range_max + " " + specificSensorType(item.type).unit
        }}
      </template>

      <template v-slot:item.operating_range_min="{ item }">
        {{
          item.operating_range_min + " " + specificSensorType(item.type).unit
        }}
      </template>

      <template v-slot:item.optimal_operating_range_max="{ item }">
        {{
          item.optimal_operating_range_max +
          " " +
          specificSensorType(item.type).unit
        }}
      </template>

      <template v-slot:item.optimal_operating_range_min="{ item }">
        {{
          item.optimal_operating_range_min +
          " " +
          specificSensorType(item.type).unit
        }}
      </template>

      <!-- Template for converting Operating Time -->
      <template v-slot:item.operating_time="{ item }">
        {{ convertMinutesToHoursMinutes(item.operating_time) }}
      </template>

      <!-- Template for converting Implementation Date -->
      <template v-slot:item.implementation_date="{ item }">
        {{ convertDateToString(item.implementation_date) }}
      </template>

      <!-- Template for function btns in each row -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon small class="mr-2" @click="openSensorDetails(item._id)">{{
            navigationIcons.information
          }}</v-icon>
        </div>
      </template>

      <!-- Template for Sensor Details -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <!-- Sensor ID -->
            <h4 class="mt-3 mb-3">Sensor ID:</h4>
            <v-text-field
              v-model="item._id"
              readonly
              outlined
              dense
            ></v-text-field>

            <!-- Sensor Description -->
            <h4 class="mt-3 mb-3">Beschreibung:</h4>
            <v-textarea
              v-model="item.description"
              readonly
              outlined
            ></v-textarea>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import sensorMixins from "../../../mixins/sensorMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "FactorySensorDetailPanel",
  mixins: [sensorMixins, helperMixins],
  props: {
    sensor_ids: [], // Array of the sensor IDs that are displayed
  },

  data: () => ({
    expanded_sensor: [], // synchronices the expanded items of the sensor data table

    // Table Header Data
    sensor_data_header: [
      { text: "Name:", value: "name" },
      { text: "Aktueller Wert", value: "current_reading" },
      { text: "Status:", value: "current_status" },
      { text: "Max:", value: "operating_range_max" },
      { text: "Min:", value: "operating_range_min" },
      { text: "Opt. Max:", value: "optimal_operating_range_max" },
      { text: "Opt. Min:", value: "optimal_operating_range_min" },
      { text: "Standort:", value: "location" },
      { text: "Betriebszeit (h):", value: "operating_time" },
      { text: "Start Datum:", value: "implementation_date" },
      { text: "", value: "actions" },
    ],
  }),

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "allSensors",
      "specificSensor",
      "specificMachine",
      "navigationIcons",
      "specificFactory",
      "specificSensorType",
    ]),

    /**
     * Title: sensors()
     * Description: returns the complete set of sensor data based on the sensor_ids array
     *
     *
     */
    sensors: function () {
      let sensors = [];
      this.sensor_ids.forEach((sensor_id) => {
        sensors.push(this.specificSensor(sensor_id));
      });
      return sensors;
    },
  },

  methods: {
    ...mapActions(["updateOverviewActiveElement"]),

    /**
     * Title: openSensorDetails()
     * Description: opens detail infomation about a sensor
     *
     * @param {String} sensor_id the id of sensor that is beeing opend
     *
     */
    openSensorDetails(sensor_id) {
      this.updateOverviewActiveElement({
        new_active_element_id: sensor_id,
        new_active_element_type: 4,
      });
    },
  },
};
</script>
