<!-- 
    Title: MachineInfoCard
  
    Description: Displays detailed information about a machine
-->

<template>
  <v-card tile height="100%" elevation="5">

    <!-- Title and Status and Icon -->
    <v-card-title>
      Kurzinformationen
      <v-spacer></v-spacer>
      <v-chip :color="getMachineStatusColor(machine.current_status)" dark>
        {{ getMachineStatusText(machine.current_status) }}
      </v-chip>
      <v-icon color="dark grey" large>{{
        specificMachineType(machine.type).icon
      }}</v-icon>
    </v-card-title>

    <!-- Data Table -->
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>

          <!-- ID -->
          <tr>
            <td>ID:</td>
            <td>
              {{ machine._id }}
            </td>
          </tr>

          <!-- Type -->
          <tr>
            <td>Typ:</td>
            <td>{{ specificMachineType(machine.type).name }}</td>
          </tr>

          <!-- Location -->
          <tr>
            <td>Standort:</td>
            <td>{{ machine.location }}</td>
          </tr>

          <!-- Operating Time -->
          <tr>
            <td>Betriebszeit:</td>
            <td>{{ convertMinutesToHoursMinutes(machine.operating_time) }}</td>
          </tr>

          <!-- Currently Working on -->
          <tr>
            <td>Arbeitet an:</td>
            <td>
              {{ currentlyWorkingOnToNameString(machine) }}
            </td>
          </tr>

          <!-- Number of Sensors -->
          <tr>
            <td>Sensoren:</td>
            <td>
              {{ machine.components.sensors.length }}
            </td>
          </tr>

          <!-- Implementation Date -->
          <tr>
            <td>Erstellt:</td>
            <td>
              {{ new Date(machine.implementation_date).toLocaleString() }}
            </td>
          </tr>

          <!-- Description -->
          <tr>
            <td>Beschreibung:</td>
            <td>{{ machine.description }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import machineMixins from "../../../mixins/machineMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "MachineInfoCard",
  mixins: [machineMixins, helperMixins],

  props: {
    machine: {},
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificMachine",
      "specificMachineType",
    ]),
  },
};
</script>
