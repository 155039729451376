<!-- 
    Title: NewProductionOrderDialog
  
    Description: Dialog for starting a new production order
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Neuen Produktionsauftrag hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Assemblytype select -->
            <v-row>
              <v-col>
                <v-select
                  :items="allAssemblyTypes"
                  item-text="name"
                  item-value="_id"
                  v-model="assemblytype"
                  :label="'Baugruppe'"
                  :rules="[() => !!assemblytype || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Amount INput -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="amount"
                  label="Anzahl"
                  :rules="[
                    () => !!amount || 'Pflichtfeld',
                    () => !isNaN(Number(amount)) || 'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewProductionOrderDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- SAve Button -->
        <v-btn
          color="green"
          text
          @click="saveProductionOrder"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewProductionOrderDialog",

  props: {
    display: Boolean, // Determines weather the dialog is displayed
  },

  data: () => ({
    // Data of the new ProductionOrder
    name: "",
    amount: "",
    assemblytype: "",
    form_validation: false,
  }),

  computed: {
    ...mapGetters(["allAssemblyTypes"]),
  },

  methods: {
    ...mapActions(["newProductionOrder"]),

    /**
     * Title: saveProductionOrder()
     * Description: saves the new ProductionOrder by calling the vuex Method
     *
     */
    saveProductionOrder() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.newProductionOrder({
          name: this.name,
          subassemblies: [
            {
              amount: parseInt(this.amount),
              subassembly_type: this.assemblytype,
            },
          ],
        });
        this.$emit("closeNewProductionOrderDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
