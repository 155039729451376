import { UserStatus } from "../types/user";

export default {
  methods: {
    /* Title: getUserStatusText
     *
     * Description: Returns a String representing the Status of an User
     *
     */
    getUserStatusText(user_status: UserStatus) {
      switch (user_status) {
        case 0:
          return "Gesperrt";
        case 1:
          return "Aktiv";
        default:
          break;
      }
    },

    /* Title: getUserStatusColor
     *
     * Description: Returns a Color-String representing the Status of an User
     *
     */
    getUserStatusColor(user_status: UserStatus) {
      switch (user_status) {
        case 0:
          return "red";
        case 1:
          return "grey";
        default:
          return "black";
      }
    },
  },
};
