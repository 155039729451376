var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Nutzerverwaltung")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_user_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Nutzer hinzufügen")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('edit')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_vm._v("Nutzer bearbeiten")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('delete')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.delete))]),_vm._v("Nutzer löschen")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('pw_reset')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.pw_reset))]),_vm._v("Passwort zurücksetzen")],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.user_search),callback:function ($$v) {_vm.user_search=$$v},expression:"user_search"}})],1),_c('v-data-table',{key:_vm.user_data_table_key,attrs:{"headers":_vm.user_data_header,"items":_vm.allUsers,"search":_vm.user_search,"expanded":_vm.expanded_user,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_user=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getUserStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getUserStatusText(item.status))+" ")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.creation_date))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.allUsers.find(function (creator) { return creator._id === item.created_by; }).login)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.allRoles.find(function (allroles) { return allroles._id === item.role; }).name)+" ")]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.last_login))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditUserDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteUser(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openPwResetDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.pw_reset))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("User ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}})],1)],1)]}}])})],1),[_c('DeleteConfirmationDialog',{attrs:{"title":'Soll der gewählte Nutzer gelöscht werden?',"object_name":_vm.edited_user.login,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDeleteEdit,"confirmDelete":_vm.deleteUserConfirmed}}),_c('UserSelectionDialog',{attrs:{"selection_data":_vm.selection_data,"mode":_vm.selection_mode,"display":_vm.display_select_dialog,"title":_vm.selection_title,"label":_vm.selection_label},on:{"cancelSelection":_vm.cancelSelection,"confirmSelection":_vm.confirmSelection}}),_c('NewUserDialog',{attrs:{"display":_vm.display_new_user_dialog},on:{"closeNewUserDialog":function($event){_vm.display_new_user_dialog = false}}}),_c('EditUserDialog',{attrs:{"display":_vm.display_edit_user_dialog,"edited_user":_vm.edited_user,"edited_user_index":_vm.edited_user_index},on:{"closeEditUserDialog":_vm.closeEditUserDialog}}),_c('EditPasswordDialog',{attrs:{"display":_vm.display_edit_pw_reset_dialog,"edited_user":_vm.edited_user,"edited_user_index":_vm.edited_user_index},on:{"closeEditPasswordDialog":_vm.closeEditPasswordDialog}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }