<!-- 
    Title: NewSimFactoryDialog
  
    Description: Dialog for adding a new simulated factory
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Neue Fabrik zur Simulation hinzufügen:</span>
      </v-card-title>

      <!-- INput Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name INput -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location INput -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewSimFactoryDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- save button  -->
        <v-btn
          color="green"
          text
          @click="saveSimFactory"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewSimFactoryDialog",

  props: {
    display: Boolean, // Determines weather the dialog is displayed
  },

  data: () => ({
    name: "", // Name of the new sim_factory
    description: "", // Description og the new SimFactory
    location: "", // Location of the new sim_factory
    form_validation: false, // Validation Data of Input Form
  }),

  computed: {
    ...mapGetters(["allSimFactoryTypes"]),
  },

  methods: {
    ...mapActions(["newSimFactory"]),

    /**
     * Title: saveSimFactory()
     * Description: saves the new SimFactory by calling the vuex Method
     *
     */
    saveSimFactory() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.newSimFactory({
          name: this.name,
          description: this.description,
          location: this.location,
        });
        this.$emit("closeNewSimFactoryDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
