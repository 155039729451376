var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Sensortypenverwaltung")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_sensortype_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Sensortyp hinzufügen")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('edit')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_vm._v("Sensortyp bearbeiten")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('delete')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.delete))]),_vm._v("Sensortyp löschen")],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.sensortype_search),callback:function ($$v) {_vm.sensortype_search=$$v},expression:"sensortype_search"}})],1),_c('v-data-table',{key:_vm.sensortype_data_table_key,attrs:{"headers":_vm.sensortype_data_header,"items":_vm.allSensorTypes,"search":_vm.sensortype_search,"expanded":_vm.expanded_sensortype,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_sensortype=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.operating_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertMillisToHoursString(item.operating_time))+" h ")]}},{key:"item.implementation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.implementation_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditSensorTypeDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteSensorType(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Sensortyp ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('h4',{staticClass:"mb-3"},[_vm._v("Wartungsplan:")]),_c('v-btn',{staticClass:"mr-3 mt-n7",attrs:{"color":"green","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){Object.assign(_vm.edited_sensortype, item);
                _vm.setupSelection('add_item_to_maintenanceplan');}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.navigationIcons.add))])],1),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Interval")]),_c('th')])]),_c('tbody',_vm._l((item.maintenance_plan),function(maintenance_item){return _c('tr',{key:maintenance_item.maintenance_type},[_c('td',[_vm._v(_vm._s(maintenance_item.maintenance_type))]),_c('td',[_vm._v(" "+_vm._s(_vm.specificMaintenanceType( maintenance_item.maintenance_type ).name)+" ")]),_c('td',[_vm._v(" "+_vm._s(maintenance_item.interval / 60 + " h")+" ")]),_c('td',[_c('v-btn',{staticClass:"mr-5 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.deleteItemFromMaintenancePlan(item, maintenance_item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.remove))])],1)],1)])}),0)])],1)],1)]}}])})],1),[_c('DeleteConfirmationDialog',{attrs:{"title":'Soll der gewählte Sensortyp gelöscht werden?',"object_name":_vm.edited_sensortype.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDeleteEdit,"confirmDelete":_vm.deleteSensorTypeConfirmed}}),_c('SelectionDialog',{attrs:{"selection_data":_vm.selection_data,"mode":_vm.selection_mode,"display":_vm.display_select_dialog,"title":_vm.selection_title,"label":_vm.selection_label,"display_interval":_vm.display_interval},on:{"cancelSelection":_vm.cancelSelection,"confirmSelection":_vm.confirmSelection}}),_c('NewSensorTypeDialog',{attrs:{"display":_vm.display_new_sensortype_dialog},on:{"closeNewSensorTypeDialog":function($event){_vm.display_new_sensortype_dialog = false}}}),_c('EditSensorTypeDialog',{attrs:{"display":_vm.display_edit_sensortype_dialog,"edited_sensortype":_vm.edited_sensortype,"edited_sensortype_index":_vm.edited_sensortype_index},on:{"closeEditSensorTypeDialog":_vm.closeEditSensorTypeDialog}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }