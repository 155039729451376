<!-- 
    Title: SensorGaugeCard
  
    Description: Displays the current reading of a sensor and its operating range
-->
<template>
  <div>
    <v-card height="100%" tile elevation="5">
      <v-card-title>

        <!-- Sensor Name -->
        {{ sensor.name }}
        <v-spacer></v-spacer>

        <!-- Sensor Status Chip -->
        <v-chip class="mr-2" :color="getSensorStatusColor(sensor.current_status)" dark>
          {{ getSensorStatusText(sensor.current_status) }}
        </v-chip>

        <!-- Sensor Icon -->
        <v-icon color="dark grey" large>{{
          specificSensorType(sensor.type).icon
        }}</v-icon>
      </v-card-title>

      <!-- Sensor ID -->
      <v-card-subtitle>ID: {{ sensor._id }}</v-card-subtitle>
      <v-card-text>
        <v-row class="d-flex align-center">

          <!-- Operating Range Table -->
          <v-col cols="5">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>Opt. Min.</td>
                  <td>
                    {{
                      sensor.optimal_operating_range_min +
                      " " +
                      specificSensorType(sensor.type).unit
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Opt. Max.</td>
                  <td>
                    {{
                      sensor.optimal_operating_range_max +
                      " " +
                      specificSensorType(sensor.type).unit
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Min.</td>
                  <td>
                    {{
                      sensor.operating_range_min +
                      " " +
                      specificSensorType(sensor.type).unit
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Max.</td>
                  <td>
                    {{
                      sensor.operating_range_max +
                      " " +
                      specificSensorType(sensor.type).unit
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <!-- Sensor Gauge -->
          <v-col cols="7" class="text-center">
            <apexchart
              class="mt-n10"
              type="radialBar"
              :options="chartOptions"
              :series="[percentageOfOperatingRange]"
              height="300px"
            ></apexchart>
            <div>
              <h3>{{ getSensorValueString(sensor) }}</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- button to open Sensor Details -->
      <v-card-actions>
        <v-btn plain @click="openSensorDetails(sensor_id)">Details</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
import sensorMixins from "../../../mixins/sensorMixins";

export default {
  name: "SensorGaugeCardSolo",
  mixins: [sensorMixins],
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    sensor_id: String, // The id of the sensor
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificSensor",
      "specificMachine",
      "allSensors",
      "specificSensorType",
    ]),

    /**
     * Title: sensor()
     * Description: returns the sensor that is beeing displayed based on the sensor_id prop
     *
     */
    sensor: function () {
      return this.specificSensor(this.sensor_id);
    },

    /**
     * Title: percentageOfOperatingRange()
     * Description: converts the current reading of the sensor into a percentage based on the total operating range of the sensor
     *
     */
    percentageOfOperatingRange: function () {
      if (
        this.sensor.current_status === 0 ||
        this.sensor.current_status === 4
      ) {
        return 0;
      } else {
        return (
          (this.sensor.current_reading - this.sensor.operating_range_min) /
          ((this.sensor.operating_range_max - this.sensor.operating_range_min) /
            100)
        ).toFixed(2);
      }
    },

    /**
     * Title: chartOptions()
     * Description: returns the chart options of the Gauge
     *
     */
    chartOptions: function () {
      return {
        chart: {
          type: "radialBar",
          height: 350,
        },
        colors: [this.getSensorValueColor(this.sensor)],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
      };
    },
  },

  methods: {
    ...mapActions(["updateOverviewActiveElement"]),

    /**
     * Title: openSensorDetails()
     * Description: opens the detail of the sensor
     * 
     * @param {ObjectID} sensor_id the id of the sensor
     *
     */
    openSensorDetails(sensor_id) {
      this.updateOverviewActiveElement({
        new_active_element_id: sensor_id,
        new_active_element_type: 4,
      });
    },
  },
};
</script>
