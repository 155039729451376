<!-- 
    Title: NewSimMachineDialog
  
    Description: Dialog for adding a new simulated machine
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Neue Maschine hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name INput -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Type Select -->
              <v-col>
                <v-select
                  v-model="type"
                  :items="allMachineTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewSimMachineDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveSimMachine"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewSimMachineDialog",

  props: {
    display: Boolean, // Determines weather the dialog is displayed
  },

  data: () => ({
    name: "", // Name of the new sim_machine
    description: "", // Description og the new SimMachine
    location: "", // Location of the new sim_machine
    type: "", // Type of the new sim_machine
    form_validation: false, // Validation Data of the input form
  }),

  computed: {
    ...mapGetters(["allMachineTypes"]),
  },

  methods: {
    ...mapActions(["newSimMachine"]),

    /**
     * Title: saveSimMachine()
     * Description: saves the new SimMachine by calling the vuex Method
     *
     */
    async saveSimMachine() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        await this.newSimMachine({
          name: this.name,
          description: this.description,
          location: this.location,
          type: this.type,
        });
        this.$emit("closeNewSimMachineDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
