<!-- 
    Title: EditSImFactoryDialog
  
    Description: Dialog for editing Data of simulated factorys
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Fabrik bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_factory._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sim_factory.name"
                  label="Name"
                  :rules="[() => !!edited_sim_factory.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_sim_factory.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_sim_factory.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sim_factory.location"
                  label="Standort"
                  :rules="[
                    () => !!edited_sim_factory.location || 'Pflichtfeld',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditSimFactoryDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Factory from "../../../../types/factory";
export default {
  name: "EditSimFactoryDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_sim_factory: Factory, // the edited SimFactory
    edited_sim_factory_index: Number, // the index of the edited SimFactory within the vuex State
  },

  computed: {
    ...mapGetters(["allSimFactorys", "allSimFactoryTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the Input Form
  }),

  methods: {
    ...mapActions(["editSimFactory"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited sim_factory by calling the vuex Method
     *
     */
    async saveEdit() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        await this.editSimFactory({
          sim_factory: this.edited_sim_factory,
          sim_factory_index: this.edited_sim_factory_index,
        });
        this.$emit("closeEditSimFactoryDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
