<!-- 
    Title: MaintenancePlanCard
  
    Description: Card for displaying the maintenance plan of a component
-->
<template>
  <v-card tile elevation="5" height="100%">
    <!-- Card Title -->
    <v-card-title>Wartungsplan</v-card-title>
    <v-container>
      <!-- Maintenanceplan Table -->
      <v-simple-table fixed-header max-height="300px">
        <thead>
          <tr>
            <th>Typ</th>
            <th>ID</th>
            <th>Intervall</th>
            <th>Beschreibung</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="maintenance in maintenance_plan"
            :key="maintenance.maintenance_type"
          >
            <!-- Type Icon and Name -->
            <td>
              <v-icon>{{
                specificMaintenanceType(maintenance.maintenance_type).icon
              }}</v-icon>
              {{ specificMaintenanceType(maintenance.maintenance_type).name }}
            </td>

            <!-- Type ID -->
            <td>{{ maintenance.maintenance_type }}</td>

            <!-- Interval in hours -->
            <td>{{ (maintenance.interval / 60).toFixed(0) }}h</td>

            <!-- Description -->
            <td>
              {{
                specificMaintenanceType(maintenance.maintenance_type)
                  .description
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MaintenancePlanCard",
  components: {},

  computed: {
    ...mapGetters(["specificMaintenanceType"]),
  },

  props: {
    maintenance_plan: [], // the maintenance plan
  },
};
</script>
