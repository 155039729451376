<template>
  <!--
    Title: Machine Group Overview
    Purpose: Shows a general overview about a manufacturing cells and its subordinated machines.
  -->
  <v-container>
    <!-- Title -->
    <v-row>
      <v-col>
        <Header :header_title="machinegroup.name" />
      </v-col>
    </v-row>

    <!-- KPI cards -->
    <v-row>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktuelle Auslastung'"
          :kpi_value="(machinegroup.current_status * 100).toFixed(0)"
          :kpi_unit="'%'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Durchschnittliche Bearbeitungszeit'"
          :kpi_value="(machinegroup.average_processing_time * 60).toFixed(0)"
          :kpi_unit="'Minuten pro Baugruppe'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktueller Durchsatz'"
          :kpi_value="machinegroup.current_throughput.toFixed(0)"
          :kpi_unit="'Baugruppen pro Stunde'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Offene Baugruppen'"
          :kpi_value="
            specificMachineType(
              specificMachine(machinegroup.components.machines[0]).type
            ).assemblyQueue.length
          "
          :kpi_unit="' '"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
    </v-row>

    <!-- Machinegroup INformation and favored Sensors -->
    <v-row>
      <v-col cols="4">
        <MachineGroupInfoCard :machinegroup="machinegroup" />
      </v-col>
      <v-col
        cols="4"
        v-for="sensor in machinegroup.components.sensors.filter(
          (sensor) => specificSensor(sensor).favored === true
        )"
        :key="sensor"
      >
        <SensorGaugeCard :sensor_id="sensor" />
      </v-col>
    </v-row>

    <!-- Machine Data -->
    <v-row>
      <!-- For each machine that is a supordinate of the manufacturing cell a machine card gets rendered -->
      <v-col
        cols="4"
        v-for="machine in machinegroup.components.machines"
        :key="machine"
      >
        <MachineCard :machineid="machine" />
      </v-col>
    </v-row>

    <!-- Utilization Data -->
    <v-row>
      <v-col>
        <UtilizationCard :utilization_data="this.utilization_data" />
      </v-col>
    </v-row>

    <!-- Throughput Data -->
    <v-row>
      <v-col>
        <ThroughputCard :throughput_data="this.throughput_data" />
      </v-col>
    </v-row>

    <!-- Average Processing TIme -->
    <v-row>
      <v-col>
        <ProcessingTimeCard
          :average_processing_time_data="average_processing_time_data"
        />
      </v-col>
    </v-row>

    <!-- Sensor Details -->
    <v-row v-if="machinegroup.components.sensors.length > 0">
      <v-col>
        <SensorDetailPanel :sensor_ids="machinegroup.components.sensors" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SensorDetailPanel from "../general/SensorDetailPanel.vue";
import MachineGroupInfoCard from "./MachineGroupInfoCard.vue";
import MachineCard from "./MachineCard.vue";
import Header from "../general/Header.vue";
import SensorGaugeCard from "../general/SensorGaugeCard.vue";
import UtilizationCard from "../general/UtilizationCard.vue";
import ThroughputCard from "../general/ThroughputCard.vue";
import ProcessingTimeCard from "../general/ProcessingTimeCard.vue";
import KPICard from "../general/KPICard.vue";
import { mapGetters } from "vuex";

export default {
  name: "MachineGroupOverview",

  components: {
    MachineGroupInfoCard,
    SensorDetailPanel,
    MachineCard,
    Header,
    SensorGaugeCard,
    UtilizationCard,
    ThroughputCard,
    ProcessingTimeCard,
    KPICard,
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificMachineGroup",
      "specificSensor",
      "allProductionOrders",
      "allAssemblys",
      "specificMachine",
      "specificMachineType",
    ]),

    // Returns the machinegroup data
    machinegroup: function () {
      return this.specificMachineGroup(this.overviewActiveElementId);
    },

    //Returns ulitization Data for apex
    utilization_data: function () {
      let data = [];
      this.machinegroup.status_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: (status.value * 100).toFixed(0),
        });
      });
      return data;
    },

    // Returns throughput data for apex
    throughput_data: function () {
      let data = [];
      this.machinegroup.throughput_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: status.value,
        });
      });
      return data;
    },

    // Returns processing time data for apex
    average_processing_time_data: function () {
      let data = [];
      this.machinegroup.average_processing_time_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: status.value,
        });
      });
      return data;
    },
  },
};
</script>
