<!-- 
    Title: MachineCard
  
    Description: Displays information on a machine within a mahcinegroup
-->
<template>
  <v-card tile height="100%" elevation="5">
    <v-card-title>
      <!-- Title/Machine name -->
      {{ machine.name }}
      <v-spacer></v-spacer>

      <!-- Machine Status -->
      <v-chip
        class="mr-2"
        :color="getMachineStatusColor(machine.current_status)"
        dark
      >
        {{ getMachineStatusText(machine.current_status) }}
      </v-chip>

      <!-- Machine ICon -->
      <v-icon color="dark grey" large>{{
        specificMachineType(machine.type).icon
      }}</v-icon>
    </v-card-title>
    <v-card-subtitle> ID: {{ machine._id }} </v-card-subtitle>
    <v-card-text> </v-card-text>

    <v-simple-table dense>
      <tbody>
        <!-- currently_working on -->
        <tr>
          <td>Arbeitet an:</td>
          <td>
            {{ currentlyWorkingOnToNameString(machine) }}
          </td>
        </tr>

        <!-- Assembly Queue -->
        <tr>
          <td>Warteschlange:</td>
          <td>
            {{ specificMachineType(machine.type).assemblyQueue.length }}
          </td>
        </tr>

        <!-- Number of Sensors -->
        <tr>
          <td>Sensoren:</td>
          <td>
            {{ machine.components.sensors.length }}
          </td>
        </tr>

        <!-- Operating Time -->
        <tr>
          <td>Betriebszeit:</td>
          <td>
            {{ convertMinutesToHoursMinutes(machine.operating_time) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- Open Machine Details Button and start/Stop switch -->
    <v-card-actions>
      <v-btn plain @click="openMachineDetails(machineid)">Details</v-btn>
      <v-spacer></v-spacer>
      <v-switch
        v-model="machineOnOff"
        color="success"
        @click="turnMachineOnOff"
      ></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import machineMixins from "../../../mixins/machineMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "MachineCard",
  mixins: [machineMixins, helperMixins],

  props: { machineid: String },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificMachine",
      "specificMachineType",
      "specificAssembly",
      "specificAssemblyType",
    ]),

    // returns the complete machine data based on the id
    machine: function () {
      return this.specificMachine(this.machineid);
    },

    // returns the state of the start/stop switch
    machineOnOff: {
      get() {
        if (this.machine.current_status === 0) {
          return false;
        } else {
          return true;
        }
      },
      set(newValue) {
        return newValue;
      },
    },
  },

  methods: {
    ...mapActions([
      "startMachine",
      "stopMachine",
      "updateOverviewActiveElement",
    ]),

    // starts/stops a machine
    turnMachineOnOff() {
      if (this.machineOnOff === true) {
        this.stopMachine({ machine_id: this.machineid });
      } else {
        this.startMachine({ machine_id: this.machineid });
      }
    },

    // opens the machine details
    openMachineDetails(machine_id) {
      this.updateOverviewActiveElement({
        new_active_element_id: machine_id,
        new_active_element_type: 3,
      });
    },
  },
};
</script>
