<!-- 
    Title: MachineGroupCard
  
    Description: Table with detailed information on a machinegroup
-->
<template>
  <v-card tile height="100%" elevation="5">
    <!-- Name -->
    <v-card-title>
      {{ machinegroup.name }}
      <v-spacer></v-spacer>
    </v-card-title>

    <!-- ID -->
    <v-card-subtitle>ID: {{ machinegroup._id }}</v-card-subtitle>
    <v-simple-table dense>
      <tbody>
        <!-- current utilization -->
        <tr>
          <td>Auslastung:</td>
          <td>{{ (machinegroup.current_status * 100).toFixed(0) }}%</td>
        </tr>

        <!-- number of machines -->
        <tr>
          <td>Maschinen:</td>
          <td>
            {{ machinegroup.components.machines.length }}
          </td>
        </tr>

        <!-- number of sensors -->
        <tr>
          <td>Sensoren:</td>
          <td>
            {{ machinegroup.components.sensors.length }}
          </td>
        </tr>

        <!-- Runtime -->
        <tr>
          <td>Betriebszeit:</td>
          <td>
            {{ convertMinutesToHoursMinutes(machinegroup.operating_time) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- Button to access the next hierarical level -->
    <v-card-actions>
      <v-btn plain @click="openMachineGroupDetails(machinegroupid)"
        >Details</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import machinegroupMixins from "../../../mixins/machinegroupMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "MachineGroupCard",
  mixins: [machinegroupMixins, helperMixins],

  props: {
    machinegroupid: String, // The id of the machinegroup that is displayed
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificMachineGroup",
      "specificMachineGroupType",
    ]),

    /**
     * Title: machinegroup()
     * Description: gets the data of the displayed machinegroup from the state
     *
     */
    machinegroup: function () {
      return this.specificMachineGroup(this.machinegroupid);
    },
  },

  methods: {
    ...mapActions(["updateOverviewActiveElement"]),

    /**
     * Title: openMachineGroupDetails()
     * Description: run when the detail Button of a machinegrouo is clicked. Opens the according hierarichal level page
     *
     */
    openMachineGroupDetails(machinegroup_id) {
      this.updateOverviewActiveElement({
        new_active_element_id: machinegroup_id,
        new_active_element_type: 2,
      });
    },
  },
};
</script>
