<!-- 
    Title: SimulationOverview
  
    Description: Page to manage simulations
-->
<template>
  <v-container>
    <!-- Title -->
    <h1 class="mt-2 mb-5">Simulationsübersicht</h1>
    <v-card class="mt-5" elevation="5" tile>
      <!-- Alert if a simulation is running, that no new simulation can be added -->
      <v-container v-if="display_alert">
        <v-alert prominent type="info" icon="mdi-information">
          <h2 class="mb-3 ml-3">Neue Simulation nicht verfügbar!</h2>
          <p class="ml-3">
            Aktuell kann keine neue Simulation gestartet werden, da das System
            bereits mit der Berechnung einer anderen Simulation beschäftigt ist.
          </p>
        </v-alert>
      </v-container>

      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn
          v-if="!display_alert"
          plain
          tile
          @click="display_new_simulation_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Simulation hinzufügen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="sim_simulation_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying simulation data  -->
      <v-data-table
        :headers="simulation_data_header"
        :items="allSimulations"
        :search="sim_simulation_search"
        :key="sim_simulation_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template for Assembly Name -->
        <template v-slot:item.simulationOrder="{ item }">
          {{
            specificAssemblyType(item.simulationOrder[0].subassembly_type).name
          }}
        </template>

        <!-- Template for Amount -->
        <template v-slot:item.amount="{ item }">
          {{ item.simulationOrder[0].amount }}
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.status="{ item }">
          <v-chip :color="getSimulationStatusColor(item.status)" dark>
            {{ getSimulationStatusText(item.status) }}
          </v-chip>
        </template>

        <!-- Template for converting created by -->
        <template v-slot:item.created_by="{ item }">
          {{ specificUser(item.created_by).login }}
        </template>

        <!-- Template for progress-->
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            color="blue"
            :value="getSimulationProgess(item)"
            height="25"
            >{{ getSimulationProgess(item) }}%</v-progress-linear
          >
        </template>

        <!-- Template for creation_date -->
        <template v-slot:item.creation_date="{ item }">
          {{ convertDateToString(item.creation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon small class="mr-2" @click="setupDelete(item)">{{
              navigationIcons.delete
            }}</v-icon>
          </div>
        </template>

        <!-- Template for Sensor Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Simulations ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <template>
      <NewSimulationDialog
        :display="display_new_simulation_dialog"
        @closeDialog="display_new_simulation_dialog = false"
      />

      <DeleteConfirmationDialog
        :title="'Soll die gewählte Simulation gelöscht werden?'"
        :object_name="deleted_simulation.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDelete"
        @confirmDelete="confirmDelete"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewSimulationDialog from "./../components/simulationNewDialog/SimulationNewSimulationDialog.vue";
import helperMixins from "../../../mixins/helperMixins";
import simulationMixins from "../../../mixins/simulationMixins";
import assemblyMixins from "../../../mixins/assemblyMixins";
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";

export default {
  name: "SimulationOverview",

  mixins: [helperMixins, simulationMixins, assemblyMixins],

  components: {
    NewSimulationDialog,
    DeleteConfirmationDialog,
  },

  computed: {
    ...mapGetters([
      "navigationIcons",
      "allSimulations",
      "specificAssemblyType",
      "specificUser",
      "specificSimAssembly",
    ]),

    // determines weather the no new simulation alert is displayed
    display_alert: function () {
      const lenght = this.allSimulations.length;
      if (lenght === 0) {
        return false;
      } else if (this.allSimulations[lenght - 1].status === 0) {
        return false;
      } else {
        return true;
      }
    },
  },

  data: () => ({
    display_new_simulation_dialog: false, // Determines weather the new Simulation Dialog is displayed
    display_delete_confirmation_dialog: false, //Determines weather the delete confirmation dialog is displayed
    deleted_simulation: {}, // Holds the to be deleted simulation
    deleted_simulation_index: -1, // Holde the to be deleted simulations index
    sim_simulation_search: "", // Search Data for Simulation Data Table
    sim_simulation_data_table_key: 0, // Key of the Simualtion Data Table

    // Simualtion Data Table Header
    simulation_data_header: [
      { text: "Name:", value: "name" },
      { text: "Baugruppe:", value: "simulationOrder" },
      { text: "Anzahl", value: "amount" },
      { text: "Status", value: "status" },
      { text: "Fortschritt", value: "progress" },
      { text: "Erstellt von", value: "created_by" },
      { text: "Erstellt am", value: "creation_date" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions(["deleteSimulation"]),

    // sets up the deletion of a simualtion
    setupDelete(simulation) {
      this.deleted_simulation_index = this.allSimulations.indexOf(simulation);
      Object.assign(this.deleted_simulation, simulation);
      this.display_delete_confirmation_dialog = true;
    },

    // cancels the deletion of a simulation
    cancelDelete() {
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        this.deleted_simulation_index = -1;
        this.deleted_simulation = {};
      });
    },

    // confirms the deletion of a simulation
    confirmDelete() {
      this.deleteSimulation(this.deleted_simulation);
      this.cancelDelete();
    },
  },
};
</script>
