<!-- 
    Title: SensorBrushChart
  
    Description: Displays historical Sensor Readings
-->

<template>
  <v-card tile elevation="5" height="100%">
    <!-- Title -->
    <v-card-title> Historische Daten </v-card-title>

    <!-- Chart -->
    <v-card-text>
      <!-- Selection -->
      <h3>Ausschnitt</h3>
      <div id="chart-line2">
        <apexchart
          type="line"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>

      <!-- Total -->
      <h3>Gesamt</h3>
      <div id="chart-line">
        <apexchart
          type="area"
          height="130"
          :options="chartOptionsLine"
          :series="seriesLine"
        ></apexchart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";

export default {
  name: "SensorBrushChart",

  computed: {
    ...mapGetters(["overviewActiveElementId", "specificSensor"]),

    // Return data for apex
    series: function () {
      return [
        {
          data: this.historical_data,
        },
      ];
    },

    // Returns selection data
    seriesLine: function () {
      return [
        {
          data: this.historical_data,
        },
      ];
    },
  },

  components: {
    apexchart: VueApexCharts,
  },

  props: {
    historical_data: [], // the complete historical readings
  },

  //Chart Options
  data: () => ({
    chartOptions: {
      chart: {
        id: "chart2",
        type: "line",
        //height: 430,
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#546E7A"],
      stroke: {
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
      },
    },

    chartOptionsLine: {
      chart: {
        id: "chart1",
        // height: 130,
        type: "area",
        brush: {
          target: "chart2",
          enabled: true,
        },
        selection: {
          enabled: true,
        },
      },
      colors: ["#008FFB"],
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.91,
          opacityTo: 0.1,
        },
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
    },
  }),
};
</script>
