var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Maschinengruppeverwaltung")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_machinegroup_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Maschinengruppe hinzufügen")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('edit')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_vm._v("Maschinengruppe bearbeiten")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('delete')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.delete))]),_vm._v("Maschinengruppe löschen")],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.machinegroup_search),callback:function ($$v) {_vm.machinegroup_search=$$v},expression:"machinegroup_search"}})],1),_c('v-data-table',{key:_vm.machinegroup_data_table_key,attrs:{"headers":_vm.machinegroup_data_header,"items":_vm.allMachineGroups,"search":_vm.machinegroup_search,"expanded":_vm.expanded_machinegroup,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_machinegroup=$event}},scopedSlots:_vm._u([{key:"item.current_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getMachineGroupStatusColor(item.current_status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getMachineGroupStatusText(item.current_status))+" ")])]}},{key:"item.assigned",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-2 mb-n2",attrs:{"dense":"","disabled":""},model:{value:(item.assigned),callback:function ($$v) {_vm.$set(item, "assigned", $$v)},expression:"item.assigned"}})]}},{key:"item.operating_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertMinutesToHoursMinutes(item.operating_time))+" ")]}},{key:"item.implementation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.implementation_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditMachineGroupDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":item.assigned},on:{"click":function($event){return _vm.confirmDeleteMachineGroup(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Maschinengruppen ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('h4',{staticClass:"mb-3"},[_vm._v("Maschinen:")]),_c('v-btn',{staticClass:"mr-3 mt-n7",attrs:{"color":"green","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){Object.assign(_vm.edited_machinegroup, item);
                _vm.setupSelection('add_machine_to_machinegroup');}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.navigationIcons.add))])],1),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),_c('th')])]),_c('tbody',_vm._l((item.components.machines),function(machine){return _c('tr',{key:machine},[_c('td',[_vm._v(_vm._s(machine))]),_c('td',[_vm._v(" "+_vm._s(_vm.allMachines.find( function (machineall) { return machineall._id === machine; } ).name)+" ")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getMachineStatusColor(
                          _vm.allMachines.find(
                            function (machineall) { return machineall._id === machine; }
                          ).current_status
                        )}},[_vm._v(" "+_vm._s(_vm.getMachineStatusText( _vm.allMachines.find( function (machineall) { return machineall._id === machine; } ).current_status ))+" ")])],1),_c('td',[_c('v-btn',{staticClass:"mr-5 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.deleteMachineFromMachineGroup(item, machine)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.remove))])],1)],1)])}),0)]),_c('h4',{staticClass:"mb-3 mt-12"},[_vm._v("Sensoren:")]),_c('v-btn',{staticClass:"mr-3 mt-n7",attrs:{"color":"green","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){Object.assign(_vm.edited_machinegroup, item);
                _vm.setupSelection('add_sensor_to_machinegroup');}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.navigationIcons.add))])],1),_c('v-simple-table',{staticClass:"mb-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),_c('th')])]),_c('tbody',_vm._l((item.components.sensors),function(sensor){return _c('tr',{key:sensor},[_c('td',[_vm._v(_vm._s(sensor))]),_c('td',[_vm._v(" "+_vm._s(_vm.allSensors.find(function (sensorall) { return sensorall._id === sensor; }) .name)+" ")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getSensorStatusColor(
                          _vm.allSensors.find(
                            function (sensorall) { return sensorall._id === sensor; }
                          ).current_status
                        )}},[_vm._v(" "+_vm._s(_vm.getSensorStatusText( _vm.allSensors.find( function (sensorall) { return sensorall._id === sensor; } ).current_status ))+" ")])],1),_c('td',[_c('v-btn',{staticClass:"mr-5 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.deleteSensorFromMachineGroup(item, sensor)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.remove))])],1),_c('v-btn',{staticClass:"mr-12 mt-n2 align-center",attrs:{"icon":"","x-small":"","absolute":"","right":""},on:{"click":function($event){return _vm.invertFavored(sensor)}}},[(!_vm.specificSensor(sensor).favored)?_c('v-icon',[_vm._v(_vm._s(_vm.navigationIcons.favoured))]):_vm._e(),(_vm.specificSensor(sensor).favored)?_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(_vm._s(_vm.navigationIcons.favoured))]):_vm._e()],1)],1)])}),0)])],1)],1)]}}])})],1),[_c('DeleteConfirmationDialog',{attrs:{"title":'Soll die gewählte Maschinengruppe gelöscht werden?',"object_name":_vm.edited_machinegroup.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDeleteEdit,"confirmDelete":_vm.deleteMachineGroupConfirmed}}),_c('SelectionDialog',{attrs:{"selection_data":_vm.selection_data,"mode":_vm.selection_mode,"display":_vm.display_select_dialog,"title":_vm.selection_title,"label":_vm.selection_label},on:{"cancelSelection":_vm.cancelSelection,"confirmSelection":_vm.confirmSelection}}),_c('NewMachineGroupDialog',{attrs:{"display":_vm.display_new_machinegroup_dialog},on:{"closeNewMachineGroupDialog":function($event){_vm.display_new_machinegroup_dialog = false}}}),_c('EditMachineGroupDialog',{attrs:{"display":_vm.display_edit_machinegroup_dialog,"edited_machinegroup":_vm.edited_machinegroup,"edited_machinegroup_index":_vm.edited_machinegroup_index},on:{"closeEditMachineGroupDialog":_vm.closeEditMachineGroupDialog}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }