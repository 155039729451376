<!-- 
    Title: AdminMachine
  
    Description: Sub Page of the Admin Center, used to configure Machine settings
-->

<template>
  <v-container>
    <h1 class="mt-2 mb-5">Maschinenverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_machine_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Maschine hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Maschine bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Maschine löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="machine_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying machine data  -->
      <v-data-table
        :headers="machine_data_header"
        :items="allMachines"
        :search="machine_search"
        :expanded.sync="expanded_machine"
        :key="machine_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template for machine type -->
        <template v-slot:item.type="{ item }">
          <v-icon>{{
            allMachineTypes.find((type) => type._id === item.type).icon
          }}</v-icon>
          {{ allMachineTypes.find((type) => type._id === item.type).name }}
        </template>

        <!-- Template for colored status chips -->
        <template v-slot:item.current_status="{ item }">
          <v-chip :color="getMachineStatusColor(item.current_status)" dark>
            {{ getMachineStatusText(item.current_status) }}
          </v-chip>
        </template>

        <!-- Template for assigned -->
        <template v-slot:item.assigned="{ item }">
          <v-checkbox
            class="mt-2 mb-n2"
            dense
            disabled
            v-model="item.assigned"
          ></v-checkbox>
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMinutesToHoursMinutes(item.operating_time) }}
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon small class="mr-2" @click="openEditMachineDialog(item)">{{
              navigationIcons.edit
            }}</v-icon>
            <v-icon
              small
              class="mr-2"
              :disabled="item.assigned"
              @click="confirmDeleteMachine(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for Machine Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <!-- Machine ID -->
              <h4 class="mt-3 mb-3">Maschinen ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <!-- Machine Description -->
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>

              <!-- Machine Sensors -->
              <h4 class="mb-3">Sensoren:</h4>

              <!-- Button to add a new Sensor -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_machine, item);
                  setupSelection('add_sensor_to_machine');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Table to display Sensor Data -->
              <v-simple-table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sensor in item.components.sensors" :key="sensor">
                    <!-- Sensor ID -->
                    <td>{{ sensor }}</td>

                    <!-- Sensor Name -->
                    <td>
                      {{
                        allSensors.find((sensorall) => sensorall._id === sensor)
                          .name
                      }}
                    </td>

                    <!-- Sensor Status Chip -->
                    <td>
                      <v-chip
                        :color="
                          getSensorStatusColor(
                            allSensors.find(
                              (sensorall) => sensorall._id === sensor
                            ).current_status
                          )
                        "
                      >
                        {{
                          getSensorStatusText(
                            allSensors.find(
                              (sensorall) => sensorall._id === sensor
                            ).current_status
                          )
                        }}
                      </v-chip>
                    </td>
                    <td>
                      <!-- Delete Sensor Button -->
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="deleteSensorFromMachine(item, sensor)"
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >

                      <!-- Favor Button -->
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-12 mt-n2 align-center"
                        @click="invertFavored(sensor)"
                      >
                        <v-icon v-if="!specificSensor(sensor).favored">{{
                          navigationIcons.favoured
                        }}</v-icon>
                        <v-icon
                          color="yellow darken-3"
                          v-if="specificSensor(sensor).favored"
                          >{{ navigationIcons.favoured }}</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!-- Dialogs -->
    <template>
      <DeleteConfirmationDialog
        :title="'Soll die gewählte Maschine gelöscht werden?'"
        :object_name="edited_machine.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteMachineConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewMachineDialog
        :display="display_new_machine_dialog"
        @closeNewMachineDialog="display_new_machine_dialog = false"
      />

      <EditMachineDialog
        :display="display_edit_machine_dialog"
        :edited_machine="edited_machine"
        :edited_machine_index="edited_machine_index"
        @closeEditMachineDialog="closeEditMachineDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import machineMixins from "../../../mixins/machineMixins";
import sensorMixins from "../../../mixins/sensorMixins";
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewMachineDialog from "../components/adminMachine/NewMachineDialog.vue";
import EditMachineDialog from "../components/adminMachine/EditMachineDialog.vue";

// Import of Types
import Machine from "../../../types/machine";

export default {
  name: "AdminMachine", // Component Name
  mixins: [machineMixins, helperMixins, sensorMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewMachineDialog,
    EditMachineDialog,
  },

  computed: {
    ...mapGetters([
      "allMachines",
      "allMachineTypes",
      "allSensors",
      "navigationIcons",
      "specificSensor",
    ]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_machine_dialog: false, // Determines wether the new machine dialog is beeing displayed
    display_edit_machine_dialog: false, // Determines wether the edit machine dialog is beeing displayed
    selected_machine_id: String, // Holds the id of the selected machine. Is beeing determined by the selection dialog
    machine_search: "", // Holds search data for the search bar of the machine data table
    expanded_machine: [], // synchronices the expanded items of the machine data table
    edited_machine_index: -1, // Holds the index of the machine that is beeing edited. determined by the openEditMachineDialog() funciton
    machine_data_table_key: 0, //Holds the key of the machine_data_table. Is needed to force vue to rerender the machine data table as vue does not notice a change in the vuex machine store after a machine is deleted

    // Holds the currently being edited machine during edits or deletion
    edited_machine: {
      _id: "",
      components: { senors: [] },
      current_status: 0,
      currently_working_on: null,
      description: "",
      implementation_data: new Date(),
      location: "",
      mqtt_pretopic: "",
      name: "",
      operating_time: 0,
      type: "",
    },

    // Standard Machine Data, used to reset edited_machine
    standard_machine: {
      _id: "",
      components: { senors: [] },
      current_status: 0,
      currently_working_on: null,
      description: "",
      implementation_data: new Date(),
      location: "",
      mqtt_pretopic: "",
      name: "",
      operating_time: 0,
      type: "",
    },

    // Holds the Header data of the machine data table
    machine_data_header: [
      { text: "Name:", value: "name" },
      { text: "Typ:", value: "type" },
      { text: "Status:", value: "current_status" },
      { text: "Zugeordnet:", value: "assigned" },
      { text: "Standort:", value: "location" },
      { text: "Laufzeit (h):", value: "operating_time" },
      { text: "Start Datum:", value: "implementation_date" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions([
      "deleteMachine",
      "editMachine",
      "editSensor",
      "addSensorToMachine",
      "removeSensorFromMachine",
    ]),

    /**
     * Title: deleteMachine()
     * Description: opens the confirmation dialog when a machine is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} machine the machien that is supposed to be deleted
     *
     */
    confirmDeleteMachine(machine) {
      this.display_delete_confirmation_dialog = true;
      this.edited_machine_index = this.allMachines.indexOf(machine);
      Object.assign(this.edited_machine, machine);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_machine and edited_machine_index variable after the deletion of a machine has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_machine, this.standard_machine);
        this.edited_machine_index = -1;
      });
    },

    /**
     * Title: confirmDelete()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteMachineConfirmed() {
      this.deleteMachine(this.edited_machine);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a machine is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    async confirmSelection(mode, selection_id) {
      if (mode === "delete") {
        const machine = this.allMachines.find(
          (machineall) => machineall._id === selection_id
        );
        Object.assign(this.edited_machine, machine);
        this.confirmDeleteMachine(machine);
      } else if (mode === "edit") {
        const machine = this.allMachines.find(
          (machineall) => machineall._id === selection_id
        );
        Object.assign(this.edited_machine, machine);
        this.display_edit_machine_dialog = true;
      }

      // If a new sensor has been added to the machine
      else if (mode === "add_sensor_to_machine") {
        await this.addSensorToMachine({
          machine_id: this.edited_machine._id,
          sensor_id: selection_id,
        });
      }

      // Reset Selection Data and force Table update
      this.machine_data_table_key++;
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditMachineDialog()
     * Description: opens the Edit Machine Dialog and sets the edited_machine and edited_machine_index according to the machine selected by the user
     *
     * @param {Machine} machine the machine that is beeing edited
     *
     */
    openEditMachineDialog(machine) {
      this.edited_machine_index = this.allMachines.indexOf(machine);
      Object.assign(this.edited_machine, machine);
      this.display_edit_machine_dialog = true;
    },

    /**
     * Title: closeEditMachineDialog()
     * Description: closes the Edit Machine Dialog and resets the edited_machine and edited_machine_index
     *
     */
    closeEditMachineDialog() {
      this.display_edit_machine_dialog = false;
      this.edited_machine_index = -1;
      Object.assign(this.edited_machine, this.standard_machine);
      this.machine_data_table_key = this.machine_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a machine is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_machine, this.standard_machine);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Maschine";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Maschine zum Bearbeiten auswählen:";
        this.selection_data = this.allMachines;
      } else if (selection_mode === "delete") {
        this.selection_title = "Maschine zum Löschen auswählen:";
        this.selection_data = this.allMachines;
      } else if (selection_mode === "add_sensor_to_machine") {
        this.selection_title = "Sensor auswählen:";
        this.selection_label = "Sensor";
        this.selection_data = this.allSensors.filter(
          (sensor) => sensor.assigned === false
        );
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: deleteSensorFromMachine()
     * Description: deletes a sensor from a machine
     *
     * @param {Object} machine the machine from which the sensor is to be deleted
     * @param {String} sensor_id the id of the sensor that is to be deleted
     *
     */

    async deleteSensorFromMachine(machine, sensor_id) {
      await this.removeSensorFromMachine({
        machine_id: machine._id,
        sensor_id,
      });
      this.machine_data_table_key++;
      this.closeEditMachineDialog;
    },

    /**
     * Title: invertFavored()
     * Description: inverts the favored status of a sensor
     *
     * @param {Stirng} sensor_id the sensor which is beeing edited
     *
     */

    async invertFavored(sensor_id) {
      var edited_sensor = {};
      Object.assign(edited_sensor, this.specificSensor(sensor_id));
      const edited_sensor_index = this.allSensors.indexOf(
        this.specificSensor(sensor_id)
      );
      edited_sensor.favored = !edited_sensor.favored;
      await this.editSensor({
        sensor: edited_sensor,
        sensor_index: edited_sensor_index,
      });
      this.machine_data_table_key = this.machine_data_table_key + 1;
    },
  },
};
</script>
