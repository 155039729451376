<!-- 
    Title: DeleteConfirmationDialog
  
    Description: Used to confirm a deletion by a user
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title class="text-h5 justify-center">{{ title }}</v-card-title>

      <!-- Name of the deleted object -->
      <v-card-title class="text-h5 justify-center orange--text">{{
        object_name
      }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn color="blue darken-1" text @click="$emit('cancelDelete')"
          >Abbrechen</v-btn
        >

        <!-- Delete Button -->
        <v-btn color="red" text @click="$emit('confirmDelete')">Löschen</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteConfirmationDialog",
  props: {
    title: String, // Confirmation Dialog Title
    object_name: String, // Name of the Object that is supposed to be deleted
    display: Boolean, // Determines weather the dialog is beeing displayed
  },
};
</script>
