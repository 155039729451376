<!-- 
    Title: AdminDevTools
  
    Description: Sub Page of the Admin Center, used to configure Factory settings
-->

<template>
  <v-container>
    <h1 class="mt-2 mb-5">Fabrikverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_factory_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Fabrik hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Fabrik bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Fabrik löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="factory_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying factory data  -->
      <v-data-table
        :headers="factory_data_header"
        :items="allFactorys"
        :search="factory_search"
        :expanded.sync="expanded_factory"
        :key="factory_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template for colored status chips -->
        <template v-slot:item.current_status="{ item }">
          <v-chip :color="getFactoryStatusColor(item.current_status)" dark>
            {{ getFactoryStatusText(item.current_status) }}
          </v-chip>
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMinutesToHoursMinutes(item.operating_time) }}
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon small class="mr-2" @click="openEditFactoryDialog(item)">{{
              navigationIcons.edit
            }}</v-icon>
            <v-icon small class="mr-2" @click="confirmDeleteFactory(item)">{{
              navigationIcons.delete
            }}</v-icon>
          </div>
        </template>

        <!-- Template for Factory Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Fabrik ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>

              <!-- MachineGroups -->
              <h4 class="mb-3">Maschinengruppen:</h4>

              <!-- Button to add a machinegroup -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_factory, item);
                  setupSelection('add_machinegroup_to_factory');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Machinegroup Table -->
              <v-simple-table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="machinegroup in item.components.machine_groups"
                    :key="machinegroup"
                  >
                    <!-- ID -->
                    <td>{{ machinegroup }}</td>

                    <!-- Name -->
                    <td>
                      {{
                        allMachineGroups.find(
                          (machinegroupall) =>
                            machinegroupall._id === machinegroup
                        ).name
                      }}
                    </td>

                    <!-- Chip for displaxing machinegroup status -->
                    <td>
                      <v-chip
                        :color="
                          getMachineGroupStatusColor(
                            allMachineGroups.find(
                              (machinegroupall) =>
                                machinegroupall._id === machinegroup
                            ).current_status
                          )
                        "
                      >
                        {{
                          getMachineGroupStatusText(
                            allMachineGroups.find(
                              (machinegroupall) =>
                                machinegroupall._id === machinegroup
                            ).current_status
                          )
                        }}
                      </v-chip>
                    </td>

                    <!-- Button for deleting a machinegroup from a factory -->
                    <td>
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="
                          deleteMachineGroupFromFactory(item, machinegroup)
                        "
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <!-- Sensoren -->
              <h4 class="mb-3 mt-12">Sensoren:</h4>

              <!-- Button to add a sensor to a factory -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_factory, item);
                  setupSelection('add_sensor_to_factory');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >


              <!-- Table for Factory Sensors -->
              <v-simple-table class="mb-3">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sensor in item.components.sensors" :key="sensor">

                    <!-- Sensor ID -->
                    <td>{{ sensor }}</td>
                    <td>
                      {{
                        allSensors.find((sensorall) => sensorall._id === sensor)
                          .name
                      }}
                    </td>
                    <td>
                      <!-- Chip for displaying sensor status -->
                      <v-chip
                        :color="
                          getSensorStatusColor(
                            allSensors.find(
                              (sensorall) => sensorall._id === sensor
                            ).current_status
                          )
                        "
                      >
                        {{
                          getSensorStatusText(
                            allSensors.find(
                              (sensorall) => sensorall._id === sensor
                            ).current_status
                          )
                        }}
                      </v-chip>
                    </td>

                    <!-- Button to delete a sensor from a factory -->
                    <td>
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="deleteSensorFromFactory(item, sensor)"
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >

                      <!-- Button to change if Sensor is favored or not -->
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-12 mt-n2 align-center"
                        @click="invertFavored(sensor)"
                      >
                        <!-- Icon if Sensor is not favored -->
                        <v-icon v-if="!specificSensor(sensor).favored">{{
                          navigationIcons.favoured
                        }}</v-icon>

                        <!-- Icon if Sensor is favored -->
                        <v-icon
                          color="yellow darken-3"
                          v-if="specificSensor(sensor).favored"
                          >{{ navigationIcons.favoured }}</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll die gewählte Fabrik gelöscht werden?'"
        :object_name="edited_factory.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteFactoryConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewFactoryDialog
        :display="display_new_factory_dialog"
        @closeNewFactoryDialog="display_new_factory_dialog = false"
      />

      <EditFactoryDialog
        :display="display_edit_factory_dialog"
        :edited_factory="edited_factory"
        :edited_factory_index="edited_factory_index"
        @closeEditFactoryDialog="closeEditFactoryDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import factoryMixins from "../../../mixins/factoryMixins";
import sensorMixins from "../../../mixins/sensorMixins";
import helperMixins from "../../../mixins/helperMixins";
import machinegroupMixins from "../../../mixins/machinegroupMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewFactoryDialog from "../components/adminFactory/NewFactoryDialog.vue";
import EditFactoryDialog from "../components/adminFactory/EditFactoryDialog.vue";

// Import of Types
import Factory from "../../../types/factory";

export default {
  name: "AdminFactory",
  mixins: [factoryMixins, helperMixins, sensorMixins, machinegroupMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewFactoryDialog,
    EditFactoryDialog,
  },

  computed: {
    ...mapGetters([
      "allFactorys",
      "allSensors",
      "navigationIcons",
      "allMachineGroups",
      "specificSensor",
    ]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_factory_dialog: false, // Determines wether the new factory dialog is beeing displayed
    display_edit_factory_dialog: false, // Determines wether the edit factory dialog is beeing displayed
    selected_factory_id: String, // Holds the id of the selected factory. Is beeing determined by the selection dialog
    factory_search: "", // Holds search data for the search bar of the factory data table
    expanded_factory: [], // synchronices the expanded items of the factory data table
    edited_factory_index: -1, // Holds the index of the factory that is beeing edited. determined by the openEditFactoryDialog() funciton
    factory_data_table_key: 0, //Holds the key of the factory_data_table. Is needed to force vue to rerender the factory data table as vue does not notice a change in the vuex factory store after a factory is deleted

    // Holds the currently being edited factory during edits or deletion
    edited_factory: {
      _id: "",
      components: {
        machine_groups: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },

    // Holds standard values of a factory used to reset
    standard_factory: {
      _id: "",
      components: {
        machine_groups: [],
        sensors: [],
      },
      current_status: 0,
      description: "",
      implementation_date: new Date(),
      location: "",
      name: "",
      operating_time: 0,
    },

    // Holds the Header data of the factory data table
    factory_data_header: [
      { text: "Name:", value: "name" },
      { text: "Status:", value: "current_status" },
      { text: "Standort:", value: "location" },
      { text: "Laufzeit (h):", value: "operating_time" },
      { text: "Start Datum:", value: "implementation_date" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions([
      "deleteFactory",
      "editFactory",
      "editSensor",
      "addMachineGroupToFactory",
      "addSensorToFactory",
      "removeMachineGroupFromFactory",
      "removeSensorFromFactory",
    ]),

    /**
     * Title: confirmDeleteFactory()
     * Description: opens the confirmation dialog when a factory is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} factory the machien that is supposed to be deleted
     *
     */
    confirmDeleteFactory(factory) {
      this.display_delete_confirmation_dialog = true;
      this.edited_factory_index = this.allFactorys.indexOf(factory);
      Object.assign(this.edited_factory, factory);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_factory and edited_factory_index variable after the deletion of a factory has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_factory, this.standard_factory);
        this.edited_factory_index = -1;
      });
    },

    /**
     * Title: deleteFactoryConfirmed()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteFactoryConfirmed() {
      this.deleteFactory(this.edited_factory);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a factory is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     * @param {String} selection_id the id which has been selected
     *
     */
    async confirmSelection(mode, selection_id) {
      if (mode === "delete") {
        const factory = this.allFactorys.find(
          (factoryall) => factoryall._id === selection_id
        );
        Object.assign(this.edited_factory, factory);
        this.confirmDeleteFactory(factory);
      } else if (mode === "edit") {
        const factory = this.allFactorys.find(
          (factoryall) => factoryall._id === selection_id
        );
        Object.assign(this.edited_factory, factory);
        this.display_edit_factory_dialog = true;
      }

      // If a new sensor has been added to the factory
      else if (mode === "add_sensor_to_factory") {
        await this.addSensorToFactory({
          factory_id: this.edited_factory._id,
          sensor_id: selection_id,
        });
      }
      // If a new machine has been added to the machinegroup
      else if (mode === "add_machinegroup_to_factory") {
        await this.addMachineGroupToFactory({
          factory_id: this.edited_factory._id,
          machinegroup_id: selection_id,
        });
      }
      this.factory_data_table_key++;
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditFactoryDialog()
     * Description: opens the Edit Factory Dialog and sets the edited_factory and edited_factory_index according to the factory selected by the user
     *
     * @param {Factory} factory the factory that is beeing edited
     *
     */
    openEditFactoryDialog(factory) {
      this.edited_factory_index = this.allFactorys.indexOf(factory);
      Object.assign(this.edited_factory, factory);
      this.display_edit_factory_dialog = true;
    },

    /**
     * Title: closeEditFactoryDialog()
     * Description: closes the Edit Factory Dialog and resets the edited_factory and edited_factory_index
     *
     */
    closeEditFactoryDialog() {
      this.display_edit_factory_dialog = false;
      this.edited_factory_index = -1;
      Object.assign(this.edited_factory, this.standard_factory);
      this.factory_data_table_key = this.factory_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a factory is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_factory, this.standard_factory);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Fabrik";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Fabrik zum Bearbeiten auswählen:";
        this.selection_data = this.allFactorys;
      } else if (selection_mode === "delete") {
        this.selection_title = "Fabrik zum Löschen auswählen:";
        this.selection_data = this.allFactorys;
      } else if (selection_mode === "add_machinegroup_to_factory") {
        this.selection_title = "Maschinengruppe auswählen:";
        this.selection_label = "Maschinengruppe";
        this.selection_data = this.allMachineGroups.filter(
          (machinegroup) => machinegroup.assigned === false
        );
      } else if (selection_mode === "add_sensor_to_factory") {
        this.selection_title = "Sensor auswählen:";
        this.selection_label = "Sensor";
        this.selection_data = this.allSensors.filter(
          (sensor) => sensor.assigned === false
        );
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: deleteSensorFormFactory()
     * Description: is called when a sensor is beeing deleted from a factory, calls the equivilant vuex Method
     *
     * @param {Object} factory the factory from which the sensor is being deleted
     * @param {String} sensor_id the id of the sensor that is supposed to be deleted
     *
     */
    async deleteSensorFromFactory(factory, sensor_id) {
      // Call the vuex Method
      await this.removeSensorFromFactory({
        factory_id: factory._id,
        sensor_id,
      });

      // Force Table rerender
      this.factory_data_table_key++;

      // reset selection data
      this.closeEditFactoryDialog;
    },

    /**
     * Title: deleteMachineGroupFromFactory()
     * Description: is called when a machinegroup is beeing deleted from a factory, calls vuex
     *
     * @param {Object} factory the factory from which the machinegroup is beeing deleted
     * @param {String} machinegroup_id the id of the machinegroup that is beeing deleted
     *
     */
    async deleteMachineGroupFromFactory(factory, machinegroup_id) {
      // call vuex
      await this.removeMachineGroupFromFactory({
        factory_id: factory._id,
        machinegroup_id,
      });

      // force Table update
      this.factory_data_table_key++;

      // reset selected factory
      this.closeEditFactoryDialog;
    },

    /**
     * Title: invertFavored()
     * Description: inverts the favored status of a sensor
     *
     * @param {Stirng} sensor_id the sensor which is beeing edited
     *
     */
    async invertFavored(sensor_id) {
      var edited_sensor = {};
      Object.assign(edited_sensor, this.specificSensor(sensor_id));

      // Get Sensor Index
      const edited_sensor_index = this.allSensors.indexOf(
        this.specificSensor(sensor_id)
      );

      // Invert Favored State
      edited_sensor.favored = !edited_sensor.favored;

      // Call vuex
      await this.editSensor({
        sensor: edited_sensor,
        sensor_index: edited_sensor_index,
      });

      // Force table update
      this.factory_data_table_key++;
    },
  },
};
</script>
