<!-- 
    Title: AdminMaintenanceType
  
    Description: Sub Page of the Admin Center, used to configure Maintenancetype settings
-->
<template>
  <v-container>
    <h1 class="mt-2 mb-5">Wartungstypenverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_maintenancetype_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Wartungstyp hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Wartungstyp bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Wartungstyp löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="maintenancetype_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying maintenancetype data  -->
      <v-data-table
        :headers="maintenancetype_data_header"
        :items="allMaintenanceTypes"
        :search="maintenancetype_search"
        :expanded.sync="expanded_maintenancetype"
        :key="maintenancetype_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template maintenancetype name-->
        <template v-slot:item.name="{ item }">
          <v-icon class="mr-2">{{ item.icon }}</v-icon>
          {{ item.name }}
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMillisToHoursString(item.operating_time) }} h
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              small
              class="mr-2"
              @click="openEditMaintenanceTypeDialog(item)"
              >{{ navigationIcons.edit }}</v-icon
            >
            <v-icon
              small
              class="mr-2"
              @click="confirmDeleteMaintenanceType(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for MaintenanceType Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <h4 class="mt-3 mb-3">Wartungstyp ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll der gewählte Wartungstyp gelöscht werden?'"
        :object_name="edited_maintenancetype.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteMaintenanceTypeConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewMaintenanceTypeDialog
        :display="display_new_maintenancetype_dialog"
        @closeNewMaintenanceTypeDialog="
          display_new_maintenancetype_dialog = false
        "
      />

      <EditMaintenanceTypeDialog
        :display="display_edit_maintenancetype_dialog"
        :edited_maintenancetype="edited_maintenancetype"
        :edited_maintenancetype_index="edited_maintenancetype_index"
        @closeEditMaintenanceTypeDialog="closeEditMaintenanceTypeDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewMaintenanceTypeDialog from "../components/adminMaintenanceType/NewMaintenanceTypeDialog.vue";
import EditMaintenanceTypeDialog from "../components/adminMaintenanceType/EditMaintenanceTypeDialog.vue";

// Import of Types
import MaintenanceType from "../../../types/maintenanceType";

export default {
  name: "AdminMaintenanceType",
  mixins: [helperMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewMaintenanceTypeDialog,
    EditMaintenanceTypeDialog,
  },

  computed: {
    ...mapGetters(["allMaintenanceTypes", "navigationIcons"]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_maintenancetype_dialog: false, // Determines wether the new maintenancetype dialog is beeing displayed
    display_edit_maintenancetype_dialog: false, // Determines wether the edit maintenancetype dialog is beeing displayed
    selected_maintenancetype_id: String, // Holds the id of the selected maintenancetype. Is beeing determined by the selection dialog
    maintenancetype_search: "", // Holds search data for the search bar of the maintenancetype data table
    expanded_maintenancetype: [], // synchronices the expanded items of the maintenancetype data table
    edited_maintenancetype_index: -1, // Holds the index of the maintenancetype that is beeing edited. determined by the openEditMaintenanceTypeDialog() funciton
    maintenancetype_data_table_key: 0, //Holds the key of the maintenancetype_data_table. Is needed to force vue to rerender the maintenancetype data table as vue does not notice a change in the vuex maintenancetype store after a maintenancetype is deleted

    // Holds the currently being edited maintenancetype during edits or deletion
    edited_maintenancetype: {
      _id: "",
      icon: "",
      name: "",
      description: "",
    },

    // Standard Maintenancetype Data, used to reset edited_maintenancetype
    standard_maintenancetype: {
      _id: "",
      icon: "",
      name: "",
      description: "",
    },

    // Holds the Header data of the maintenancetype data table
    maintenancetype_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID", value: "_id" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions(["deleteMaintenanceType"]),

    /**
     * Title: deleteMaintenanceType()
     * Description: opens the confirmation dialog when a maintenancetype is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} maintenancetype the machien that is supposed to be deleted
     *
     */
    confirmDeleteMaintenanceType(maintenancetype) {
      this.display_delete_confirmation_dialog = true;
      this.edited_maintenancetype_index =
        this.allMaintenanceTypes.indexOf(maintenancetype);
      Object.assign(this.edited_maintenancetype, maintenancetype);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_maintenancetype and edited_maintenancetype_index variable after the deletion of a maintenancetype has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(
          this.edited_maintenancetype,
          this.standard_maintenancetype
        );
        this.edited_maintenancetype_index = -1;
      });
    },

    /**
     * Title: confirmDelete()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteMaintenanceTypeConfirmed() {
      this.deleteMaintenanceType(this.edited_maintenancetype);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a maintenancetype is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    confirmSelection(mode, selection_id) {
      if (mode === "delete") {
        const maintenancetype = this.allMaintenanceTypes.find(
          (maintenancetypeall) => maintenancetypeall._id === selection_id
        );
        Object.assign(this.edited_maintenancetype, maintenancetype);
        this.confirmDeleteMaintenanceType(maintenancetype);
      } else if (mode === "edit") {
        const maintenancetype = this.allMaintenanceTypes.find(
          (maintenancetypeall) => maintenancetypeall._id === selection_id
        );
        Object.assign(this.edited_maintenancetype, maintenancetype);
        this.display_edit_maintenancetype_dialog = true;
      }

      // reset selection data
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditMaintenanceTypeDialog()
     * Description: opens the Edit MaintenanceType Dialog and sets the edited_maintenancetype and edited_maintenancetype_index according to the maintenancetype selected by the user
     *
     * @param {MaintenanceType} maintenancetype the maintenancetype that is beeing edited
     *
     */
    openEditMaintenanceTypeDialog(maintenancetype) {
      console.log("open");
      this.edited_maintenancetype_index =
        this.allMaintenanceTypes.indexOf(maintenancetype);
      Object.assign(this.edited_maintenancetype, maintenancetype);
      this.display_edit_maintenancetype_dialog = true;
    },

    /**
     * Title: closeEditMaintenanceTypeDialog()
     * Description: closes the Edit MaintenanceType Dialog and resets the edited_maintenancetype and edited_maintenancetype_index
     *
     */
    closeEditMaintenanceTypeDialog() {
      this.display_edit_maintenancetype_dialog = false;
      this.edited_maintenancetype_index = -1;
      Object.assign(this.edited_maintenancetype, this.standard_maintenancetype);
      this.maintenancetype_data_table_key =
        this.maintenancetype_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a maintenancetype is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_maintenancetype, this.standard_maintenancetype);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Wartungstyp";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Wartungstyp zum Bearbeiten auswählen:";
        this.selection_data = this.allMaintenanceTypes;
      } else if (selection_mode === "delete") {
        this.selection_title = "Wartungstyp zum Löschen auswählen:";
        this.selection_data = this.allMaintenanceTypes;
      }
      this.display_select_dialog = true;
    },
  },
};
</script>
