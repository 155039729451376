export default {
  methods: {
    /* Title: getSimulationStatusText
     *
     * Description: Returns a String representing the Status of an Simulation
     *
     */
    getSimulationStatusText(simulation_status: number) {
      if (simulation_status === 0) {
        return "Abgeschlossen";
      } else {
        return "In Bearbeitung";
      }
    },

    /* Title: getSimulationStatusColor
     *
     * Description: Returns a Color-String representing the Status of an Simulation
     *
     */
    getSimulationStatusColor(simulation_status: number) {
      if (simulation_status === 0) {
        return "green";
      } else {
        return "grey";
      }
    },

    /* Title: getSimulationProgress
     *
     * Description: Returns a the progress percentage of an Simulation based on the number of finished subassemblies
     *
     */
    getSimulationProgess(simulation: any) {
      if (simulation.status === simulation.simulationOrder[0].amount) {
        return 0;
      } else {
        return (
          ((simulation.status * -1 + simulation.simulationOrder[0].amount) /
            simulation.simulationOrder[0].amount) *
          100
        );
      }
    },
  },
};
