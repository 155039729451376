import { Sensor, SensorStatus } from "../types/sensor";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["specificSensorType"]),
  },

  methods: {
    /* Title: getSensorStatusText
     *
     * Description: Returns a String representing the Status of an Sensor
     *
     */
    getSensorStatusText(sensor_status: SensorStatus) {
      switch (sensor_status) {
        case 0:
          return "Deaktiviert";
        case 1:
          return "Aktiviert";
        case 4:
          return "In Wartung";
        default:
          break;
      }
    },

    /* Title: getSensorStatusColor
     *
     * Description: Returns a Color-String representing the Status of an Sensor
     *
     */
    getSensorStatusColor(sensor_status: SensorStatus) {
      switch (sensor_status) {
        case 0:
          return "grey";
        case 1:
          return "green";
        case 4:
          return "orange";
        default:
          return "black";
      }
    },

    /* Title: getSensorValueColor
     *
     * Description: returns a status/Signaling color based on the current reading and the operating range of the sensor
     *
     */
    getSensorValueColor(sensor: Sensor): string {
      if (sensor.current_status === 0 || sensor.current_status === 4) {
        return "#9E9E9E";
      } else if (
        sensor.current_reading > sensor.operating_range_max ||
        sensor.current_reading < sensor.operating_range_min
      ) {
        return "#F44336";
      } else if (
        sensor.current_reading > sensor.optimal_operating_range_max ||
        sensor.current_reading < sensor.optimal_operating_range_min
      ) {
        return "#FF9800";
      } else {
        return "#4CAF50";
      }
    },

    /* Title: getSensorValueString
     *
     * Description: return the current reading of the sensor combined with its unit as a strring
     *
     */
    getSensorValueString(sensor: Sensor): string {
      if (sensor.current_status === 0 || sensor.current_status === 4) {
        return "-";
      } else {
        return (
          sensor.current_reading +
          " " +
          this.specificSensorType(sensor.type).unit
        );
      }
    },

    /* Title: getSensorErrorText
     *
     * Description: returns a string representing the error code of an sensor
     *
     */
    getSensorErrorText(sensor_status: 1 | 2) {
      switch (sensor_status) {
        case 1:
          return "Außerhalb Optimum";
        case 2:
          return "Kritischer Fehler";
        default:
          return "-";
      }
    },
  },
};
