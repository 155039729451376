<!-- 
    Title: User SelectionDialog
  
    Description: Dialog for selection which user shall be edited
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card tile>
      <!-- Dialog Title -->
      <v-card-title class="text-h5 justify-center">{{ title }}</v-card-title>

      <!-- User Selection -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-select
              :items="selection_data"
              item-text="login"
              item-value="_id"
              v-model="selection_id"
              :label="label"
              :rules="[() => !!selection_id || 'Pflichtfeld']"
              required
            ></v-select>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="blue darken-1"
          text
          @click="
            $refs.form.reset();
            $emit('cancelSelection');
          "
          >Abbrechen</v-btn
        >

        <!-- Confirmation Dialog -->
        <v-btn
          color="green"
          text
          @click="confirmSelection"
          :disabled="!form_validation"
          >Auswahl bestätigen</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserSelectionDialog", // Component Name

  props: {
    selection_data: [], // Data which can be selected
    mode: String, // mode in which the selection dialog is opened, mainly used to return to the mother component in order to determine which action is beeing done after selection is confirmed
    display: Boolean, //determines if the dialog is beeing displayed
    label: String, // the label of the v-select
    title: String, // title of the dialog
  },

  data: () => ({
    selection_id: "", // holds the selected element id
    form_validation: true, // contains validation data of the input form
  }),

  methods: {
    /**
     * Title: confirmsSelection()
     * Description: cinforms the selection, emits the mode of the selection (e.g. delete/edot) & the id of the selected object and resets the selection id
     *
     */
    confirmSelection() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Emit Confirmation/Selection to parent component
        this.$emit("confirmSelection", this.mode, this.selection_id);

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
