<!-- 
    Title: NewSensorTypeDialog
  
    Description: Dialog for adding a new role
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>

      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neuen Sensortyp hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">

            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Unit Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="unit"
                  label="Einheit"
                  :rules="[() => !!unit || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Icon Input -->
              <v-col>
                <v-text-field
                  v-model="icon"
                  label="Icon"
                  :rules="[() => !!icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>

        <!-- Close Button -->
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewSensorTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveSensorType"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewSensorTypeDialog", // Component Name

  props: {
    display: Boolean, // Determines weather the dialog is beeing displayed
  },

  data: () => ({
    // Data of the new SensorType
    name: "",
    description: "",
    icon: "",
    unit: "",
    form_validation: false,
  }),

  methods: {
    ...mapActions(["newSensorType"]),

    /**
     * Title: saveSensorType()
     * Description: saves the new SensorType by calling the vuex Method
     *
     */
    saveSensorType() {

      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {

        // Call Vuex Method to save
        this.newSensorType({
          name: this.name,
          description: this.description,
          icon: this.icon,
          unit: this.unit,
          maintenance_plan: [],
        });

        // Emit Save Event to parent component
        this.$emit("closeNewSensorTypeDialog");

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
