<template>
  <!-- 
    Title: NewMachineDialog
  
    Description: Dialog for adding Data of a new machine
  
   -->

  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neue Maschine hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Machine Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Machine Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <!-- Machine Location Input -->
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Machine Type Selector -->
              <v-col>
                <v-select
                  v-model="type"
                  :items="allMachineTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Dialog Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewMachineDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveMachine"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewMachineDialog",

  props: {
    display: Boolean, //Determines weather the dialog is beeing displayed
  },

  data: () => ({
    name: "", // Name of the new machine
    description: "", // Description og the new Machine
    location: "", // Location of the new machine
    type: "", // Type of the new machine
    form_validation: false, //Contains Validation Data of the form
  }),

  computed: {
    ...mapGetters(["allMachineTypes"]),
  },

  methods: {
    ...mapActions(["newMachine"]),

    /**
     * Title: saveMachine()
     * Description: saves the new Machine by calling the vuex Method
     *
     */
    saveMachine() {
      // Validate Form
      this.form_validation = this.$refs.form.validate();

      //Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to save
        this.newMachine({
          name: this.name,
          description: this.description,
          location: this.location,
          type: this.type,
        });

        // Emit Close Dialog to Parent Component
        this.$emit("closeNewMachineDialog");

        // Reset Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
