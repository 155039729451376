<!-- 
    Title: KPI Card
  
    Description: Card to dispaly a single KPI
-->
<template>
  <v-card
    :style="{ 'background-color': kpi_bgcolor }"
    height="100%"
    tile
    elevation="5"
  >
    <!-- KPI Title -->
    <v-card-text class="white--text"> {{ kpi_title }}</v-card-text>

    <!-- KPI Value -->
    <v-row>
      <v-col align-self="center">
        <v-card-title class="font-weight-black mt-n4 mb-n4 white--text"
          ><h1>{{ kpi_value }}</h1></v-card-title
        >
      </v-col>
    </v-row>

    <!-- KPI Unit -->
    <v-card-title class="white--text">
      {{ kpi_unit }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "KPICard",

  props: {
    kpi_title: String, // Title of the KPI
    kpi_value: [Number, String], // Value of the KPI
    kpi_icon: String, // Icon of the KPI
    kpi_bgcolor: String, // Background Color of the KPI
    kpi_unit: String, // the unit of the kpi
  },
};
</script>
