<!-- 
    Title: AdminSensor
  
    Description: Sub Page of the Admin Center, used to configure Sensor settings
-->
<template>
  <v-container>
    <h1 class="mt-2 mb-5">Sensorenverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_sensor_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Sensor hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Sensor bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Sensor löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="sensor_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying sensor data  -->
      <v-data-table
        :headers="sensor_data_header"
        :items="allSensors"
        :search="sensor_search"
        :expanded.sync="expanded_sensor"
        :key="sensor_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template sensor name-->
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>

        <!-- Template sensor type -->
        <template v-slot:item.type="{ item }">
          <v-icon>{{ specificSensorType(item.type).icon }}</v-icon>
          {{ specificSensorType(item.type).name }}
        </template>

        <!-- Template sensor Unit -->
        <template v-slot:item.unit="{ item }">
          {{ specificSensorType(item.type).unit }}
        </template>

        <!-- Template for colored status chips -->
        <template v-slot:item.current_status="{ item }">
          <v-chip :color="getSensorStatusColor(item.current_status)" dark>
            {{ getSensorStatusText(item.current_status) }}
          </v-chip>
        </template>

        <!-- Template for assigned -->
        <template v-slot:item.assigned="{ item }">
          <v-checkbox
            class="mt-2 mb-n2"
            dense
            disabled
            v-model="item.assigned"
          ></v-checkbox>
        </template>

        <!-- Template for converting Operating Time -->
        <template v-slot:item.operating_time="{ item }">
          {{ convertMinutesToHoursMinutes(item.operating_time) }}
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.implementation_date="{ item }">
          {{ convertDateToString(item.implementation_date) }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <!-- Favor Buttons -->
            <v-icon
              small
              class="mr-2"
              @click="invertFavoured(item)"
              v-if="!item.favored"
              >{{ navigationIcons.favoured }}</v-icon
            >
            <v-icon
              small
              color="yellow darken-3"
              class="mr-2"
              @click="invertFavoured(item)"
              v-if="item.favored"
              >{{ navigationIcons.favoured }}</v-icon
            >

            <!-- Edit Sensor Button -->
            <v-icon small class="mr-2" @click="openEditSensorDialog(item)">{{
              navigationIcons.edit
            }}</v-icon>

            <!-- Delete Sensor Button -->
            <v-icon
              small
              class="mr-2"
              :disabled="item.assigned"
              @click="confirmDeleteSensor(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for Sensor Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <!-- Sensor ID -->
              <h4 class="mt-3 mb-3">Sensor ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <!-- Sensor Description -->
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>

              <!-- Min Max Values -->
              <v-row>
                <v-col>
                  <v-text-field
                    readonly
                    v-model="item.operating_range_max"
                    label="Absoluter max. Wert"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    v-model="item.operating_range_min"
                    label="Absolute min. Wert"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    v-model="item.optimal_operating_range_max"
                    label="Optimaler max. Wert"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    v-model="item.optimal_operating_range_min"
                    label="Optimaler min. Wert"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll die gewählte Sensor gelöscht werden?'"
        :object_name="edited_sensor.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteSensorConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewSensorDialog
        :display="display_new_sensor_dialog"
        @closeNewSensorDialog="display_new_sensor_dialog = false"
      />

      <EditSensorDialog
        :display="display_edit_sensor_dialog"
        :edited_sensor="edited_sensor"
        :edited_sensor_index="edited_sensor_index"
        @closeEditSensorDialog="closeEditSensorDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import sensorMixins from "../../../mixins/sensorMixins";
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewSensorDialog from "../components/adminSensor/NewSensorDialog.vue";
import EditSensorDialog from "../components/adminSensor/EditSensorDialog.vue";

// Import of Types
import Sensor from "../../../types/sensor";

export default {
  name: "AdminSensor",
  mixins: [sensorMixins, helperMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewSensorDialog,
    EditSensorDialog,
  },

  computed: {
    ...mapGetters(["allSensors", "navigationIcons", "specificSensorType"]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_sensor_dialog: false, // Determines wether the new sensor dialog is beeing displayed
    display_edit_sensor_dialog: false, // Determines wether the edit sensor dialog is beeing displayed
    selected_sensor_id: String, // Holds the id of the selected sensor. Is beeing determined by the selection dialog
    sensor_search: "", // Holds search data for the search bar of the sensor data table
    expanded_sensor: [], // synchronices the expanded items of the sensor data table
    edited_sensor_index: -1, // Holds the index of the sensor that is beeing edited. determined by the openEditSensorDialog() funciton
    sensor_data_table_key: 0, //Holds the key of the sensor_data_table. Is needed to force vue to rerender the sensor data table as vue does not notice a change in the vuex sensor store after a sensor is deleted

    // Holds the currently being edited sensor during edits or deletion
    edited_sensor: {
      _id: "",
      name: "",
      current_status: "",
      description: "",
      location: "",
      operating_time: 0,
      implementation_date: new Date(),
      mqtt_pretopic: "",
      current_reading: 0,
      operating_range_max: 0,
      operating_range_min: 0,
      optimal_operating_range_max: 0,
      optimal_operating_range_min: 0,
      unit: "",
      type: "",
    },

    // Standard Sensor data used to reset edited sensor
    standard_sensor: {
      _id: "",
      name: "",
      current_status: "",
      description: "",
      location: "",
      operating_time: 0,
      implementation_date: new Date(),
      mqtt_pretopic: "",
      current_reading: 0,
      type: "",
      operating_range_max: 0,
      operating_range_min: 0,
      optimal_operating_range_max: 0,
      optimal_operating_range_min: 0,
      unit: "",
    },

    // Holds the Header data of the sensor data table
    sensor_data_header: [
      { text: "Name:", value: "name" },
      { text: "Typ", value: "type" },
      { text: "Einheit", value: "unit" },
      { text: "Status:", value: "current_status" },
      { text: "Zugeordnet:", value: "assigned" },
      { text: "Standort:", value: "location" },
      { text: "Laufzeit (h):", value: "operating_time" },
      { text: "Start Datum:", value: "implementation_date" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions(["deleteSensor", "editSensor"]),

    /**
     * Title: deleteSensor()
     * Description: opens the confirmation dialog when a sensor is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} sensor the machien that is supposed to be deleted
     *
     */
    confirmDeleteSensor(sensor) {
      this.display_delete_confirmation_dialog = true;
      this.edited_sensor_index = this.allSensors.indexOf(sensor);
      Object.assign(this.edited_sensor, sensor);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_sensor and edited_sensor_index variable after the deletion of a sensor has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_role, this.standard_sensor);
        this.edited_sensor_index = -1;
      });
    },

    /**
     * Title: deleteSensorConfirmed()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteSensorConfirmed() {
      this.deleteSensor(this.edited_sensor);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a sensor is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    confirmSelection(mode, selection_id) {
      if (mode === "delete") {
        const sensor = this.allSensors.find(
          (sensorall) => sensorall._id === selection_id
        );
        Object.assign(this.edited_sensor, sensor);
        this.confirmDeleteSensor(sensor);
      } else if (mode === "edit") {
        const sensor = this.allSensors.find(
          (sensorall) => sensorall._id === selection_id
        );
        Object.assign(this.edited_sensor, sensor);
        this.display_edit_sensor_dialog = true;
      }

      // Reset Selection data
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditSensorDialog()
     * Description: opens the Edit Sensor Dialog and sets the edited_sensor and edited_sensor_index according to the sensor selected by the user
     *
     * @param {Sensor} sensor the sensor that is beeing edited
     *
     */
    openEditSensorDialog(sensor) {
      this.edited_sensor_index = this.allSensors.indexOf(sensor);
      Object.assign(this.edited_sensor, sensor);
      this.display_edit_sensor_dialog = true;
    },

    /**
     * Title: closeEditSensorDialog()
     * Description: closes the Edit Sensor Dialog and resets the edited_sensor and edited_sensor_index
     *
     */
    closeEditSensorDialog() {
      this.display_edit_sensor_dialog = false;
      this.edited_sensor_index = -1;
      this.sensor_data_table_key = this.sensor_data_table_key + 1;
      Object.assign(this.edited_sensor, this.standard_sensor);
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a sensor is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_sensor, this.standard_sensor);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Sensor";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Sensor zum Bearbeiten auswählen:";
        this.selection_data = this.allSensors;
      } else if (selection_mode === "delete") {
        this.selection_title = "Sensor zum Löschen auswählen:";
        this.selection_data = this.allSensors;
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: invertFavored()
     * Description: inverts the favored status of a sensor
     *
     * @param {Stirng} sensor_id the sensor which is beeing edited
     *
     */
    async invertFavoured(sensor) {
      this.edited_sensor_index = this.allSensors.indexOf(sensor);
      Object.assign(this.edited_sensor, sensor);
      this.edited_sensor.favored = !this.edited_sensor.favored;
      await this.editSensor({
        sensor: this.edited_sensor,
        sensor_index: this.edited_sensor_index,
      });
      Object.assign(this.edited_sensor, this.standard_sensor);
      this.edited_sensor_index = -1;
      this.sensor_data_table_key = this.sensor_data_table_key + 1;
    },
  },
};
</script>
