<!-- 
    Title: Function Card
  
    Description: Used to Display Functiongroups within the Homepages of the different centers
-->
<template>
  <v-card tile height="100%" elevation="5">
    <!-- Title/Main Function -->
    <v-card-title class="title_hover" @click="onNewNavigationFunction(id, 0)">
      <v-icon class="ml-6 mr-3">{{ icon }}</v-icon
      >{{ title }}
    </v-card-title>
    <v-divider></v-divider>

    <!-- Subfunctions  -->
    <v-list>
      <v-list-item v-for="subfunction in subfunctions" :key="subfunction.id">
        <v-btn
          plain
          x-large
          tile
          @click="onNewNavigationFunction(id, subfunction.id)"
        >
          <v-icon class="mr-4">{{ subfunction.icon }}</v-icon
          >{{ subfunction.name }}</v-btn
        >
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "FunctionCard",

  props: {
    id: Number, // Function ID
    title: String, // Funciton Title
    icon: String, // Function Icon
    subfunctions: [], // Subfunction
  },

  methods: {
    /**
     * Title: onNewQuickFunction()
     * Description: is run when a new Function is selected by the user.
     *
     * @param {Number} nav_function_id the new active function, transmitted by the FunctionCard
     * @param {Number} subfuntion_id the new active subfunction, 0 if only nav_function change
     *
     */
    onNewNavigationFunction(nav_function_id, subfunction_id) {
      this.$emit("newNavigationFunction", nav_function_id, subfunction_id);
    },
  },
};
</script>

<style scoped>
/* Style for the Hover Effect of the main Function */
.title_hover:hover {
  background-color: #eceff1;
  cursor: pointer;
}
</style>
>
