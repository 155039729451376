import { Assembly } from "@/types/assembly";

export default {
  methods: {

    /* Title: getAssemblyStatusText
     * 
     * Description: Returns a String representing the Status of an Assembly
     *
     */
    getAssemblyStatusText(assembly_status: number) {
      if (assembly_status === 0) {
        return "Abgeschlossen";
      } else {
        return "In Bearbeitung";
      }
    },

    /* Title: getAssemblyStatusColor
     * 
     * Description: Returns a Color-String representing the Status of an Assembly
     *
     */
    getAssemblyStatusColor(assembly_status: number) {
      if (assembly_status === 0) {
        return "green";
      } else {
        return "grey";
      }
    },

    /* Title: getAssemblyProgress
     * 
     * Description: Returns a the progress percentage of an assembly based on the number of finished subassemblies
     *
     */
    getAssemblyProgess(assembly: Assembly) {
      if (assembly.status === 0) {
        return 100;
      } else {
        const subassemblies = assembly.subassemblies.length + 1;
        const unfinished_subassemblies = assembly.status;
        const finished_subassemblies = subassemblies - unfinished_subassemblies;
        if (subassemblies === unfinished_subassemblies) {
          return 0;
        } else {
          const progress = (finished_subassemblies / subassemblies) * 100;
          return progress.toFixed(0)
        }
      }
    },
  },
};
