<!-- 
    Title: NewSimSensorDialog
  
    Description: Dialog for adding a new Sensor to Simulations
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Neue Sensor hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Type Selection -->
            <v-row>
              <v-col>
                <v-select
                  v-model="type"
                  :items="allSensorTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="location"
                  label="Standort"
                  :rules="[() => !!location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Operating Range Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="operating_range_max"
                  label="Absoluter max. Wert"
                  :rules="[
                    () => !!operating_range_max || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(operating_range_max)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="operating_range_min"
                  label="Absoluter min. Wert"
                  :rules="[
                    () => !!operating_range_min || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(operating_range_min)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="optimal_operating_range_max"
                  label="Optimaler max. Wert"
                  :rules="[
                    () => !!optimal_operating_range_max || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(optimal_operating_range_max)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="optimal_operating_range_min"
                  label="Optimaler min. Wert"
                  :rules="[
                    () => !!optimal_operating_range_min || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(optimal_operating_range_min)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewSimSensorDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Sve Button -->
        <v-btn
          color="green"
          text
          @click="saveSimSensor"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewSimSensorDialog",

  props: {
    display: Boolean, // Determines weather the dialog is displayed
  },

  data: () => ({
    // Data of the new sim_sensor
    name: "",
    description: "",
    location: "",
    operating_range_max: "",
    operating_range_min: "",
    optimal_operating_range_max: "",
    optimal_operating_range_min: "",
    type: "",
    form_validation: false,
  }),

  computed: {
    ...mapGetters(["allSensorTypes"]),
  },

  methods: {
    ...mapActions(["newSimSensor"]),

    /**
     * Title: saveSimSensor()
     * Description: saves the new SimSensor by calling the vuex Method
     *
     */
    async saveSimSensor() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        await this.newSimSensor({
          name: this.name,
          description: this.description,
          location: this.location,
          type: this.type,
          operating_range_max: this.operating_range_max,
          operating_range_min: this.operating_range_min,
          optimal_operating_range_max: this.optimal_operating_range_max,
          optimal_operating_range_min: this.optimal_operating_range_min,
        });
        this.$emit("closeNewSimSensorDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
