<!-- 
    Title: EditAssemblyTypeDialog
  
    Description: Dialog for editing assembly type data
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Baugruppentyp bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_assemblytype._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_assemblytype.name"
                  label="Name"
                  :rules="[() => !!edited_assemblytype.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Average Duration Input -->
              <v-col>
                <v-text-field
                  v-model="edited_assemblytype.average_duration"
                  label="Dauer (ms)"
                  :rules="[
                    () =>
                      !!edited_assemblytype.average_duration || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(edited_assemblytype.average_duration)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Discription Input -->
              <v-col>
                <v-textarea
                  v-model="edited_assemblytype.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_assemblytype.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Machine Selector -->
            <v-row>
              <v-col>
                <v-select
                  :items="allMachineTypes"
                  item-text="name"
                  item-value="_id"
                  v-model="edited_assemblytype.machine_type"
                  :label="'Maschinentyp'"
                  :rules="[
                    () => !!edited_assemblytype.machine_type || 'Pflichtfeld',
                  ]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditAssemblyTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AssemblyType from "../../../../types/assemblyType";
export default {
  name: "EditAssemblyTypeDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_assemblytype: AssemblyType, // the assemblytype that is beeing edited
    edited_assemblytype_index: Number, // the index of the edited assemblytype within the vuex State
  },

  computed: {
    ...mapGetters(["allAssemblyTypes", "allMachineTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the input form
  }),

  methods: {
    ...mapActions(["editAssemblyType"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited assemblytype by calling the vuex Method
     *
     */
    saveEdit() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.editAssemblyType({
          assembly_type: this.edited_assemblytype,
          assembly_type_index: this.edited_assemblytype_index,
        });
        this.$emit("closeEditAssemblyTypeDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
