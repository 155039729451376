var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Rollenverwaltung")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_role_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Rolle hinzufügen")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('edit')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_vm._v("Rolle bearbeiten")],1),_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){return _vm.setupSelection('delete')}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.delete))]),_vm._v("Rolle löschen")],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.role_search),callback:function ($$v) {_vm.role_search=$$v},expression:"role_search"}})],1),_c('v-data-table',{key:_vm.role_data_table_key,attrs:{"headers":_vm.role_data_header,"items":_vm.allRoles,"search":_vm.role_search,"expanded":_vm.expanded_role,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_role=$event}},scopedSlots:_vm._u([{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.creation_date))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificUser(item.created_by).login)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditRoleDialog(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.edit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteRole(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Rollen ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)]}}])})],1),[_c('DeleteConfirmationDialog',{attrs:{"title":'Soll die gewählte Rolle gelöscht werden?',"object_name":_vm.edited_role.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDeleteEdit,"confirmDelete":_vm.deleteRoleConfirmed}}),_c('SelectionDialog',{attrs:{"selection_data":_vm.selection_data,"mode":_vm.selection_mode,"display":_vm.display_select_dialog,"title":_vm.selection_title,"label":_vm.selection_label},on:{"cancelSelection":_vm.cancelSelection,"confirmSelection":_vm.confirmSelection}}),_c('NewRoleDialog',{attrs:{"display":_vm.display_new_role_dialog},on:{"closeNewRoleDialog":function($event){_vm.display_new_role_dialog = false}}}),_c('EditRoleDialog',{attrs:{"display":_vm.display_edit_role_dialog,"edited_role":_vm.edited_role,"edited_role_index":_vm.edited_role_index},on:{"closeEditRoleDialog":_vm.closeEditRoleDialog}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }