import { MachineGroupStatus } from "../types/machineGroup";

export default {
  methods: {
    /* Title: getmachinegroupStatusColor
     *
     * Description: Returns a Color-String representing the Status of an machinegroup
     *
     */
    getMachineGroupStatusText(machinegroup_status: MachineGroupStatus) {
      switch (machinegroup_status) {
        case 0:
          return "Aus";
        case 1:
          return "Aktiviert";
        default:
          break;
      }
    },

    /* Title: getmachinegroupProgress
     *
     * Description: Returns a the progress percentage of an machinegroup based on the number of finished subassemblies
     *
     */
    getMachineGroupStatusColor(machinegroup_status: MachineGroupStatus) {
      switch (machinegroup_status) {
        case 0:
          return "grey";
        case 1:
          return "green";
        default:
          return "black";
      }
    },
  },
};
