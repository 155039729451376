<!-- 
    Title: ErrorHistoryCard
  
    Description: Table used to display the error history of a sensor
-->

<template>
  <v-card tile elevation="5" height="100%">
    <!-- Title -->
    <v-card-title> Fehlerhistorie </v-card-title>

    <!-- Text if there is no error history -->
    <v-card-text v-if="!error_history || error_history.length == 0">
      Keine Fehler vorhanden!
    </v-card-text>

    <!-- Error Table -->
    <v-card-text v-if="error_history && error_history.length > 0">
      <v-data-table
        :headers="headers"
        :items="error_history"
        item-key="time"
        :items-per-page="5"
      >
        <!-- Template for time slot -->
        <template v-slot:item.time="{ item }">
          {{ new Date(item.time).toLocaleString() }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ErrorHistoryCard",

  props: {
    error_history: [], // the error history
  },

  data: () => ({
    // table header
    headers: [
      { text: "Fehlertyp:", value: "errorType" },
      { text: "Zeitpunkt:", value: "time" },
    ],
  }),
};
</script>
