<!-- 
    Title: NewMaterialTypeDialog
  
    Description: Dialog for creaton  of new materialtypes
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <span class="text-h5">Neuen Materialtyp hinzufügen:</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Icon Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="icon"
                  label="Icon"
                  :rules="[() => !!icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Cancel Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewMaterialTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Close Button -->
        <v-btn
          color="green"
          text
          @click="saveMaterialType"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewMaterialTypeDialog",

  props: {
    display: Boolean, // Determines weather the dialog is displayed
  },

  data: () => ({
    // Data of the new MaterialType
    name: "",
    description: "",
    icon: "",
    form_validation: false,
  }),

  methods: {
    ...mapActions(["newMaterialType"]),

    /**
     * Title: saveMaterialType()
     * Description: saves the new MaterialType by calling the vuex Method
     *
     */
    saveMaterialType() {
      this.form_validation = this.$refs.form.validate();
      if (this.form_validation === true) {
        this.newMaterialType({
          name: this.name,
          description: this.description,
          icon: this.icon,
        });
        this.$emit("closeNewMaterialTypeDialog");
        this.$refs.form.reset();
      }
    },
  },
};
</script>
