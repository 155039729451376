<!-- 
    Title: Edit User Dialog

    Description: Dialog for editing data of a specific user
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Nutzer bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_user._id"
                  label="ID"
                  disabled
                  required
                ></v-text-field>
              </v-col>

              <!-- Login Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_user.login"
                  label="login"
                  disabled
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- First Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_user.first_name"
                  label="Vorname"
                  :rules="[() => !!edited_user.first_name || 'Pflichtfeld']"
                  required
                ></v-text-field>
              </v-col>

              <!-- last Name INput -->
              <v-col>
                <v-text-field
                  v-model="edited_user.last_name"
                  label="Nachname"
                  :rules="[() => !!edited_user.last_name || 'Pflichtfeld']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Role Selector -->
              <v-col>
                <v-select
                  v-model="edited_user.role"
                  :items="allRoles"
                  :menu-props="{ maxHeight: '400' }"
                  label="Rolle"
                  item-text="name"
                  item-value="_id"
                  required
                ></v-select>
              </v-col>

              <!-- Status Selector -->
              <v-col>
                <v-select
                  v-model="edited_user.status"
                  :items="user_status"
                  :menu-props="{ maxHeight: '400' }"
                  label="Status"
                  item-text="name"
                  item-value="id"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditUserDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import helperMixins from "../../../../mixins/helperMixins";
import User from "../../../../types/user";
export default {
  name: "EditUserDialog", // Component Name

  mixins: [helperMixins],

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_user: User, // the edited User
    edited_user_index: Number, // the index of the edited User within the vuex State
  },

  computed: {
    ...mapGetters(["allUsers", "allRoles"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of the input Form

    // Possible user Status for Status Select
    user_status: [
      {
        id: 0,
        name: "Gesperrt",
      },
      {
        id: 1,
        name: "Aktiv",
      },
    ],
  }),

  methods: {
    ...mapActions(["editUser"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited user by calling the vuex Method
     *
     */
    async saveEdit() {
      // Validate Input
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to save
        await this.editUser({
          user: this.edited_user,
          user_index: this.edited_user_index,
        });

        // Emit Dialog Close Event to Parent Component
        this.$emit("closeEditUserDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
