<!-- 
    Title: EditMaintenanceTypeDialog
  
    Description: Dialog for editing data of a specific maintenance type
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>

      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Wartungstyp bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>

              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_maintenancetype._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_maintenancetype.name"
                  label="Name"
                  :rules="[() => !!edited_maintenancetype.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>

              <!-- Icon Input -->
              <v-col>
                <v-text-field
                  v-model="edited_maintenancetype.icon"
                  label="Icon"
                  :rules="[() => !!edited_maintenancetype.icon || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_maintenancetype.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[
                    () => !!edited_maintenancetype.description || 'Pflichtfeld',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Dialog Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditMaintenanceTypeDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MaintenanceType from "../../../../types/maintenanceType";
export default {
  name: "EditMaintenanceTypeDialog", // Component Name

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_maintenancetype: MaintenanceType, // the maintenancetype that is beeing edited
    edited_maintenancetype_index: Number, // the index of the edited maintenancetype within the vuex State
  },

  computed: {
    ...mapGetters(["allMaintenanceTypes"]),
  },

  data: () => ({
    form_validation: false, // Validation Data of Input Form
  }),

  methods: {
    ...mapActions(["editMaintenanceType"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited maintenancetype by calling the vuex Method
     *
     */
    async saveEdit() {

      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      //Check Validation
      if (this.form_validation === true) {

        // Call Vuex Method to save
        await this.editMaintenanceType({
          maintenance_type: this.edited_maintenancetype,
          maintenance_type_index: this.edited_maintenancetype_index,
        });

        // Emit Close Dialog Event to parent
        this.$emit("closeEditMaintenanceTypeDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
