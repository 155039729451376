var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"5"}},[_c('v-card-title',[_vm._v("Sensoren")]),_c('v-data-table',{attrs:{"headers":_vm.sensor_data_header,"items":_vm.sensors,"expanded":_vm.expanded_sensor,"item-key":"_id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_sensor=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.specificSensorType(item.type).icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.current_reading",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getSensorValueColor(item),"dark":""}},[_vm._v(" "+_vm._s(_vm.getSensorValueString(item))+" ")])]}},{key:"item.current_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getSensorStatusColor(item.current_status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getSensorStatusText(item.current_status))+" ")])]}},{key:"item.operating_range_max",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.operating_range_max + " " + _vm.specificSensorType(item.type).unit)+" ")]}},{key:"item.operating_range_min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.operating_range_min + " " + _vm.specificSensorType(item.type).unit)+" ")]}},{key:"item.optimal_operating_range_max",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.optimal_operating_range_max + " " + _vm.specificSensorType(item.type).unit)+" ")]}},{key:"item.optimal_operating_range_min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.optimal_operating_range_min + " " + _vm.specificSensorType(item.type).unit)+" ")]}},{key:"item.operating_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertMinutesToHoursMinutes(item.operating_time))+" ")]}},{key:"item.implementation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.implementation_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openSensorDetails(item._id)}}},[_vm._v(_vm._s(_vm.navigationIcons.information))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Sensor ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":"","dense":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Beschreibung:")]),_c('v-textarea',{attrs:{"readonly":"","outlined":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }