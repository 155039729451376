<!-- 
    Title: SensorInfoCard
  
    Description: Displays detailed informaiton on a sensor
-->
<template>
  <v-card tile height="100%" elevation="5">
    <!-- Title -->
    <v-card-title>Kurzinformationen</v-card-title>

    <!-- Data Table -->
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <!-- ID -->
          <tr>
            <td>ID:</td>
            <td>
              {{ sensor._id }}
            </td>
          </tr>

          <!-- Type -->
          <tr>
            <td>Typ:</td>
            <td>
              {{ specificSensorType(sensor.type).name }}
            </td>
          </tr>

          <!-- Status -->
          <tr>
            <td>Status:</td>
            <td>
              {{ getSensorStatusText(sensor.current_status) }}
            </td>
          </tr>

          <!-- Location -->
          <tr>
            <td>Standort:</td>
            <td>
              {{ sensor.location }}
            </td>
          </tr>

          <!-- Implementation Date -->
          <tr>
            <td>Inbetriebnahme:</td>
            <td>
              {{ new Date(sensor.implementation_date).toLocaleString() }}
            </td>
          </tr>

          <!-- Operaing Time -->
          <tr>
            <td>Betriebszeit:</td>
            <td>{{ convertMinutesToHoursMinutes(sensor.operating_time) }}</td>
          </tr>

          <!-- Operating Range -->
          <tr>
            <td>Betriebsbereich:</td>
            <td>
              {{
                sensor.operating_range_min +
                specificSensorType(sensor.type).unit +
                " bis " +
                sensor.operating_range_max +
                specificSensorType(sensor.type).unit
              }}
            </td>
          </tr>

          <!-- Optimal Operating Range -->
          <tr>
            <td>Optimaler Betriebsbereich:</td>
            <td>
              {{
                sensor.optimal_operating_range_min +
                specificSensorType(sensor.type).unit +
                " bis " +
                sensor.optimal_operating_range_max +
                specificSensorType(sensor.type).unit
              }}
            </td>
          </tr>

          <!-- Description -->
          <tr>
            <td>Beschreibung:</td>
            <td>{{ specificSensor(overviewActiveElementId).description }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import sensorMixins from "../../../mixins/sensorMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "SensorInfoCard",
  mixins: [sensorMixins, helperMixins],

  props: {
    sensor: {}, // the data of the sensor
  },

  computed: {
    ...mapGetters(["overviewActiveElementId", "specificSensor"]),
  },
};
</script>
