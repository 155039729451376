<!-- 
    Title: SensorOvervie
  
    Description: Gives a complete overview of a specific sensor
-->
<template>
  <v-container>
    <v-row>
      <v-col class="d-flex">
        <!-- Title/Name -->
        <h1>{{ specificSensor(overviewActiveElementId).name }}</h1>
        <v-spacer></v-spacer>

        <!-- On/OFf Switch -->
        <v-switch
          right
          v-model="sensorOnOff"
          color="success"
          @click="turnSensorOnOff"
        ></v-switch>
      </v-col>
    </v-row>

    <!-- KPIs -->
    <v-row>
      <v-col cols="4">
        <KPICard
          :kpi_title="'Durchschnittlicher Wert'"
          :kpi_value="averageReading"
          :kpi_unit="specificSensorType(sensor.type).unit"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="4">
        <KPICard
          :kpi_title="'Nächste Wartung in'"
          :kpi_value="next_maintenance"
          :kpi_unit="'Betriebsstunden'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="4">
        <KPICard
          :kpi_title="'Letzte Störung'"
          :kpi_value="last_error_date"
          :kpi_unit="getSensorErrorText(last_error_type)"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
    </v-row>

    <!-- Sensor Info, Gauge and Brush Chart -->
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col>
            <SensorInfoCard :sensor="sensor" />
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col>
            <SensorGaugeCardSolo :sensor="sensor" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <SensorBrushChart :historical_data="historical_data" />
      </v-col>
    </v-row>

    <!-- Maintenanceplan -->
    <v-row>
      <v-col>
        <MaintenancePlanCard
          :maintenance_plan="specificSensorType(sensor.type).maintenance_plan"
        />
      </v-col>
    </v-row>

    <!-- Maintenance History -->
    <v-row>
      <v-col>
        <MaintenanceHistoryCard
          :component_id="sensor._id"
          :component_type="'sensor'"
          :maintenance_history="sensor.maintenance_history"
        />
      </v-col>
    </v-row>

    <!-- Error History -->
    <v-row>
      <v-col>
        <ErrorHistoryCard :error_history="sensor.error_history" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SensorGaugeCardSolo from "../sensor/SensorGaugeCardSolo.vue";
import SensorBrushChart from "./SensorBrushChart.vue";
import SensorInfoCard from "./SensorInfoCard.vue";
import MaintenancePlanCard from "../general/MaintenancePlanCard.vue";
import MaintenanceHistoryCard from "../general/MaintenanceHistoryCard.vue";
import ErrorHistoryCard from "../general/ErrorHistoryCard.vue";
import { mapActions, mapGetters } from "vuex";
import { getHistoricalSensorReadings } from "../../../services/sensorService";
import KPICard from "../general/KPICard.vue";
import sensorMixins from "../../../mixins/sensorMixins";

export default {
  name: "SensorOverview",

  mixins: [sensorMixins],

  components: {
    SensorGaugeCardSolo,
    SensorBrushChart,
    SensorInfoCard,
    MaintenancePlanCard,
    MaintenanceHistoryCard,
    ErrorHistoryCard,
    KPICard,
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "specificSensor",
      "specificSensorType",
    ]),

    // Returns the complete sensor data
    sensor: function () {
      return this.specificSensor(this.overviewActiveElementId);
    },

    // Returns the status of the on/off switch
    sensorOnOff: {
      get() {
        if (this.sensor.current_status === 0) {
          return false;
        } else {
          return true;
        }
      },
      set(newValue) {
        return newValue;
      },
    },

    // Returns the next maintenance
    next_maintenance: function () {
      let time_till_next_is_due = 0;
      let hours_till_maintenance = 0;
      this.specificSensorType(this.sensor.type).maintenance_plan.forEach(
        (maintenance) => {
          if (this.sensor.operating_time / 60 < maintenance.interval / 60) {
            hours_till_maintenance =
              maintenance.interval / 60 - this.sensor.operating_time / 60;
          } else {
            hours_till_maintenance =
              (this.sensor.operating_time / 60) % (maintenance.interval / 60);
          }
          if (time_till_next_is_due === 0) {
            time_till_next_is_due = hours_till_maintenance;
          } else if (hours_till_maintenance < time_till_next_is_due) {
            time_till_next_is_due = hours_till_maintenance;
          }
        }
      );
      return time_till_next_is_due.toFixed(0);
    },

    // Returns the date of the last error
    last_error_date: function () {
      if (this.sensor.error_history && this.sensor.error_history.length > 0) {
        return new Date(this.sensor.error_history[0].time).toLocaleString();
      } else {
        return "-";
      }
    },

    // Return the type of the last error
    last_error_type: function () {
      if (this.sensor.error_history && this.sensor.error_history.length > 0) {
        return this.sensor.error_history[0].errorType;
      } else {
        return "-";
      }
    },

    // Returns the average reading
    averageReading: function () {
      let average_reading = 0;
      this.historical_data.forEach((reading) => {
        average_reading = average_reading + reading.y;
      });
      return (average_reading / this.historical_data.length).toFixed(2);
    },
  },

  mounted() {
    this.getHistoricalData();
  },

  watch: {
    // Watches the currently selected sensor and gets its historical readings
    overviewActiveElementId: function () {
      this.getHistoricalData();
    },
  },

  methods: {
    ...mapActions(["startSensor", "stopSensor"]),

    // gets the historical data of a sensor
    getHistoricalData() {
      this.historical_data = [];
      const promise = getHistoricalSensorReadings(this.sensor._id).then(
        (result) => {
          let data = [];
          result.forEach((res) => {
            //const time = res.time.slice(0, -1) + "-02:00";
            let reading = {
              x: new Date(res.time),
              y: res.value,
            };
            this.historical_data.push(reading);
          });
          return data;
        }
      );
    },

    // turns a sensor on or off
    turnSensorOnOff() {
      if (this.sensorOnOff === true) {
        this.stopSensor({ sensor_id: this.sensor._id });
      } else {
        this.startSensor({ sensor_id: this.sensor._id });
      }
    },
  },

  data: () => ({
    historical_data: [], // the historical readings
  }),
};
</script>
