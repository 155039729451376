<!-- 
    Title: NewRoleDialog
  
    Description: Dialog for adding a new role
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neue Rolle hinzufügen:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- Name Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[() => !!name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Checkboxes for Permission Selection -->
            <v-row>
              <v-col>
                <h3>Berechtigungen:</h3>

                <!-- Admin Checkbox -->
                <v-checkbox
                  v-model="rights.admin"
                  label="Admin"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Production Checkbox -->
                <v-checkbox
                  v-model="rights.production"
                  label="Produktion"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Hierarchy Checkboy -->
                <v-checkbox
                  v-model="rights.hierachy"
                  label="Hierachie"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Maintenance Checkbox -->
                <v-checkbox
                  v-model="rights.maintenance"
                  label="Wartung"
                  class="mb-n6"
                ></v-checkbox>

                <!-- Simulation Checkbox -->
                <v-checkbox
                  v-model="rights.simulation"
                  label="Simulation"
                  class="mb-n6"
                ></v-checkbox>
                <h3 class="mt-8">Hierachisches Level:</h3>

                <!-- Selector for hierarchy level -->
                <v-select
                  v-model="rights.hierachy_level"
                  :items="hierachy_levels"
                  :rules="[() => !!rights.hierachy_level || 'Pflichtfeld']"
                  label="Hierachisches Level"
                  item-text="name"
                  item-value="id"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeNewRoleDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveRole"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewRoleDialog", // Component Name

  props: {
    display: Boolean, // Determines weather the Dialo is beeing displayed
  },

  data: () => ({
    name: "", // Name of the new role
    description: "", // Description og the new Role
    form_validation: false, // Validation Data of Input Form

    //Determines the rights associated with a role. if changed edit closeDialog() aswell
    rights: {
      admin: false,
      production: false,
      reports: false,
      hierachy: false,
      maintenance: false,
    },

    // Possible Hierarchy Levels for selector
    hierachy_levels: [
      { id: 1, name: "Alle" },
      { id: 2, name: "Fabrik" },
      { id: 3, name: "Maschinengruppe" },
      { id: 4, name: "Maschine" },
      { id: 5, name: "Sensor" },
    ],
  }),

  methods: {
    ...mapActions(["newRole"]),

    /**
     * Title: saveRole()
     * Description: saves the new Role by calling the vuex Method
     *
     */
    saveRole() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to save
        this.newRole({
          name: this.name,
          description: this.description,
          rights: this.rights,
        });

        // Emit Close Event to Parent Component
        this.$emit("closeNewRoleDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
