<!-- 
    Title: FactoryInfoCard
  
    Description: Displays a table with information about a specific factory
-->
<template>
  <v-card elevation="5" tile height="100%">
    <v-card-title>{{ title }}</v-card-title>
    <v-simple-table dense>
      <tbody>
        <!-- Factory ID -->
        <tr>
          <td>ID:</td>
          <td>
            {{ factory._id }}
          </td>
        </tr>

        <!-- Factory Utilization -->
        <tr>
          <td>Auslastung:</td>
          <td>{{ (factory.current_status * 100).toFixed(0) }}%</td>
        </tr>

        <!-- Factory Location -->
        <tr>
          <td>Standort:</td>
          <td>{{ factory.location }}</td>
        </tr>

        <!-- Factory Runtime -->
        <tr>
          <td>Betriebszeit:</td>
          <td>
            {{ convertMinutesToHoursMinutes(factory.operating_time) }}
          </td>
        </tr>

        <!-- Number of machinegroups within factory -->
        <tr>
          <td>Maschinengruppen:</td>
          <td>
            {{ factory.components.machine_groups.length }}
          </td>
        </tr>

        <!-- Number of sensors within factory -->
        <tr>
          <td>Sensoren:</td>
          <td>
            {{ factory.components.sensors.length }}
          </td>
        </tr>

        <!-- IMplementation DAta  -->
        <tr>
          <td>Erstellt am:</td>
          <td>
            {{ new Date(factory.implementation_date).toLocaleString() }}
          </td>
        </tr>

        <!-- Description -->
        <tr>
          <td>Beschreibung:</td>
          <td>
            {{ factory.description }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import factoryMixins from "../../../mixins/factoryMixins";
import helperMixins from "../../../mixins/helperMixins";

export default {
  name: "FactoryInfoCard",
  mixins: [factoryMixins, helperMixins],

  props: {
    factory: {}, // the Factory that is displayed
    title: String, // the title of the card
  },

  computed: {
    ...mapGetters(["overviewActiveElementId", "specificFactory"]),
  },
};
</script>
