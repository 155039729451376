var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mt-2 mb-5"},[_vm._v("Produktionsaufträge")]),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"5","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"plain":"","tile":""},on:{"click":function($event){_vm.display_new_productionorder_dialog = true}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.navigationIcons.add))]),_vm._v("Produktionsauftrag hinzufügen")],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.navigationIcons.search,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.productionorder_search),callback:function ($$v) {_vm.productionorder_search=$$v},expression:"productionorder_search"}})],1),_c('v-data-table',{key:_vm.productionorder_data_table_key,attrs:{"headers":_vm.productionorder_data_header,"items":_vm.allProductionOrders,"search":_vm.productionorder_search,"item-key":"_id","show-expand":""},scopedSlots:_vm._u([{key:"item.assemblies",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificAssemblyType(_vm.specificAssembly(item.assemblies[0]).type).name)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assemblies.length)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getProductionOrderStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getProductionOrderStatusText(item.status))+" ")])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.specificUser(item.created_by).login)+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":"blue","value":_vm.getProductionOrderProgess(item),"height":"25"}},[_vm._v(_vm._s(_vm.getProductionOrderProgess(item).toFixed(0))+"%")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateToString(item.creation_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteProductionOrder(item)}}},[_vm._v(_vm._s(_vm.navigationIcons.delete))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Auftrags ID:")]),_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_c('h4',{staticClass:"mb-3 mt-12"},[_vm._v("Baugruppen:")]),_c('v-simple-table',{staticClass:"mb-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Typ")]),_c('th',[_vm._v("Fortschritt")])])]),_c('tbody',_vm._l((item.assemblies),function(assembly){return _c('tr',{key:assembly},[_c('td',[_vm._v(_vm._s(assembly))]),_c('td',[_c('v-chip',{attrs:{"color":_vm.getAssemblyStatusColor(
                          _vm.specificAssembly(assembly).status
                        ),"dark":""}},[_vm._v(" "+_vm._s(_vm.getAssemblyStatusText( _vm.specificAssembly(assembly).status ))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.specificAssemblyType(_vm.specificAssembly(assembly).type) .name)+" ")]),_c('td',[_c('v-progress-linear',{attrs:{"color":"blue","value":_vm.getAssemblyProgess(_vm.specificAssembly(assembly)),"height":"25"}},[_vm._v(_vm._s(_vm.getAssemblyProgess(_vm.specificAssembly(assembly)))+"%")])],1)])}),0)])],1)],1)]}}])})],1),[_c('NewProductionOrderDialog',{attrs:{"display":_vm.display_new_productionorder_dialog},on:{"closeNewProductionOrderDialog":function($event){_vm.display_new_productionorder_dialog = false}}}),_c('DeleteConfirmationDialog',{attrs:{"title":'Soll der gewählte Produktionsauftrag gelöscht werden?',"object_name":_vm.edited_productionorder.name,"display":_vm.display_delete_confirmation_dialog},on:{"cancelDelete":_vm.cancelDeleteEdit,"confirmDelete":_vm.deleteProductionOrderConfirmed}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }