<!-- 
    Title: Production AssmeblyTypes
  
    Description: Page to manage assemblytypes
-->
<template>
  <v-container>
    <h1 class="mt-2 mb-5">Baugruppentypenverwaltung</h1>
    <!-- Card that holds function btns and user data table -->
    <v-card class="mt-5" elevation="5" tile>
      <!-- Function Buttons -->
      <v-card-actions>
        <v-btn plain tile @click="display_new_assemblytype_dialog = true"
          ><v-icon class="mr-4">{{ navigationIcons.add }}</v-icon
          >Baugruppentyp hinzufügen</v-btn
        >
        <v-btn plain tile @click="setupSelection('edit')"
          ><v-icon class="mr-4">{{ navigationIcons.edit }}</v-icon
          >Baugruppentyp bearbeiten</v-btn
        >
        <v-btn plain tile @click="setupSelection('delete')"
          ><v-icon class="mr-4">{{ navigationIcons.delete }}</v-icon
          >Baugruppentyp löschen</v-btn
        >
      </v-card-actions>

      <!-- Search Field for searching within the user data table  -->
      <v-card-title>
        <v-text-field
          v-model="assemblytype_search"
          :append-icon="navigationIcons.search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- table for displaying assemblytype data  -->
      <v-data-table
        :headers="assemblytype_data_header"
        :items="allAssemblyTypes"
        :search="assemblytype_search"
        :expanded.sync="expanded_assemblytype"
        :key="assemblytype_data_table_key"
        item-key="_id"
        show-expand
      >
        <!-- Template for converting Operating Time -->
        <template v-slot:item.average_duration="{ item }">
          {{ convertMillisToMinutesString(item.average_duration) }} m
        </template>

        <!-- Template for converting Implementation Date -->
        <template v-slot:item.machine_type="{ item }">
          <v-icon>{{ specificMachineType(item.machine_type).icon }}</v-icon>
          {{ specificMachineType(item.machine_type).name }}
        </template>

        <!-- Template for function btns in each row -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              small
              class="mr-2"
              @click="openEditAssemblyTypeDialog(item)"
              >{{ navigationIcons.edit }}</v-icon
            >
            <v-icon
              small
              class="mr-2"
              @click="confirmDeleteAssemblyType(item)"
              >{{ navigationIcons.delete }}</v-icon
            >
          </div>
        </template>

        <!-- Template for AssemblyType Details -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <!-- ID -->
              <h4 class="mt-3 mb-3">Baugruppen ID:</h4>
              <v-text-field v-model="item._id" readonly outlined></v-text-field>

              <!-- Description -->
              <h4 class="mt-3 mb-3">Beschreibung:</h4>
              <v-textarea
                v-model="item.description"
                readonly
                outlined
              ></v-textarea>

              <!-- subassemblytypes -->
              <h4 class="mb-3">Unterbaugruppen:</h4>

              <!-- Button to add subassembly -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_assemblytype, item);
                  setupSelection('add_subassemblytype_to_assemblytype');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Subassembly Data Table -->
              <v-simple-table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Anzahl</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="subassembly in item.subassembly_types"
                    :key="subassembly"
                  >
                    <!-- Subassembly type -->
                    <td>{{ subassembly.subassembly_type }}</td>

                    <!-- Subassembly Name -->
                    <td>
                      {{
                        specificAssemblyType(subassembly.subassembly_type).name
                      }}
                    </td>

                    <!-- Subassembly amout -->
                    <td>
                      {{ subassembly.amount }}
                    </td>

                    <!-- Button to delete subassembly -->
                    <td>
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="
                          deleteSubAssemblyTypeFromAssemblyType(
                            item,
                            subassembly
                          )
                        "
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <!-- materialtypes -->
              <h4 class="mb-3 mt-12">Materialtypen:</h4>

              <!-- Button to add a materialtype -->
              <v-btn
                color="green"
                fab
                small
                absolute
                right
                class="mr-3 mt-n7"
                @click="
                  Object.assign(edited_assemblytype, item);
                  setupSelection('add_materialtype_to_assemblytype');
                "
                ><v-icon color="white">{{ navigationIcons.add }}</v-icon></v-btn
              >

              <!-- Materialtype data table -->
              <v-simple-table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Anzahl</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="materialtype in item.material_types"
                    :key="materialtype"
                  >
                    <!-- Mateiral ID -->
                    <td>{{ materialtype.material_type }}</td>

                    <!-- Material ICON and Name -->
                    <td>
                      <v-icon>{{
                        specificMaterialType(materialtype.material_type).icon
                      }}</v-icon>
                      {{
                        specificMaterialType(materialtype.material_type).name
                      }}
                    </td>

                    <!-- Material Amount -->
                    <td>
                      {{ materialtype.amount }}
                    </td>

                    <!-- Button to delte a materialtype -->
                    <td>
                      <v-btn
                        icon
                        x-small
                        absolute
                        right
                        class="mr-5 mt-n2 align-center"
                        @click="
                          deleteMaterialTypeFromAssemblyType(item, materialtype)
                        "
                        ><v-icon>{{ navigationIcons.remove }}</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <DeleteConfirmationDialog
        :title="'Soll der gewählte Baugruppentyp gelöscht werden?'"
        :object_name="edited_assemblytype.name"
        :display="display_delete_confirmation_dialog"
        @cancelDelete="cancelDeleteEdit"
        @confirmDelete="deleteAssemblyTypeConfirmed"
      />

      <SelectionDialog
        :selection_data="selection_data"
        :mode="selection_mode"
        :display="display_select_dialog"
        :display_amount="display_amount"
        :title="selection_title"
        :label="selection_label"
        @cancelSelection="cancelSelection"
        @confirmSelection="confirmSelection"
      />

      <NewAssemblyTypeDialog
        :display="display_new_assemblytype_dialog"
        @closeNewAssemblyTypeDialog="display_new_assemblytype_dialog = false"
      />

      <EditAssemblyTypeDialog
        :display="display_edit_assemblytype_dialog"
        :edited_assemblytype="edited_assemblytype"
        :edited_assemblytype_index="edited_assemblytype_index"
        @closeEditAssemblyTypeDialog="closeEditAssemblyTypeDialog"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// Import of Mixins
import helperMixins from "../../../mixins/helperMixins";

// Import of Components
import DeleteConfirmationDialog from "../../general/DeleteConfirmationDialog.vue";
import SelectionDialog from "../../general/SelectionDialog.vue";
import NewAssemblyTypeDialog from "../components/ProductionAssemblyTypes/NewAssemblyTypeDialog.vue";
import EditAssemblyTypeDialog from "../components/ProductionAssemblyTypes/EditAssemblyTypeDialog.vue";

// Import of Types
import AssemblyType from "../../../types/assemblyType";

export default {
  name: "ProductionAssemblyType",
  mixins: [helperMixins],

  components: {
    DeleteConfirmationDialog,
    SelectionDialog,
    NewAssemblyTypeDialog,
    EditAssemblyTypeDialog,
  },

  computed: {
    ...mapGetters([
      "allAssemblyTypes",
      "navigationIcons",
      "specificAssemblyType",
      "allMaterialTypes",
      "specificMaterialType",
      "specificMachineType",
    ]),
  },

  data: () => ({
    selection_mode: "", //Determines the mode in which the selection dialog is beeing opened. 'edit' / 'delete'
    selection_title: "", // the Title of the selection dialog
    selection_label: "", // the lable of v-select element of the selection dialog
    selection_data: [], // the data which can be selected by the selction dialog
    display_delete_confirmation_dialog: false, // Determines wether the delete confirmation dialog is beeing displayed
    display_select_dialog: false, // Determines wether the select dialog is beeing displayed
    display_new_assemblytype_dialog: false, // Determines wether the new assemblytype dialog is beeing displayed
    display_edit_assemblytype_dialog: false, // Determines wether the edit assemblytype dialog is beeing displayed
    display_amount: false, // Determines weather the amount input is displayed in selection dialogs
    selected_assemblytype_id: String, // Holds the id of the selected assemblytype. Is beeing determined by the selection dialog
    assemblytype_search: "", // Holds search data for the search bar of the assemblytype data table
    expanded_assemblytype: [], // synchronices the expanded items of the assemblytype data table
    edited_assemblytype_index: -1, // Holds the index of the assemblytype that is beeing edited. determined by the openEditAssemblyTypeDialog() funciton
    assemblytype_data_table_key: 0, //Holds the key of the assemblytype_data_table. Is needed to force vue to rerender the assemblytype data table as vue does not notice a change in the vuex assemblytype store after a assemblytype is deleted

    // Holds the currently being edited assemblytype during edits or deletion
    edited_assemblytype: {
      _id: "",
      name: "",
      average_duration: 0,
      description: "",
      machine_type: "",
      material_types: [],
      subassembly_types: [],
    },

    // Standard Assemblytype data used to reset edited_assemblytype
    standard_assemblytype: {
      _id: "",
      name: "",
      average_duration: 0,
      description: "",
      machine_type: "",
      material_types: [],
      subassembly_types: [],
    },

    // Holds the Header data of the assemblytype data table
    assemblytype_data_header: [
      { text: "Name:", value: "name" },
      { text: "ID", value: "_id" },
      { text: "Dauer", value: "average_duration" },
      { text: "Maschinentyp", value: "machine_type" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    ...mapActions(["deleteAssemblyType", "editAssemblyType"]),

    /**
     * Title: deleteAssemblyType()
     * Description: opens the confirmation dialog when a assemblytype is supossed to be deleted and sets the edited_user_index and edited_user variables to the appropriate user
     *
     * @param {Object} assemblytype the machien that is supposed to be deleted
     *
     */
    confirmDeleteAssemblyType(assemblytype) {
      this.display_delete_confirmation_dialog = true;
      this.edited_assemblytype_index =
        this.allAssemblyTypes.indexOf(assemblytype);
      Object.assign(this.edited_assemblytype, assemblytype);
    },

    /**
     * Title: cancelDeleteEdit()
     * Description: resets the edited_assemblytype and edited_assemblytype_index variable after the deletion of a assemblytype has been canceled or after the user has succesfully been deleted
     *
     */
    cancelDeleteEdit() {
      this.display_editing_dialog = false;
      this.display_delete_confirmation_dialog = false;
      this.$nextTick(() => {
        Object.assign(this.edited_assemblytype, this.standard_assemblytype);
        this.edited_assemblytype_index = -1;
      });
    },

    /**
     * Title: confirmDelete()
     * Description: executed when confirmation button of the delete dialog is beeing clicked
     *
     */
    deleteAssemblyTypeConfirmed() {
      this.deleteAssemblyType(this.edited_assemblytype);
      this.cancelDeleteEdit();
    },

    /**
     * Title: confirmSelection()
     * Description: run when the selection of a assemblytype is confirmed within the selection dialog
     *
     * @param {String} mode determines if the delete or the edit dialog shall be opened after select confirmation
     *
     */
    confirmSelection(mode, selection_id, amount) {
      if (mode === "delete") {
        const assemblytype = this.allAssemblyTypes.find(
          (assemblytypeall) => assemblytypeall._id === selection_id
        );
        Object.assign(this.edited_assemblytype, assemblytype);
        this.confirmDeleteAssemblyType(assemblytype);
      } else if (mode === "edit") {
        const assemblytype = this.allAssemblyTypes.find(
          (assemblytypeall) => assemblytypeall._id === selection_id
        );
        this.openEditAssemblyTypeDialog(assemblytype);
      }

      // If a new material_type has been added to the factory
      else if (mode === "add_subassemblytype_to_assemblytype") {
        this.edited_assemblytype_index = this.allAssemblyTypes.indexOf(
          this.edited_assemblytype
        );
        this.edited_assemblytype.subassembly_types.push({
          subassembly_type: selection_id,
          amount: amount,
        });
        this.editAssemblyType({
          assembly_type: this.edited_assemblytype,
          assembly_type_index: this.edited_assemblytype_index,
        });
      }
      // If a new machine has been added to the machinegroup
      else if (mode === "add_materialtype_to_assemblytype") {
        this.edited_assemblytype_index = this.allAssemblyTypes.indexOf(
          this.edited_assemblytype
        );
        this.edited_assemblytype.material_types.push({
          material_type: selection_id,
          amount: amount,
        });
        this.editAssemblyType({
          assembly_type: this.edited_assemblytype,
          assembly_type_index: this.edited_assemblytype_index,
        });
      }
      this.display_select_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
    },

    /**
     * Title: openEditAssemblyTypeDialog()
     * Description: opens the Edit AssemblyType Dialog and sets the edited_assemblytype and edited_assemblytype_index according to the assemblytype selected by the user
     *
     * @param {AssemblyType} assemblytype the assemblytype that is beeing edited
     *
     */
    openEditAssemblyTypeDialog(assemblytype) {
      this.edited_assemblytype_index =
        this.allAssemblyTypes.indexOf(assemblytype);
      Object.assign(this.edited_assemblytype, assemblytype);
      this.display_edit_assemblytype_dialog = true;
    },

    /**
     * Title: closeEditAssemblyTypeDialog()
     * Description: closes the Edit AssemblyType Dialog and resets the edited_assemblytype and edited_assemblytype_index
     *
     */
    closeEditAssemblyTypeDialog() {
      this.display_edit_assemblytype_dialog = false;
      this.edited_assemblytype_index = -1;
      Object.assign(this.edited_assemblytype, this.standard_assemblytype);
      this.assemblytype_data_table_key = this.assemblytype_data_table_key + 1;
    },

    /**
     * Title: cancelSelection()
     * Description: run when the edit/deletion of a assemblytype is cancel within the select edit/delete dialogs and resets the selected_user_id
     *
     */
    cancelSelection() {
      this.display_select_dialog = false;
      this.display_edit_assemblytype_dialog = false;
      this.selection_mode = "";
      this.selection_title = "";
      this.selection_label = "";
      this.selection_data = [];
      Object.assign(this.edited_assemblytype, this.standard_assemblytype);
    },

    /**
     * Title: setupSelection()
     * Description: sets up and opens the selection dialog
     *
     * @param {String} selection_mode the mode in which the selection dialog is beeing opened.
     *
     */
    setupSelection(selection_mode) {
      this.selection_label = "Baugruppentyp";
      this.selection_mode = selection_mode;
      if (selection_mode === "edit") {
        this.selection_title = "Baugruppentyp zum Bearbeiten auswählen:";
        this.selection_data = this.allAssemblyTypes;
      } else if (selection_mode === "delete") {
        this.selection_title = "Baugruppentyp zum Löschen auswählen:";
        this.selection_data = this.allAssemblyTypes;
      } else if (selection_mode === "add_subassemblytype_to_assemblytype") {
        this.selection_title = "Baugruppe auswählen:";
        this.selection_label = "Baugruppe";
        this.selection_data = this.allAssemblyTypes;
        this.display_amount = true;
      } else if (selection_mode === "add_materialtype_to_assemblytype") {
        this.selection_title = "Materialtyp auswählen:";
        this.selection_label = "Materialtyp";
        this.selection_data = this.allMaterialTypes;
        this.display_amount = true;
      }
      this.display_select_dialog = true;
    },

    /**
     * Title: deleteSubAssemblyTypeFromAssemblyType()
     * Description: deletes an subassembly from an assembly type
     *
     * @param {AssemblyType} assemblytype the assemblytype that edited
     * @param {AssemblyType} subassembly the subassembly that deleted
     *
     */
    deleteSubAssemblyTypeFromAssemblyType(assemblytype, subassembly) {
      Object.assign(this.edited_assemblytype, assemblytype);
      this.edited_assemblytype_index = this.allAssemblyTypes.indexOf(
        this.edited_assemblytype
      );
      const subassembly_index =
        this.edited_assemblytype.subassembly_types.indexOf(subassembly);
      this.edited_assemblytype.subassembly_types.splice(subassembly_index, 1);
      this.editAssemblyType({
        assembly_type: this.edited_assemblytype,
        assembly_type_index: this.edited_assemblytype_index,
      });
      this.closeEditFactoryDialog;
    },

    /**
     * Title: deleteMaterialTypeFromAssemblyType()
     * Description: deletes an material from an assembly type
     *
     * @param {AssemblyType} assemblytype the assemblytype that edited
     * @param {AssemblyType} materialtype the materialtype that deleted
     *
     */
    deleteMaterialTypeFromAssemblyType(assemblytype, materialtype) {
      Object.assign(this.edited_assemblytype, assemblytype);
      this.edited_assemblytype_index = this.allAssemblyTypes.indexOf(
        this.edited_assemblytype
      );
      const materialtype_index =
        this.edited_assemblytype.material_types.indexOf(materialtype);
      this.edited_assemblytype.material_types.splice(materialtype_index, 1);
      this.editAssemblyType({
        assembly_type: this.edited_assemblytype,
        assembly_type_index: this.edited_assemblytype_index,
      });
      this.closeEditFactoryDialog;
    },
  },
};
</script>
