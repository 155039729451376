<!-- 
    Title: NavigationDrawer
  
    Description: The Navigation Drawer of the hierarchichal Overview
-->

<template>
  <v-navigation-drawer
    app
    clipped
    :elevation="2"
    permanent
    fixed
    dark
    width="20%"
  >
    <v-list-item>
      <v-list-item-content>
        <!-- Title -->
        <v-list-item-title class="text-h6">
          Hierarchischer Digitaler Zwilling
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ overviewNavigationDrawerTitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <!-- Button for Main DT Overview -->
    <v-btn
      plain
      large
      tile
      width="100%"
      class="mt-2 justify-start"
      @click="onUpdate([{ _id: '0', type: 0 }])"
      ><v-icon class="mr-2">mdi-content-copy</v-icon>Hauptübersicht</v-btn
    >

    <!-- Treeview for the dt Components -->
    <v-treeview
      :items="overviewNavigationTree"
      :active.sync="active"
      :open.sync="open"
      item-key="_id"
      activatable
      return-object
      @update:active="onUpdate(active)"
    >
      <!-- Icon Template -->
      <template v-slot:prepend="{ item }">
        <v-icon>{{ treeviewIcon(item) }}</v-icon>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import overviewMixins from "../../../mixins/overviewMixins";
export default {
  name: "NavigationDrawer",

  props: {
    title: String, // Current Title of the Nav Drawer
  },

  mixins: [overviewMixins],

  data: () => ({
    active: [], // currently active Tree Elements
    open: [], // currently open tree elements
  }),

  watch: {
    // Watches the OverviewActiveElementID and updates the active and open Element of the treeview if the OverviewActiveElementID has been edited by something else than the treeview
    overviewActiveElementId: function () {
      if (
        this.active[0]._id != this.overviewActiveElementId &&
        this.overviewActiveElementId != "0"
      ) {
        const new_active = this.active[0].children.find(
          (child) => child._id === this.overviewActiveElementId
        );
        this.open.push(this.active[0].valueOf());
        this.active = [new_active].valueOf();
      }
    },
  },

  computed: {
    ...mapGetters([
      "navigationIcons",
      "overviewNavigationTree",
      "getAppLoadingStatus",
      "overviewNavigationDrawerTitle",
      "overviewActiveElementId",
      "overviewActiveElementType",
      "specificSensor",
      "specificSensorType",
      "specificMachine",
      "specificMachineType",
    ]),
  },

  methods: {
    ...mapActions([
      "fetchOverviewNavigationTree",
      "changeAppLoadingStatus",
      "updateOverviewActiveElement",
    ]),

    /**
     * Title: onUpdate()
     * Description: is run when the active element of the treeview component changes
     *
     * @param {Object} active the currently active element, transmitted by the treeview component
     *
     */
    onUpdate(active) {
      this.updateOverviewActiveElement({
        new_active_element_id: active[0]._id,
        new_active_element_type: active[0].type,
      });
    },

    /**
     * Title: treeviewIcon()
     * Description: returns the icon of a specific treeview element, gets it from the stata
     *
     * @param {Object} item the treeview item whos icon is to be returned
     *
     */
    treeviewIcon(item) {
      if (item.type === 1 || item.type === 2) {
        return this.navigationIcons[
          this.activeElementTypeToIconString(item.type)
        ];
      } else if (item.type === 3) {
        return this.specificMachineType(this.specificMachine(item._id).type)
          .icon;
      } else if (item.type === 4) {
        return this.specificSensorType(this.specificSensor(item._id).type).icon;
      }
    },
  },
};
</script>
