<template>
  <!--
    Title: Machine Overview
    Purpose: Shows a general overview about a manchine and its subordinated sensors.
  -->
  <v-container>
    <v-row>
      <v-col class="d-flex">
        <!-- Title/Machine Name -->
        <h1>{{ machine.name }}</h1>
        <v-spacer></v-spacer>

        <!-- Switch to turn a machine on/off -->
        <v-switch
          right
          v-model="machineOnOff"
          color="success"
          @click="turnMachineOnOff"
        ></v-switch>
      </v-col>
    </v-row>

    <!-- KPI Cards -->
    <v-row>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Durchschnittliche Bearbeitungszeit'"
          :kpi_value="machine.average_processing_time"
          :kpi_unit="'Stunden pro Baugruppe'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Aktueller Durchsatz'"
          :kpi_value="machine.current_throughput"
          :kpi_unit="'Baugruppen pro Stunde'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Nächste Wartung in'"
          :kpi_value="next_maintenance"
          :kpi_unit="'Betriebsstunden'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
      <v-col cols="3">
        <KPICard
          :kpi_title="'Warteschlange'"
          :kpi_value="specificMachineType(machine.type).assemblyQueue.length"
          :kpi_unit="'Baugruppen'"
          :kpi_bgcolor="'#2196F3'"
        />
      </v-col>
    </v-row>

    <!-- Detailed Machine Infomation and Sensor Gauges for favored sensors -->
    <v-row>
      <v-col cols="4">
        <MachineInfoCard :machine="machine" />
      </v-col>
      <v-col
        cols="4"
        v-for="sensor in machine.components.sensors.filter(
          (sensor) => specificSensor(sensor).favored === true
        )"
        :key="sensor"
      >
        <SensorGaugeCard :sensor_id="sensor" />
      </v-col>
    </v-row>

    <!-- Throuzghput -->
    <v-row>
      <v-col>
        <ThroughputCard :throughput_data="throughput_data" />
      </v-col>
    </v-row>

    <!-- Average Processing Time -->
    <v-row>
      <v-col>
        <ProcessingTimeCard
          :average_processing_time_data="average_processing_time_data"
        />
      </v-col>
    </v-row>

    <!-- Sensor Details -->
    <v-row>
      <v-col>
        <SensorDetailPanel :sensor_ids="machine.components.sensors" />
      </v-col>
    </v-row>

    <!-- Production Historxy -->
    <v-row>
      <v-col>
        <ProductionHistoryCard
          :production_history="machine.production_history"
        />
      </v-col>
    </v-row>

    <!-- Maintenance Plan -->
    <v-row>
      <v-col>
        <MaintenancePlanCard
          :maintenance_plan="specificMachineType(machine.type).maintenance_plan"
        />
      </v-col>
    </v-row>

    <!-- Maintenance History -->
    <v-row>
      <v-col>
        <MaintenanceHistoryCard
          :component_id="machine._id"
          :component_type="'machine'"
          :maintenance_history="machine.maintenance_history"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SensorGaugeCard from "../general/SensorGaugeCard.vue";
import MachineInfoCard from "./MachineInfoCard.vue";
import SensorDetailPanel from "../general/SensorDetailPanel.vue";
import MaintenancePlanCard from "../general/MaintenancePlanCard.vue";
import MaintenanceHistoryCard from "../general/MaintenanceHistoryCard.vue";
import ProductionHistoryCard from "./ProductionHistoryCard.vue";
import ThroughputCard from "../general/ThroughputCard.vue";
import ProcessingTimeCard from "../general/ProcessingTimeCard.vue";
import KPICard from "../general/KPICard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MachineOverview",

  components: {
    SensorGaugeCard,
    MachineInfoCard,
    SensorDetailPanel,
    MaintenancePlanCard,
    MaintenanceHistoryCard,
    ThroughputCard,
    ProductionHistoryCard,
    ProcessingTimeCard,
    KPICard,
  },

  computed: {
    ...mapGetters([
      "overviewActiveElementId",
      "allSensors",
      "specificMachine",
      "specificSensor",
      "specificMachineType",
    ]),

    // Returns the data of the displayed machine
    machine: function () {
      return this.specificMachine(this.overviewActiveElementId);
    },

    // Determines the state of the machine on/off Switch and set a new value if it was clicked
    machineOnOff: {
      get() {
        if (this.machine.current_status === 0) {
          return false;
        } else {
          return true;
        }
      },
      set(newValue) {
        return newValue;
      },
    },

    /**
     * Title: throughput_data()
     * Description: converts the raw throughput data for apex
     *
     */
    throughput_data: function () {
      let data = [];
      this.machine.throughput_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: status.value,
        });
      });
      return data;
    },

    /**
     * Title: average_processing_time_data()
     * Description: converts the raw porcessing time data for apex
     *
     */
    average_processing_time_data: function () {
      let data = [];
      this.machine.average_processing_time_history.forEach((status) => {
        //const time = status.time.slice(0, -1) + "-02:00";
        data.push({
          x: new Date(status.time),
          y: status.value,
        });
      });
      return data;
    },

    /**
     * Title: next_maintenance()
     * Description: calculatzes the next maintenance
     *
     */
    next_maintenance: function () {
      let time_till_next_is_due = 0; // the time that is returned
      let hours_till_maintenance = 0; // used for calculating

      this.specificMachineType(this.machine.type).maintenance_plan.forEach(
        (maintenance) => {

          // If the Operating Time of the machine is smaller than the maintenance interval: return the difference
          if (this.machine.operating_time / 60 < maintenance.interval / 60) {
            hours_till_maintenance =
              maintenance.interval / 60 - this.machine.operating_time / 60;
          } 
          
          // Else devide through the interval and return the remainder
          else {
            hours_till_maintenance =
              (this.machine.operating_time / 60) % (maintenance.interval / 60);
          }
          if (time_till_next_is_due === 0) {
            time_till_next_is_due = hours_till_maintenance;
          } else if (hours_till_maintenance < time_till_next_is_due) {
            time_till_next_is_due = hours_till_maintenance;
          }
        }
      );
      return time_till_next_is_due.toFixed(0);
    },
  },

  methods: {
    ...mapActions(["startMachine", "stopMachine"]),

    /**
     * Title: turnMachineOnOff()
     * Description: turns a machine on or off
     *
     */
    turnMachineOnOff() {
      if (this.machineOnOff === true) {
        this.stopMachine({ machine_id: this.machine._id });
      } else {
        this.startMachine({ machine_id: this.machine._id });
      }
    },
  },
};
</script>
