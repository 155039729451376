<!-- 
    Title: EditSensorDialog
  
    Description: Dialog for editing data of a specific Sensor
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Sensor bearbeiten:</span>
      </v-card-title>

      <!-- Input Form -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <v-row>
              <!-- ID Field -->
              <v-col>
                <v-text-field
                  v-model="edited_sensor._id"
                  label="ID"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Name Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensor.name"
                  label="Name"
                  :rules="[() => !!edited_sensor.name || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Sensortype Selector -->
            <v-row>
              <v-col>
                <v-select
                  v-model="edited_sensor.type"
                  :items="allSensorTypes"
                  :menu-props="{ maxHeight: '400' }"
                  label="Typ"
                  item-text="name"
                  item-value="_id"
                  :rules="[() => !!edited_sensor.type || 'Pflichtfeld']"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Description Input -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited_sensor.description"
                  label="Beschreibung"
                  auto-grow
                  outlined
                  :rules="[() => !!edited_sensor.description || 'Pflichtfeld']"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Location Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited_sensor.location"
                  label="Standort"
                  :rules="[() => !!edited_sensor.location || 'Pflichtfeld']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- Operating Range max Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensor.operating_range_max"
                  label="Absoluter max. Wert"
                  :rules="[
                    () => !!edited_sensor.operating_range_max || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(edited_sensor.operating_range_max)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Operating Range Min Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensor.operating_range_min"
                  label="Absoluter min. Wert"
                  :rules="[
                    () => !!edited_sensor.operating_range_min || 'Pflichtfeld',
                    () =>
                      !isNaN(Number(edited_sensor.operating_range_min)) ||
                      'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Optimal Operating Range Max Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensor.optimal_operating_range_max"
                  label="Optimaler max. Wert"
                  :rules="[
                    () =>
                      !!edited_sensor.optimal_operating_range_max ||
                      'Pflichtfeld',
                    () =>
                      !isNaN(
                        Number(edited_sensor.optimal_operating_range_max)
                      ) || 'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>

              <!-- Optimal Operating Range Min Input -->
              <v-col>
                <v-text-field
                  v-model="edited_sensor.optimal_operating_range_min"
                  label="Optimaler min. Wert"
                  :rules="[
                    () =>
                      !!edited_sensor.optimal_operating_range_min ||
                      'Pflichtfeld',
                    () =>
                      !isNaN(
                        Number(edited_sensor.optimal_operating_range_min)
                      ) || 'Nur Nummern zugelassen',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Dialog Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditSensorDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveEdit"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EditSensorDialog", // Component Name

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
    edited_sensor: {}, // the sensor that is beeing edited
    edited_sensor_index: Number, // the index of the edited sensor within the vuex State
  },

  data: () => ({
    form_validation: false, // Validation Data of the Input Form
  }),

  computed: {
    ...mapGetters(["allSensors", "allSensorTypes"]),
  },

  methods: {
    ...mapActions(["editSensor"]),

    /**
     * Title: saveEdit()
     * Description: saves the edited sensor by calling the vuex Method
     *
     */
    async saveEdit() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        // Call Vuex Method to Save
        await this.editSensor({
          sensor: this.edited_sensor,
          sensor_index: this.edited_sensor_index,
        });

        // Emit Close Event to Parents
        this.$emit("closeEditSensorDialog");

        // Reset Input Form
        this.$refs.form.reset();
      }
    },
  },
};
</script>
